import pluralize from "pluralize-ru"
import PropType from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import Api from "../../../../../api"
import { Input } from "../../../../../components"
import { AppContext } from "../../../../../context/AppContext"
import { IconTrash } from "../../../../../static/icons"
import AddAnswerModal from "./AddAnswerModal/AddAnswerModal"
import AddQuestionModal from "./AddQuestionModal/AddQuestionModal"

const TestEdit = ({ location, history, match }) => {
	const { type, courseId, lessonVersions } = location.state

	const { id: testId } = match.params

	const influences = lessonVersions.map(ls => ({
		lesson_version_id: ls.id,
		name: ls.name,
		amount: null
	}))

	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const [addQuestionModalVisible, setAddQuestionModalVisible] = useState(false)
	const [addAnswerModalVisible, setAddAnswerModalVisible] = useState(false)
	const [formData, setFormData] = useState([])
	const [questionId, setQuestionId] = useState(null)
	const [questionIndex, setQuestionIndex] = useState(null)
	const [incorrectQuestionCount, setIncorrectQuestionCount] = useState(0)
	const [errObject, setErrObject] = useState({})

	const addQuestionModalToggle = () => setAddQuestionModalVisible(s => !s)
	const addAnswerModalToggle = (id, index) => {
		setQuestionId(id)
		setQuestionIndex(index)
		setAddAnswerModalVisible(s => !s)
	}

	const changeQuestionValue = (question, qIndex) => {
		const data = formData.map((q, qInd) => {
			if (qInd === qIndex) {
				return { ...q, question }
			}

			return q
		})

		setFormData(data)
	}

	const changeAnswerValue = (answer, qIndex, aIndex) => {
		const answ = formData[qIndex].answers.map((a, aInd) => {
			if (aInd === aIndex) {
				return { ...a, answer }
			}

			return a
		})
		const formD = [...formData]
		formD[qIndex].answers = answ

		setFormData(formD)
	}

	const changeAnswerCorrect = (qIndex, aIndex) => {
		const answ = formData[qIndex]?.answers.map((a, aInd) => {
			if (aInd === aIndex) {
				if (a.correct === "") {
					return { ...a, correct: "on" }
				} else {
					return { ...a, correct: "" }
				}
			}
			return a
		})

		const formD = [...formData]
		formD[qIndex].answers = answ

		setFormData(formD)
	}

	const editQuestion = (id, question) => {
		Api.put(`/test/${id}`, { question }, { headers: { Authorization } }).then(
			res => {
				const { error, data } = res.data

				if (!error) {
					alert(data)
				} else {
					console.error(data)
					alert("Ошибка")
				}
			}
		)
	}

	const editAnswer = ({ id, answer, correct }) => {
		Api.put(
			`/test/update-answer/${id}`,
			{ answer, correct },
			{ headers: { Authorization } }
		).then(res => {
			const { error, data } = res.data

			if (!error) {
				alert(data)
			} else {
				console.error(data)
				alert("Ошибка")
			}
		})
	}

	const removeQuestion = (id, index) => {
		Api.delete(`/test/delete-question/${id}`, {
			headers: { Authorization }
		}).then(res => {
			const { error, data } = res.data

			if (!error) {
				setFormData(formData.filter((i, ind) => ind !== index))
				alert(data)
			} else {
				console.error(data)
				alert("Ошибка")
			}
		})
	}

	const removeAnswer = (id, index) => {
		Api.delete(`/test/delete-answer/${id}`, {
			headers: { Authorization }
		}).then(res => {
			const { error, data } = res.data

			if (!error) {
				const data = formData.map(q => {
					return { ...q, answers: q.answers.filter((a, ind) => ind !== index) }
				})

				setFormData(data)
			} else {
				console.error(data)
			}

			alert(data)
		})
	}

	const handleInfluenceChange = (influence, id, answerId) => {
		Api.post(
			`/test/influence/${answerId}?lesson_version_id=${id}`,
			{ influence },
			{ headers: { Authorization } }
		).then(res => {
			const { error, data } = res.data

			if (!error) {
				alert(data)
			} else {
				console.error(data)
				alert("Ошибка")
			}
		})
	}

	const onSaveIncorrectQuestionCount = () => {
		Api.post(
			`/test/correct?${type}=${testId}`,
			{ not_correct: +incorrectQuestionCount },
			{ headers: { Authorization } }
		).then(res => {
			const { error, data } = res.data

			if (!error) {
				alert(data)
				setErrObject({})
			} else {
				console.error(data)
				setErrObject(data)
			}
		})
	}

	const getTestData = () => {
		Api.get(`/test?${type}=${testId}`, { headers: { Authorization } }).then(
			res => {
				const { data, error } = res.data

				if (!error) {
					setFormData(data.questions)
					setIncorrectQuestionCount(data.countNotCorrect)
				} else {
					alert("Ошибка")
					console.error(data)
				}
			}
		)
	}

	useEffect(() => {
		getTestData()
	}, [])

	return (
		<>
			<div className="page-header">
				<div className="row ai-center jc-between">
					<div className="col-md-auto">
						<h6 className="title">Редактирование теста</h6>
					</div>

					<div className="col-md-auto">
						<div onClick={() => history.goBack()} className="btn btn-primary">
							Назад
						</div>
					</div>
				</div>
			</div>

			<div className="form">
				<div className="card">
					<div className="card-body">
						<div className="mb-4">
							<Input
								label={`Максимальное количество ошибок в тесте из ${
									formData.length
								} ${pluralize(
									formData.length,
									"вопросов",
									"вопроса",
									"вопросов",
									"вопросов"
								)}`}
								placeholder="0"
								value={incorrectQuestionCount}
								onChange={e => setIncorrectQuestionCount(e.target.value)}
								type="number"
								onSave={() => onSaveIncorrectQuestionCount(testId)}
								error={errObject.lesson_id}
							/>
						</div>

						{formData.map((qItem, qIndex) => {
							return (
								<div className="card" key={qIndex}>
									<div className="card-body">
										<div className="field">
											<div className="row row-sm ai-end">
												<div className="col">
													<Input
														label={`Вопрос ${qIndex + 1}`}
														placeholder="Вопрос"
														value={qItem.question}
														onChange={e =>
															changeQuestionValue(e.target.value, qIndex)
														}
														onSave={() =>
															editQuestion(qItem.id, qItem.question)
														}
													/>
												</div>

												<div className="col-auto">
													<div
														className="btn btn-danger"
														onClick={() => removeQuestion(qItem.id, qIndex)}>
														<IconTrash size={13} color="#fff"/>
													</div>
												</div>
											</div>
										</div>

										<div className="pl-md-3">
											{qItem.answers.map((aItem, aIndex) => {
												return (
													<div className="field" key={aIndex}>
														<div className="row row-sm ai-end">
															<div className="col">
																<Input
																	label={`Ответ ${aIndex + 1}`}
																	value={aItem.answer}
																	onChange={e =>
																		changeAnswerValue(
																			e.target.value,
																			qIndex,
																			aIndex
																		)
																	}
																	placeholder="Ответ"
																	onSave={() => editAnswer(aItem)}
																/>
															</div>

															<div
																className="col-auto d-flex ai-center"
																style={{ height: 38 }}>
																<label className="cursor-pointer">
																	<input
																		name={`answers[${qIndex}].correct`}
																		type="checkbox"
																		className="input-checkbox"
																		onChange={() =>
																			changeAnswerCorrect(qIndex, aIndex)
																		}
																		checked={aItem.correct}
																	/>
																</label>
															</div>

															<div className="col-auto">
																<div
																	className="btn btn-danger"
																	onClick={() =>
																		removeAnswer(aItem.id, aIndex)
																	}>
																	<IconTrash size={13} color="#fff"/>
																</div>
															</div>
														</div>

														<div className="row row row-sm mt-2">
															{aItem.influences &&
															aItem.influences.map((i, index) => {
																return (
																	<div className="col-xl-2" key={index}>
																		<input
																			disabled
																			className="input input-sm my-2"
																			defaultValue={i.name}
																		/>

																		<select
																			className="input input-sm _select"
																			value={i.amount || 0}
																			onChange={e =>
																				handleInfluenceChange(
																					e.target.value,
																					i.lesson_version_id,
																					aItem.id
																				)
																			}>
																			<option value="1">
																				Предпочтительно
																			</option>
																			<option value="0">Нейтрально</option>
																			<option value="-1">Нежелательно</option>
																		</select>
																	</div>
																)
															})}
														</div>
													</div>
												)
											})}

											<div
												className="btn btn-primary btn-sm"
												onClick={() => addAnswerModalToggle(qItem.id, qIndex)}>
												Добавить ответ
											</div>
										</div>
									</div>
								</div>
							)
						})}

						<button
							className="btn btn-primary"
							onClick={addQuestionModalToggle}>
							Добавить вопрос
						</button>
					</div>
				</div>
			</div>

			{addQuestionModalVisible && (
				<AddQuestionModal
					courseId={courseId}
					type={type}
					id={testId}
					onAddQuestion={setFormData}
					onCloseModal={addQuestionModalToggle}
					influences={influences}
				/>
			)}

			{addAnswerModalVisible && (
				<AddAnswerModal
					questionId={questionId}
					qIndex={questionIndex}
					onAddAnswer={setFormData}
					onCloseModal={() => addAnswerModalToggle(null, null)}
					influences={influences}
				/>
			)}
		</>
	)
}

TestEdit.propTypes = {
	location: PropType.object,
	history: PropType.object,
	match: PropType.object
}

export default TestEdit
