/* eslint-disable no-unused-vars */
import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import Api from "../../../../api"
import { Spinner } from "../../../../components"
import Message from "../../../../components/Message/Message"
import { AppContext } from "../../../../context/AppContext"
import { IconClip, IconEducation, IconSend, IconTrash } from "../../../../static/icons"
import default_avatar from "../../../../static/imgs/users/default_avatar.svg"
import "../Chat.scss"

const Chat = ({ match, history }) => {
	const { state, setUnreadMessages } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	// const { id: chatId } = match.params

	const role = state.roleList.support

	const [chatId, setChatId] = useState(null)
	const [chatMessages, setChatMessages] = useState([])
	const [chatList, setChatList] = useState([])
	const [message, setMessage] = useState("")

	const [documents, setDocuments] = useState([])
	const [documentError, setDocumentsError] = useState(null)

	const getChatList = () => {
		Api.get("/support/list", { headers: { Authorization } }).then(res => {
			const { error, data } = res.data

			if (!error) {
				setChatList(data)
			} else {
				console.error(data)
			}
		})
	}

	const getChatMessages = () => {
		if (chatId) {
			Api.get(`/message/${chatId}`, { headers: { Authorization } }).then(
				res => {
					const { error, data } = res.data

					if (!error) {
						setChatMessages(data)
					} else {
						console.error(data)
					}
				}
			)
		}
	}

	/**
	 * Удалить прикрепленную картинку
	 **/
	const removeFile = docName => {
		setDocuments(documents.filter(doc => doc.name !== docName))
	}

	/**
	 * Добавить документ
	 **/
	const onAddFiles = e => {
		const files = [...e.target.files]

		if (files.length > 0) {
			files.forEach(doc => {
				if (doc.size <= 10485760) {
					setDocuments([...documents, doc])
					setDocumentsError(null)
				} else {
					setDocumentsError("Попробуйте файл меньше 5 Мб")
				}
			})
		}
	}

	const onFormSubmit = e => {
		e.preventDefault()

		if (
			(documents.length === 0 || documents.length > 20) &&
			message.length === 0
		)
			return false

		const formData = new FormData()
		formData.append("message", message)

		documents.length > 0 &&
		documents.forEach((doc, index) =>
			formData.append(`documents[${index}]`, doc)
		)

		Api.post(`/message/${chatId}`, formData, {
			headers: { Authorization }
		}).then(res => {
			const { error, data } = res

			if (!error) {
				setMessage("")
				setDocuments([])
				setDocumentsError(null)
				getChatMessages()
			} else {
				console.error(data)
			}
		})
	}

	const onChatChange = (id, index, isViewed) => {
		// history.replace({ pathname: `/support/${id}` })
		setChatId(id)

		const newChatList = [...chatList]
		newChatList[index].isViewed = true

		setChatList(newChatList)

		if (!isViewed) {
			const val =
				state.unreadMessages.support - 1 > 0
					? state.unreadMessages.support - 1
					: 0
			setUnreadMessages({ ...state.unreadMessages, support: val })
		}
	}

	/**
	 * Загрузка документа
	 **/
	const downloadBase64File = (base64Data, fileName) => {
		const downloadLink = document.createElement("a")
		downloadLink.href = base64Data
		downloadLink.download = fileName
		downloadLink.click()
	}

	const onFileDownload = (url, fileName) => {
		Api.get(url, { headers: { Authorization } }).then(res => {
			const { error, data } = res.data

			if (!error) {
				downloadBase64File(data, fileName)
			} else {
				console.error(data)
				alert("Ошибка")
			}
		})
	}

	useEffect(() => {
		getChatList()
	}, [])

	useEffect(() => {
		getChatMessages()

		const unsubscribe = setInterval(() => {
			getChatMessages()
		}, 10000)

		return () => {
			clearTimeout(unsubscribe)
		}
	}, [chatId])

	return (
		<div className="page-chat">
			<div className="page-header">
				<h6 className="title">Чат</h6>
			</div>

			<div className="row mt-5">
				<div className="col-xl-3">
					<div className="chat-list">
						<div className="h6 mb-3 list-header">Контакты</div>

						<div className="scroll-y">
							{role.view &&
							chatList.map((i, index) => {
								return (
									<div
										key={i.id}
										className={`chat-item d-flex ai-center ${
											+chatId === i.id ? " _active" : ""
										}${!i.isViewed ? " _unread" : ""}`}
										onClick={() => onChatChange(i.id, index, i.isViewed)}>
										<img
											src={i.user?.avatarThumb || default_avatar}
											alt=""
											className="avatar avatar-xs avatar-circle mr-3 image-cover"
										/>
										<div className="item-body">
											<div className="name font-500">{`${
												i.user?.last_name || "Фамилия"
											} ${i.user?.first_name || "Имя"}`}</div>
											<div className="thumb">{i.user?.birthDate}</div>
										</div>
									</div>
								)
							})}
						</div>
					</div>
				</div>

				<div className="col-xl-9">
					{chatMessages.length > 0 && (
						<>
							<div className="chat-wrap">
								<div className="msg-list scroll-y d-flex flex-column">
									{chatMessages.map((i, index) => (
										<Message
											key={index}
											data={i}
											userId={state.user.user_id}
											download={onFileDownload}
										/>
									))}
								</div>

								<form onSubmit={onFormSubmit} className="msg-form pos">
									<label className="pos-abs-y attach-btn d-flex">
										<input
											type="file"
											className="d-none"
											multiple
											onChange={onAddFiles}
										/>
										<IconClip
											size={18}
											color="#1952a5"
											className="image-contain"
										/>
									</label>

									<textarea
										rows="1"
										placeholder="Написать сообщение"
										className="textareaChat"
										name="message"
										value={message}
										onChange={e => setMessage(e.target.value)}
									/>

									<button type="submit" className="send-btn pos-abs-y">
										<IconSend
											size={18}
											color="#1952a5"
											className="image-contain"
										/>
									</button>
								</form>
								<div className="mt-2" style={{ color: "red" }}>
									{documentError}
								</div>
							</div>

							{documents.length > 0 && (
								<div className="mt-3 row row-sm attach-files">
									{documents.map((doc, index) => {
										const typeDoc = !doc.type.includes("image/")
										const img = URL.createObjectURL(doc)

										return (
											<div
												className="col-xs-6 col-sm-4 col-md-4 col-xl-3 col-xxl-2"
												key={index}>
												<div className="mt-3 p-2 file pos d-flex ai-center jc-center flex-column">
													<button
														className="close"
														onClick={() => removeFile(doc.name)}>
														<IconTrash size={12} className="pos-abs"/>
													</button>

													{typeDoc ? (
														<>
															<IconEducation size={34} color={"#1952a5"}/>
															<div className="ta-center mt-2 h7">
																{doc.name}
															</div>
														</>
													) : (
														<>
															<Spinner size={60} className="spinner pos-abs"/>
															<img
																src={img}
																alt={doc.name}
																className="img image-cover"
															/>
														</>
													)}
												</div>
											</div>
										)
									})}
								</div>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	)
}
Chat.propTypes = {
	match: PropTypes.any,
	history: PropTypes.any
}

export default Chat
