/* eslint-disable no-unused-vars */
export const getUrlParams = () => {
	const params = []
	const url = window.location.search.substring(1)

	if (url.length > 0) {
		url.split("&").forEach(v => {
			const val = v.split("=")

			params[val[0]] = val[1]
		})
	}

	return params
}

export const addUrlParam = (paramName, paramValue) => {
	const {
		href,
		protocol,
		hostname,
		pathname,
		hash,
		search,
		port
	} = window.location
	const initUrl = `${protocol}//${hostname}${port ? `:${port}` : ""}${pathname}`
	const matchRule = new RegExp(`(${paramName}=[-0-9a-zA-Z]+)`, "g")

	let newLink = ""

	if (search.length > 0) {
		newLink = search.match(matchRule)
			? href.replace(matchRule, `${paramName}=${paramValue}`)
			: `${initUrl}${search}&${paramName}=${paramValue}`
	} else {
		newLink = `${initUrl}?${paramName}=${paramValue}${hash}`
	}

	history.pushState(null, null, newLink)
}

export const removeUrlParam = paramNames => {
	const { protocol, hostname, pathname, hash, search, port } = window.location
	const initUrl = `${protocol}//${hostname}${port ? `:${port}` : ""}${pathname}`

	const searchArr = search.replace("?", "").split("&")
	const newSearchArr = searchArr.filter(
		search => !paramNames.includes(search.split("=")[0])
	)

	history.pushState(null, null, `${initUrl}?${newSearchArr.join("&")}${hash}`)
}

export const clearUrlParams = () => {
	const { protocol, hostname, pathname, hash, port } = window.location
	const initUrl = `${protocol}//${hostname}${port ? `:${port}` : ""}${pathname}`

	history.pushState(null, null, `${initUrl}${hash}`)
}
