import axios from "axios"

const Api = axios.create({
	baseURL: "https://mindflex.wocom.biz/v1b",
})

Api.interceptors.request.use(req => {
	console.log(req)
	return req
})

Api.interceptors.response.use(res => {
	console.log(res)
	return res
})

export default Api
