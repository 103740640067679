import React, { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Api from "../../../../../api"
import { Spinner } from "../../../../../components"
import { Pagination } from "../../../../../components/index"
import { AppContext } from "../../../../../context/AppContext"
import { IconEdit, IconTrash, IconWatch } from "../../../../../static/icons"
import "./FaqSectionList.scss"

const FaqSectionList = () => {
	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const role = state.roleList["section-faq"]

	const [page, setPage] = useState(1)
	const [lastPage, setLastPage] = useState(null)

	const [faqSectionList, setFaqSectionList] = useState([])
	const [isLoading, setIsLoading] = useState(true)

	const getFaqSectionList = () => {
		Api.get(`/section-faq/list?page=${page}&limit=10`, {
			headers: { Authorization },
		}).then(res => {
			setLastPage(res.data.lastPage)
			const { error, data } = res.data

			if (!error) {
				setFaqSectionList(data)
			} else {
				console.error(data)
				alert("Ошибка")
			}

			setIsLoading(false)
		})
	}

	const removeCourse = id => {
		if (window.confirm("Удалить раздел")) {
			Api.delete(`/section-faq/${id}`, {
				headers: { Authorization },
			}).then(res => {
				const { error, data } = res.data
				if (!error) {
					setFaqSectionList(faqSectionList.filter(it => it.id !== id))
				} else {
					console.error(data)
					alert(data)
				}
			})
		}
	}

	useEffect(() => {
		getFaqSectionList()
	}, [])

	return (
		<div className="page-faq-section-list">
			<div className="page-header">
				<div className="row ai-center jc-between">
					<div className="col-md-auto">
						<h6 className="title">Разделы частых вопросов</h6>
					</div>

					{role.create && (
						<div className="col-md-auto">
							<Link to="/faq/section/create" className="btn btn-primary">
								Добавить раздел
							</Link>
						</div>
					)}
				</div>
			</div>

			{!isLoading ? (
				<div className="card">
					<div className="card-body">
						<table>
							<thead>
								<tr>
									<th></th>
									<th>Название раздела</th>
									{role.update && role.delete && role.view && <th></th>}
								</tr>
							</thead>

							<tbody>
								{faqSectionList.map(item => (
									<tr key={item.id}>
										<td>
											<img
												src={item.icon}
												alt={item.name}
												className="icon-render image-contain"
											/>
										</td>
										<td>{item.name}</td>
										<td className="actions">
											<div className="row row-xs">
												{role.view && (
													<div className="col-auto">
														<Link
															to={{
																pathname: `/faq/section/list/${item.id}`,
																state: {
																	faqDirectionName: item.name,
																},
															}}
															className="btn btn-sm btn-primary">
															<IconWatch size={14} color="#fff" />
														</Link>
													</div>
												)}

												{role.update && (
													<div className="col-auto">
														<Link
															to={{
																pathname: `/faq/section/edit/${item.id}`,
																state: { faqDirection: item },
															}}
															className="btn btn-sm btn-primary">
															<IconEdit size={14} color="#fff" />
														</Link>
													</div>
												)}

												{role.delete && (
													<div className="col-auto">
														<button
															className="btn btn-sm btn-danger"
															onClick={() => removeCourse(item.id)}>
															<IconTrash size={14} color="#fff" />
														</button>
													</div>
												)}
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
						<Pagination
							currentPage={page}
							totalPages={lastPage || 1}
							onChangePage={setPage}
						/>
					</div>
				</div>
			) : (
				<div className="col d-flex jc-center">
					<Spinner size={100} styles={{ marginTop: 100 }} />
				</div>
			)}
		</div>
	)
}

export default FaqSectionList
