import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { Route, withRouter } from "react-router"
import Api from "../../api"
import Header from "../../containers/Header/Header"
import LeftMenu from "../../containers/LeftMenu/LeftMenu"
import { AppContext } from "../../context/AppContext"
import Courses from "./Chat/Courses/Courses"
import Graduates from "./Chat/Graduates/Graduates"
import Homeworks from "./Chat/Homeworks/Homeworks"
import Support from "./Chat/Support/Support"
import CourseCreate from "./Education/Course/Create/CourseCreate"
import CourseEdit from "./Education/Course/Edit/CourseEdit"
import CourseList from "./Education/Course/List/CourseList"
import CourseView from "./Education/Course/View/CourseView"
import DirectionCreate from "./Education/Direction/Create/DirectionCreate"
import DirectionEdit from "./Education/Direction/Edit/DirectionEdit"
import DirectionList from "./Education/Direction/List/DirectionList"
import DirectionView from "./Education/Direction/View/DirectionView"
import ExtMaterialAdd from "./Education/ExtMaterial/Create/ExtMaterialAdd"
import LessonCreate from "./Education/Lesson/Create/LessonCreate"
import LessonEdit from "./Education/Lesson/Edit/LessonEdit"
import LessonList from "./Education/Lesson/List/LessonList"
import LessonVersionCreate from "./Education/Lesson/Version/LessonVersionCreate/LessonVersionCreate"
import LessonVersionEdit from "./Education/Lesson/Version/LessonVersionEdit/LessonVersionEdit"
import LessonVersionView from "./Education/Lesson/Version/LessonVersionView/LessonVersionView"
import LessonView from "./Education/Lesson/View/LessonView"
import ModuleCreate from "./Education/Module/Create/ModuleCreate"
import ModuleEdit from "./Education/Module/Edit/ModuleEdit"
import ModuleList from "./Education/Module/List/ModuleList"
import ModuleView from "./Education/Module/View/ModuleView"
import EducationQuestionsList from "./Education/Questions/List/EducationQuestionsList"
import EducationQuestionsView from "./Education/Questions/View/EducationQuestionsView"
import FaqQuestionCreate from "./Faq/Question/Create/FaqQuestionCreate"
import FaqQuestionEdit from "./Faq/Question/Edit/FaqQuestionEdit"
import FaqQuestionList from "./Faq/Question/List/FaqQuestionList"
import FaqQuestionView from "./Faq/Question/View/FaqQuestionView"
import FaqSectionCreate from "./Faq/Section/Create/FaqSectionCreate"
import FaqSectionEdit from "./Faq/Section/Edit/FaqSectionEdit"
import FaqSectionList from "./Faq/Section/List/FaqSectionList"
import HomePage from "./Home/Home"
import "./index.scss"
import HomeWorkCreate from "./Testing/HomeWork/Create/HomeWorkCreate"
import HomeWorkEdit from "./Testing/HomeWork/Edit/HomeWorkEdit"
import HomeworkView from "./Testing/HomeWork/View/HomeworkView"
import QuestCreate from "./Testing/Quest/Create/QuestCreate"
import QuestView from "./Testing/Quest/View/QuestView"
import TestCreate from "./Testing/Test/Create/TestCreate"
import TestEdit from "./Testing/Test/Edit/TestEdit"
import TestView from "./Testing/Test/View/TestView"
import UserInfo from "./User/Info/UserInfo"
import UserList from "./User/List/UserList"
import UserView from "./User/View/UserView"

const LkFlow = ({ location }) => {
	const { state, setUnreadMessages } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`
	const [menuActive, setMenuActive] = useState(false)

	const showMenu = show => {
		setMenuActive(show)
	}

	const getUnreadMessages = async () => {
		const res = await Api.get("/message/not-read", {
			headers: { Authorization },
		})
		const { data, error } = res.data

		if (!error) {
			setUnreadMessages(data)
		} else {
			console.error(data)
			alert("Ошибка")
		}
	}

	useEffect(() => {
		if (Object.keys(state.roleList).length > 0) {
			document.getElementById("root").scrollTop = 0
			getUnreadMessages()
		}
	}, [location.pathname])

	return (
		<>
			<Header mobMenuPress={() => showMenu(true)} />

			<LeftMenu
				active={menuActive}
				mobMenuPress={() => showMenu(false)}
				roleList={state.roleList}
			/>

			<div id="main-content" className="d-flex flex-column">
				<Route path="/" exact component={HomePage} />

				<Route path="/user/list" component={UserList} />

				<Route path="/user/info/:id" component={UserInfo} />
				<Route path="/user/view/:id" component={UserView} />

				<Route path="/support/:id" component={Support} />
				<Route path="/support" exact component={Support} />

				<Route path="/graduate-work/:id" component={Graduates} />
				<Route path="/graduate-work" exact component={Graduates} />

				<Route path="/course-work/:id" component={Courses} />
				<Route path="/course-work" exact component={Courses} />

				<Route path="/homeworks/:id" component={Homeworks} />
				<Route path="/homeworks" exact component={Homeworks} />

				<Route path="/direction/edit/:id" component={DirectionEdit} />
				<Route path="/direction/view/:id" component={DirectionView} />
				<Route path="/direction/list" component={DirectionList} />
				<Route path="/direction/create" component={DirectionCreate} />

				<Route path="/course/edit/:id" component={CourseEdit} />
				<Route path="/course/list" component={CourseList} />
				<Route path="/course/create" component={CourseCreate} />
				<Route path="/course/view/:id" component={CourseView} />

				<Route path="/module/edit/:id" component={ModuleEdit} />
				<Route path="/module/list" component={ModuleList} />
				<Route path="/module/create" component={ModuleCreate} />
				<Route path="/module/view/:id" component={ModuleView} />

				<Route path="/lesson/edit/:id" component={LessonEdit} />
				<Route path="/lesson/list" component={LessonList} />
				<Route path="/lesson/create" component={LessonCreate} />
				<Route path="/lesson/view/:id" component={LessonView} />

				<Route path="/lesson-version/create" component={LessonVersionCreate} />
				<Route path="/lesson-version/edit/:id" component={LessonVersionEdit} />
				<Route path="/lesson-version/view/:id" component={LessonVersionView} />

				<Route path="/test/create" component={TestCreate} />
				<Route path="/test/edit/:id" component={TestEdit} />
				<Route path="/test/view/:id" component={TestView} />

				<Route path="/quest/create" component={QuestCreate} />
				<Route path="/quest/view/:id" component={QuestView} />

				<Route path="/homework/edit/:id" component={HomeWorkEdit} />
				<Route path="/homework/view/:id" component={HomeworkView} />
				<Route path="/homework/create" component={HomeWorkCreate} />

				<Route path="/faq/section/edit/:id" component={FaqSectionEdit} />
				<Route path="/faq/section/list/:id" component={FaqQuestionList} />
				<Route path="/faq/section/list" exact component={FaqSectionList} />
				<Route path="/faq/section/create" component={FaqSectionCreate} />

				<Route path="/faq/question/edit/:id" component={FaqQuestionEdit} />
				<Route path="/faq/question/create" component={FaqQuestionCreate} />
				<Route path="/faq/question/view/:id" component={FaqQuestionView} />

				<Route path="/questions/:id" component={EducationQuestionsView} />
				<Route path="/questions" exact component={EducationQuestionsList} />

				<Route path="/ext-material/add" component={ExtMaterialAdd} />
			</div>
		</>
	)
}
LkFlow.propTypes = {
	location: PropTypes.any,
}

export default withRouter(LkFlow)
