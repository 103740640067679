import PropType from "prop-types"
import React, { useContext, useState } from "react"
import { useForm } from "react-hook-form"
import Api from "../../../../../api"
import { Dropzone, Input } from "../../../../../components"
import { Modal, Spinner } from "../../../../../components/index"
import { AppContext } from "../../../../../context/AppContext"

const QuestCreate = ({ location, history }) => {
	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const { id, courseId, type } = location.state

	const [isSending, setIsSending] = useState(false)
	const [errorObj, setErrorObj] = useState({})
	const [files, setFiles] = useState([])
	const [downloadPercent, setDownloadPercent] = useState(0)

	const { register, handleSubmit } = useForm()

	const onQuestAdd = data => {
		if (!isSending) {
			setIsSending(true)

			const { name, question } = data

			const formData = new FormData()
			formData.append("video", files[0])
			formData.append("name", name)
			formData.append("question", question)

			Api.post(`/quest/create/${courseId}?${type}=${id}`, formData, {
				headers: { Authorization },
				onUploadProgress: progressEvent => {
					const percentCompleted = Math.round(
						(progressEvent.loaded * 100) / progressEvent.total
					)
					setDownloadPercent(percentCompleted)
				},
			})
				.then(res => {
					const { data, error } = res.data

					if (!error) {
						alert("Квест успешно создан")
						setFiles([])
						history.goBack()
					} else {
						window.scrollTo(0, 0)
						setErrorObj(data)
						console.error(data)
						alert("Ошибка")
					}
				})
				.finally(() => {
					setIsSending(false)
					setDownloadPercent(0)
				})
		}
	}

	const onFileRemove = fileName =>
		setFiles(files.filter(f => f.name !== fileName))
	const onFileUpload = filesData => setFiles(filesData)

	return (
		<>
			<div className="page-header">
				<div className="row ai-center jc-between">
					<div className="col-md-auto">
						<h6 className="title">Создать квест</h6>
					</div>

					<div className="col-md-auto">
						<div className="btn btn-primary" onClick={() => history.goBack()}>
							Назад
						</div>
					</div>
				</div>
			</div>

			<form className="form" onSubmit={handleSubmit(onQuestAdd)}>
				<div className="field">
					<Input
						required
						label="Имя квеста"
						placeholder="Название"
						name="name"
						register={register}
						error={errorObj.name}
					/>
				</div>

				<div className="field">
					<Input
						required
						label="Вопрос"
						placeholder="Вопрос"
						name="question"
						register={register}
						error={errorObj.question}
					/>
				</div>

				<div className="field">
					<div className="card">
						<div className="card-body">
							<Dropzone
								multiple={false}
								maxSize={2000000000}
								acceptTypes="video/*"
								files={files}
								onFileRemove={onFileRemove}
								onFileUpload={onFileUpload}
							/>
						</div>
					</div>
				</div>

				<button type="submit" className="btn btn-primary btn-lg">
					Создать
				</button>
			</form>

			<Modal
				show={isSending}
				showClose={false}
				modalWrapperStyles={{ maxWidth: 300 }}>
				<div className="ta-center">
					<Spinner size={100} />
					<div className="font-700 mt-2">{parseInt(downloadPercent)}%</div>
				</div>
			</Modal>
		</>
	)
}

QuestCreate.propTypes = {
	history: PropType.object,
	location: PropType.object,
}

export default QuestCreate
