/* eslint-disable react/prop-types */
import * as React from "react"

const SvgComponent = ({ size, color, ...props }) => (
	<svg width={size} height={size} viewBox="0 0 447.674 447.674" {...props}>
		<path
			fill={color}
			d="M182.725 379.151c-.572-1.522-.769-2.816-.575-3.863.193-1.04-.472-1.902-1.997-2.566-1.525-.664-2.286-1.191-2.286-1.567 0-.38-1.093-.667-3.284-.855-2.19-.191-3.283-.288-3.283-.288H82.224c-12.562 0-23.317-4.469-32.264-13.421-8.945-8.946-13.417-19.698-13.417-32.258V123.335c0-12.562 4.471-23.313 13.417-32.259 8.947-8.947 19.702-13.422 32.264-13.422h91.361c2.475 0 4.421-.614 5.852-1.854 1.425-1.237 2.375-3.094 2.853-5.568.476-2.474.763-4.708.859-6.707.094-1.997.048-4.521-.144-7.566-.189-3.044-.284-4.947-.284-5.712 0-2.474-.905-4.611-2.712-6.423-1.809-1.804-3.949-2.709-6.423-2.709H82.224c-22.648 0-42.016 8.042-58.101 24.125C8.042 81.323 0 100.688 0 123.338v200.994c0 22.648 8.042 42.018 24.123 58.095 16.085 16.091 35.453 24.133 58.101 24.133h91.365c2.475 0 4.422-.622 5.852-1.854 1.425-1.239 2.375-3.094 2.853-5.571.476-2.471.763-4.716.859-6.707.094-1.999.048-4.518-.144-7.563-.191-3.048-.284-4.95-.284-5.714z"
		/>
		<path
			fill={color}
			d="M442.249 210.989L286.935 55.67c-3.614-3.612-7.898-5.424-12.847-5.424s-9.233 1.812-12.851 5.424c-3.617 3.617-5.424 7.904-5.424 12.85v82.226H127.907c-4.952 0-9.233 1.812-12.85 5.424-3.617 3.617-5.424 7.901-5.424 12.85v109.636c0 4.948 1.807 9.232 5.424 12.847 3.621 3.61 7.901 5.427 12.85 5.427h127.907v82.225c0 4.945 1.807 9.233 5.424 12.847 3.617 3.617 7.901 5.428 12.851 5.428 4.948 0 9.232-1.811 12.847-5.428L442.249 236.69c3.617-3.62 5.425-7.898 5.425-12.848 0-4.948-1.808-9.236-5.425-12.853z"
		/>
	</svg>
)

export default SvgComponent
