import PropType from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import Api from "../../../../../api"
import { Dropzone, Input, Select, Textarea } from "../../../../../components"
import { Modal, Spinner } from "../../../../../components/index"
import { AppContext } from "../../../../../context/AppContext"

const CourseEdit = ({ location, history }) => {
	const { course } = location.state
	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	console.log(course)

	const [isSending, setIsSending] = useState(false)
	const [errorObj, setErrorObj] = useState({})
	const [bannerFiles, setBannerFiles] = useState([])
	const [videoFiles, setVideoFiles] = useState([])
	const [directionId, setDirectionId] = useState(course?.direction_id || "")
	const [directionList, setDirectionList] = useState([])

	const [downloadPercent, setDownloadPercent] = useState(0)

	const { register, handleSubmit } = useForm({
		defaultValues: {
			direction_id: course?.direction_id || "",
			name: course?.name || "",
			price: course?.price || "",
			old_price: course?.old_price || "",
			description: course?.description || "",
		},
	})

	const changeDirectionIdHandle = e => setDirectionId(e.target.value)

	const getDirectionList = () => {
		Api.get("/direction/list", { headers: { Authorization } }).then(res => {
			const { data, error } = res.data

			if (!error) {
				setDirectionList(data)
			} else {
				console.error(data)
			}
		})
	}

	const onCourseEdit = data => {
		if (!isSending) {
			setIsSending(true)

			const { name, description, price, old_price } = data

			let errObj = {}

			if (price < 0) errObj = { ...errObj, price: "Некорректная сумма" }
			if (old_price && +old_price < +price)
				errObj = { ...errObj, old_price: "Старая цена должна быть больше" }

			setErrorObj(errObj)

			if (Object.keys(errObj).length === 0) {
				const formData = new FormData()
				formData.append("name", name)
				formData.append("name", name)
				formData.append("description", description)
				formData.append("price", price)
				formData.append("old_price", old_price)
				formData.append("direction_id", directionId)

				if (bannerFiles.length > 0) formData.append("banner", bannerFiles[0])

				Api.post(`/course/update/${course.id}`, formData, {
					headers: { Authorization },
					onUploadProgress: progressEvent => {
						const percentCompleted = Math.round(
							(progressEvent.loaded * 100) / progressEvent.total
						)
						setDownloadPercent(percentCompleted)
					},
				})
					.then(res => {
						const { data, error } = res.data

						if (!error) {
							alert(data)
							setBannerFiles([])
							setVideoFiles([])
							history.goBack()
						} else {
							window.scrollTo(0, 0)
							console.error(data)
							alert("Ошибка")
							setErrorObj(data)
						}
					})
					.finally(() => {
						setDownloadPercent(0)
						setIsSending(false)
					})
			}
		}
	}

	const togglePublic = () => {
		let value
		if (course.isPublic == true) {
			value = false
		} else value = true

		console.log(course.isPublic)

		Api.post(
			`/publish/index?course_id=${course.id}`,
			{ public: value },
			{ headers: { Authorization } }
		).then(res => {
			const { data, error } = res.data

			if (!error) {
				history.goBack()
				alert("Настройки публикации изменены")
			} else {
				console.error(data)
				alert("Ошибка")
			}
		})
	}

	const onFileBannerRemove = fileName => {
		setBannerFiles(bannerFiles.filter(f => f.name !== fileName))
	}
	const onFileBannerUpload = filesData => setBannerFiles(filesData)

	const onFileVideoRemove = fileName => {
		setVideoFiles(videoFiles.filter(f => f.name !== fileName))
	}

	const onFileVideoUpload = filesData => setVideoFiles(filesData)

	useEffect(() => {
		getDirectionList()
	}, [])

	return (
		<>
			<div className="page-header">
				<div className="row ai-center jc-between">
					<div className="col-auto">
						<h6 className="title">Редактирование курса</h6>
					</div>

					<div className="col-auto">
						<button
							onClick={() => history.goBack()}
							className="btn btn-primary btn-lg">
							Назад
						</button>
					</div>
				</div>
			</div>

			<form className="form" onSubmit={handleSubmit(onCourseEdit)}>
				<div className="row">
					<div className="col-md-5 col-xl-4 col-xxl-3">
						<div className="card field">
							<div className="card-body">
								<img src={course.banner} alt="" className="card-img _block" />
							</div>
						</div>
					</div>

					<div className="col-md-7 col-xl-8 col-xxl-9">
						<div className="row">
							<div className="col-sm-6">
								<div className="field">
									<Select
										label="Профессия"
										disabled={directionList === 0}
										items={directionList}
										onChange={changeDirectionIdHandle}
										value={directionId}
									/>
								</div>
							</div>

							<div className="col-sm-6">
								<div className="field">
									<Input
										required
										label="Название"
										placeholder="Название"
										name="name"
										register={register}
										error={errorObj.name}
									/>
								</div>
							</div>

							<div className="col-sm-6">
								<div className="field">
									<Input
										required
										label="Цена"
										placeholder="Цена"
										name="price"
										register={register}
										error={errorObj.price}
									/>
								</div>
							</div>

							<div className="col-sm-6">
								<div className="field">
									<Input
										label="Старая цена"
										placeholder="Старая цена"
										name="old_price"
										register={register}
										error={errorObj.old_price}
									/>
								</div>
							</div>

							<div className="col-12">
								<div className="field">
									<Textarea
										required
										label="Описание"
										placeholder="Описание"
										name="description"
										register={register}
										rows="9"
										error={errorObj.description}
									/>
								</div>
							</div>
							<div className="col-12">
								<div className="field">
									<label className="d-flex ai-center cursor-pointer mt-2">
										<input
											type="checkbox"
											onChange={() => togglePublic()}
											checked={course.isPublic}
											className="input-checkbox mr-2"
											ref={register}
											name="public"
										/>
										Опубликовать
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-6">
						<div className="field">
							<div className="h6 mb-2">Загрузка баннера</div>
							<Dropzone
								multiple={false}
								maxSize={5000000}
								acceptTypes="image/png, image/jpeg, image/gif"
								files={bannerFiles}
								onFileRemove={onFileBannerRemove}
								onFileUpload={onFileBannerUpload}
							/>
						</div>
					</div>

					<div className="col-6">
						<div className="field">
							<div className="h6 mb-2">Загрузка видео</div>
							<Dropzone
								multiple={false}
								maxSize={2000000000}
								acceptTypes="video/*"
								files={videoFiles}
								onFileRemove={onFileVideoRemove}
								onFileUpload={onFileVideoUpload}
							/>
						</div>
					</div>
				</div>

				<button className="btn btn-primary btn-lg">Отредактировать</button>

				<Modal
					show={isSending}
					showClose={false}
					modalWrapperStyles={{ maxWidth: 300 }}>
					<div className="ta-center">
						<Spinner size={100} />
						<div className="font-700 mt-2">{parseInt(downloadPercent)}%</div>
					</div>
				</Modal>
			</form>
		</>
	)
}

CourseEdit.propTypes = {
	location: PropType.object,
	history: PropType.object,
}

export default CourseEdit
