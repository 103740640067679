import React, { useContext, useEffect, useState } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { Link } from "react-router-dom"
import Api from "../../../../../api"
import { Select, Spinner } from "../../../../../components"
import { Pagination } from "../../../../../components/index"
import { AppContext } from "../../../../../context/AppContext"
import { IconEdit, IconTrash, IconWatch } from "../../../../../static/icons"
import {
	addUrlParam,
	clearUrlParams,
	getUrlParams,
	removeUrlParam,
} from "../../../../../utils/urlManipulation"

const STATUS_LIST = ["Не опубликован", "Опубликован"]

const LessonList = () => {
	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const role = state.roleList.lesson

	const [page, setPage] = useState(1)
	const [lastPage, setLastPage] = useState(null)

	const [sortedLessonId, setSortedLessonId] = useState(null)

	const URL_PARAMS = getUrlParams()
	const directionParamId = URL_PARAMS["direction"]
	const courseParamId = URL_PARAMS["course"]
	const moduleParamId = URL_PARAMS["module"]

	const [isLoading, setIsLoading] = useState(true)
	const [sortAttrName, setSortAttrName] = useState(null)
	const [eduList, setEduList] = useState({
		directionList: [],
		courseList: [],
		moduleList: [],
		lessonList: [],
	})
	const [eduIds, setEduIds] = useState({
		directionId: null,
		courseId: null,
		moduleId: null,
	})

	const changeDirectionIdHandle = e => {
		const value = +e.target.value

		clearUrlParams()
		addUrlParam("direction", value)
		setEduIds({ ...eduIds, directionId: value })
	}
	const changeCourseIdHandle = e => {
		const value = +e.target.value

		removeUrlParam(["module"])
		addUrlParam("course", value)
		setEduIds({ ...eduIds, courseId: value })
	}
	const changeModuleIdHandle = e => {
		const value = +e.target.value

		addUrlParam("module", value)
		setEduIds({ ...eduIds, moduleId: value })
	}

	const getDirectionList = () => {
		setIsLoading(true)

		Api.get("/direction/list?sort=name", { headers: { Authorization } }).then(
			res => {
				const { data, error } = res.data

				if (!error) {
					setEduList({ ...eduList, directionList: data })

					if (data.length) {
						setEduIds({
							...eduIds,
							directionId: directionParamId || data[0].id,
						})
					}
				} else {
					console.error(data)
					alert("Ошибка")
				}
			}
		)
	}

	const getCourseList = () => {
		if (eduIds.directionId) {
			setIsLoading(true)

			Api.get(`/course/list/${eduIds.directionId}`, {
				headers: { Authorization },
			}).then(res => {
				const { data, error } = res.data

				if (!error) {
					setEduList({ ...eduList, courseList: data })

					if (data.length) {
						setEduIds({ ...eduIds, courseId: courseParamId || data[0].id })
					}
				} else {
					console.error(data)
					alert("Ошибка")
				}
			})
		}
	}

	const getModuleList = () => {
		if (eduIds.courseId) {
			setIsLoading(true)

			Api.get(`/module/list/${eduIds.courseId}`, {
				headers: { Authorization },
			}).then(res => {
				const { data, error } = res.data

				if (!error) {
					setEduList({ ...eduList, moduleList: data })

					if (data.length) {
						setEduIds({ ...eduIds, moduleId: moduleParamId || data[0].id })
					} else {
						setEduIds({ ...eduIds, moduleId: null })
						setEduList({ ...eduList, lessonList: [] })
						setIsLoading(false)
					}
				} else {
					console.error(data)
					alert("Ошибка")
				}
			})
		}
	}

	const getLessonList = () => {
		if (eduIds.moduleId) {
			setIsLoading(true)

			let link = `/lesson/list/${eduIds.moduleId}?&page=${page}&limit=10`

			if (sortAttrName) {
				link = `${link}&sort=${sortAttrName}`
			}

			Api.get(link, { headers: { Authorization } }).then(res => {
				setLastPage(res.data.lastPage)
				const { data, error } = res.data

				if (!error) {
					setEduList({ ...eduList, lessonList: data })
				} else {
					console.error(data)
					alert("Ошибка")
				}
			})
		}

		setIsLoading(false)
	}

	const removeLesson = id => {
		if (window.confirm("Удалить урок?")) {
			Api.delete(`/lesson/${id}`, { headers: { Authorization } }).then(res => {
				const { error, data } = res.data

				if (!error) {
					setEduList({
						...eduList,
						lessonList: eduList.lessonList.filter(sl => sl.id !== id),
					})
				} else {
					console.error(data)
					alert("Ошибка")
				}
			})
		}
	}

	const sortDataHandle = sortName => {
		let direction = sortName

		if (sortAttrName === sortName) {
			direction = `-${sortName}`
		} else if (sortAttrName === `-${sortName}`) {
			direction = sortName
		}

		setSortAttrName(direction)
	}

	//Перемещение уроков

	const sendDraggedArr = () => {
		Api.post(
			`/lesson/sort/${eduIds.moduleId}`,
			{ ids: sortedLessonId },
			{
				headers: { Authorization },
			}
		).then(res => {
			const { error, data } = res

			if (!error) {
				console.log(res)
			} else {
				console.error(data)
			}
		})
	}

	const handleOnDragEnd = result => {
		if (!result.destination) {
			return
		}
		const items = Array.from(eduList.lessonList)
		const [reorderItem] = items.splice(result.source.index, 1)
		items.splice(result.destination.index, 0, reorderItem)

		setEduList({ lessonList: items })
		let arr = []
		for (let i = 0; i < items.length; i++) {
			arr.push(items[i].id)
		}
		setSortedLessonId(arr)
	}

	useEffect(() => {
		sendDraggedArr()
	}, [sortedLessonId])

	const getClassName = name =>
		sortAttrName === name ? "_asc" : sortAttrName === `-${name}` ? "_desc" : ""

	useEffect(() => {
		getDirectionList()
	}, [])

	useEffect(() => {
		getCourseList()
	}, [eduIds.directionId])

	useEffect(() => {
		getModuleList()
	}, [eduIds.courseId])

	useEffect(() => {
		getLessonList()
	}, [eduIds.moduleId, sortAttrName])

	return (
		<>
			<div className="page-header">
				<div className="row ai-center jc-between">
					<div className="col-md-auto">
						<h6 className="title">Уроки</h6>
					</div>

					{role.create && (
						<div className="col-md-auto">
							<Link to="/lesson/create" className="btn btn-primary">
								Добавить урок
							</Link>
						</div>
					)}
				</div>
			</div>

			<div className="form row">
				<div className="col-md-4 field">
					<Select
						label="Профессия"
						items={eduList.directionList}
						onChange={changeDirectionIdHandle}
						value={eduIds.directionId || ""}
					/>
				</div>

				<div className="col-md-4 field">
					<Select
						label="Курс"
						items={eduList.courseList}
						onChange={changeCourseIdHandle}
						value={eduIds.courseId || ""}
					/>
				</div>

				<div className="col-md-4 field">
					<Select
						label="Модуль"
						items={eduList.moduleList}
						onChange={changeModuleIdHandle}
						value={eduIds.moduleId || ""}
					/>
				</div>
			</div>

			{!isLoading ? (
				<div className="card">
					<div className="card-body">
						<DragDropContext onDragEnd={handleOnDragEnd}>
							<table>
								<thead>
									<tr>
										<th
											className={`sortable ${getClassName("name")}`}
											onClick={() => sortDataHandle("name")}>
											Название
										</th>
										<th>Описание</th>
										<th>Статус</th>
										{(role.update || role.view || role.delete) && <th></th>}
									</tr>
								</thead>
								<Droppable droppableId="tbody">
									{provided => (
										<tbody
											className="tbody"
											{...provided.droppableProps}
											ref={provided.innerRef}>
											{eduList.lessonList.map((item, index) => (
												<Draggable
													key={item.id}
													draggableId={item.name}
													index={index}>
													{provided => (
														<tr
															className="tr"
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															ref={provided.innerRef}>
															<td>
																<div className="font-500">{item.name}</div>
															</td>
															<td>
																<p className="short-text">{item.description}</p>
															</td>
															<td>
																<p>{STATUS_LIST[item.public]}</p>
															</td>
															<td className="actions">
																<div className="row row-xs">
																	{role.view && (
																		<div className="col-auto">
																			<Link
																				to={{
																					pathname: `/lesson/view/${item.id}`,
																					state: {
																						lesson: item,
																					},
																				}}
																				className="btn btn-sm btn-primary">
																				<IconWatch size={14} color="#fff" />
																			</Link>
																		</div>
																	)}

																	{role.update && (
																		<div className="col-auto">
																			<Link
																				to={{
																					pathname: `/lesson/edit/${item.id}`,
																					state: {
																						lesson: item,
																						moduleId: eduIds.moduleId,
																					},
																				}}
																				className="btn btn-sm btn-primary ">
																				<IconEdit size={14} color="#fff" />
																			</Link>
																		</div>
																	)}

																	{role.delete && (
																		<div className="col-auto">
																			<button
																				onClick={() => removeLesson(item.id)}
																				className="btn btn-sm btn-danger">
																				<IconTrash size={14} color="#fff" />
																			</button>
																		</div>
																	)}
																</div>
															</td>
														</tr>
													)}
												</Draggable>
											))}
											{provided.placeholder}
										</tbody>
									)}
								</Droppable>
							</table>
						</DragDropContext>
						<Pagination
							currentPage={page}
							totalPages={lastPage || 1}
							onChangePage={setPage}
						/>
					</div>
				</div>
			) : (
				<div className="d-flex jc-center">
					<Spinner size={100} styles={{ marginTop: 100 }} />
				</div>
			)}
		</>
	)
}

export default LessonList
