/* eslint-disable react/prop-types */
import React, { useContext } from "react"
import { AppContext } from "../../context/AppContext"
import { IconTrash } from "../../static/icons"

const PresentationListTable = ({ data, onRemove }) => {
	const { state } = useContext(AppContext)
	const role = state.roleList

	return (
		<table>
			<thead>
				<tr>
					<th>Название</th>
					{role.document.delete && <th className="actions" />}
				</tr>
			</thead>

			<tbody>
				{Object.keys(data).length > 0 ? (
					<tr key={data.id}>
						<td>{data.name}</td>
						{role.document.delete && (
							<td>
								<button
									onClick={() => onRemove(data.id)}
									className="btn btn-sm btn-danger">
									<IconTrash size={14} color="#fff" />
								</button>
							</td>
						)}
					</tr>
				) : (
					<tr>
						<td colSpan="2" className="ta-center">
							Презентации отсутствуют
						</td>
					</tr>
				)}
			</tbody>
		</table>
	)
}

export default PresentationListTable
