import PropType from "prop-types"
import React, { useContext, useState } from "react"
import { useForm } from "react-hook-form"
import Api from "../../../../../api"
import { Dropzone, Input } from "../../../../../components"
import { Modal, Spinner } from "../../../../../components/index"
import { AppContext } from "../../../../../context/AppContext"
import "./FaqSectionEdit.scss"

const FaqSectionEdit = ({ location, history }) => {
	const { faqDirection } = location.state

	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const [isSending, setIsSending] = useState(false)
	const [errorObj, setErrorObj] = useState({})
	const [files, setFiles] = useState([])

	const { register, handleSubmit } = useForm({
		defaultValues: {
			name: faqDirection.name || ""
		}
	})

	const onFileRemove = fileName =>
		setFiles(files.filter(f => f.name !== fileName))
	const onFileUpload = filesData => setFiles(filesData)

	const onEditData = data => {
		if (!isSending) {
			setIsSending(true)

			const { name } = data

			let errObj = {}

			if (name.trim().length === 0)
				errObj = { ...errObj, name: "Название не может быть пустым" }

			setErrorObj(errObj)

			if (Object.keys(errObj).length === 0) {
				const formData = new FormData()
				formData.append("name", name)

				if (files.length > 0) formData.append("icon", files[0])

				Api.post(`/section-faq/update/${faqDirection.id}`, formData, {
					headers: { Authorization }
				}).then(res => {
					const { error, data } = res.data

					if (!error) {
						alert("Успешно отредактировано")
						setErrorObj({})
						history.goBack()
					} else {
						setErrorObj(data)
					}
				})
			}

			setIsSending(false)
		}
	}

	return (
		<div className="page-faq-section-edit">
			<div className="page-header">
				<div className="row ai-center jc-between">
					<div className="col-md-auto">
						<h6 className="title">Редактирование раздела частого вопроса</h6>
					</div>

					<div className="col-md-auto">
						<button
							onClick={() => history.goBack()}
							className="btn btn-primary btn-lg">
							Назад
						</button>
					</div>
				</div>
			</div>

			<form className="form" onSubmit={handleSubmit(onEditData)}>
				<div className="row">
					<div className="col-lg-2">
						<img
							src={faqDirection.icon}
							alt={faqDirection.name}
							className="icon-render"
							style={{ maxWidth: "100%" }}
						/>
					</div>

					<div className="col-lg-10">
						<div className="field">
							<Input
								required
								label="Название раздела"
								placeholder="Название раздела"
								name="name"
								register={register}
								error={errorObj.name}
							/>
						</div>

						<div className="field">
							<div className="card">
								<div className="card-body">
									<Dropzone
										multiple={false}
										maxSize={5000000}
										acceptTypes="image/*"
										files={files}
										onFileRemove={onFileRemove}
										onFileUpload={onFileUpload}
									/>
								</div>
							</div>
						</div>

						<button className="btn btn-primary" type="submit">
							Отредактировать
						</button>
					</div>
				</div>
			</form>

			<Modal
				show={isSending}
				showClose={false}
				modalWrapperStyles={{ maxWidth: 300 }}>
				<div className="ta-center">
					<Spinner size={100}/>
				</div>
			</Modal>
		</div>
	)
}

FaqSectionEdit.propTypes = {
	location: PropType.object,
	history: PropType.object
}

export default FaqSectionEdit
