import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Api from "../../api"
import { AppContext } from "../../context/AppContext"
import { IconWatch } from "../../static/icons"
import IconEdit from "../../static/icons/Edit"
import IconTrash from "../../static/icons/Trash"

const TestTableList = ({
	list = [],
	type,
	id,
	lessonVersions = [],
	courseId,
	testDelete,
}) => {
	const [dataList, setDataList] = useState(list)

	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`
	const role = state.roleList

	/**
	 * Удаление тестов
	 **/
	const removeLessonTestList = () => {
		if (window.confirm("Удалить тест?")) {
			Api.delete(`/test?${type}=${id}`, { headers: { Authorization } }).then(
				res => {
					const { data, error } = res.data

					if (!error) {
						testDelete()
					} else {
						alert(data)
					}
				}
			)
		}
	}

	useEffect(() => {
		setDataList(list)
	}, [list])

	return (
		<table>
			<thead>
				<tr>
					<th>Информация по тесту</th>
					{(role.test.view || role.test.update || role.test.delete) && (
						<th className="actions">
							{dataList.length > 0 && (
								<div className="row row-xs">
									{role.test.view && (
										<div className="col-auto">
											<Link
												to={{
													pathname: `/test/view/${id}`,
													state: {
														test: dataList,
														type,
													},
												}}
												className="btn btn-sm btn-primary">
												<IconWatch size={14} color="#fff" />
											</Link>
										</div>
									)}

									{role.test.update && (
										<div className="col-auto">
											<Link
												to={{
													pathname: `/test/edit/${id}`,
													state: {
														id,
														type,
														lessonVersions,
														courseId,
													},
												}}
												className="btn btn-sm btn-primary">
												<IconEdit size={14} color="#fff" />
											</Link>
										</div>
									)}

									{role.test.delete && (
										<div className="col-auto">
											<button
												onClick={removeLessonTestList}
												className="btn btn-sm btn-danger">
												<IconTrash size={14} color="#fff" />
											</button>
										</div>
									)}
								</div>
							)}
						</th>
					)}
				</tr>
			</thead>

			<tbody>
				{dataList.length > 0 ? (
					dataList.map(item => (
						<tr key={item.id}>
							<td colSpan="2">
								<div className="font-500">{item.question}</div>
							</td>
						</tr>
					))
				) : (
					<tr>
						<td colSpan="2" className="ta-center">
							Тесты отсутствуют
						</td>
					</tr>
				)}
			</tbody>
		</table>
	)
}
TestTableList.propTypes = {
	list: PropTypes.any,
	type: PropTypes.any,
	id: PropTypes.any,
	lessonVersions: PropTypes.any,
	courseId: PropTypes.any,
	testDelete: PropTypes.any,
}

export default TestTableList
