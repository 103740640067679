import PropType from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Api from "../../../../../api"
import { Input, Textarea } from "../../../../../components"
import HomeworkListTable from "../../../../../components/HomeworkListTable/HomeworkListTable"
import { Spinner } from "../../../../../components/index"
import { AppContext } from "../../../../../context/AppContext"

const CourseView = ({ history, match }) => {
	const { id: courseId } = match.params

	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const [course, setCourse] = useState(null)
	const [homeworkList, setHomeworkList] = useState([])

	console.log("course", course)


	const getCourseData = () => {
		Api.get(`/course/${courseId}`, { headers: { Authorization } }).then(res => {
		
			const { data, error } = res.data
			if (!error) {
				setCourse(data)
			} else {
				alert(data)
			}
		})
	}

	// получение домашек
	const getHomeworkList = () => {
		Api.get(`/homework/list?course_id=${courseId}`, {
			headers: { Authorization }
		}).then(res => {
			const { data, error } = res.data

			if (!error) {
				if (data.length) {
					setHomeworkList(data)
				}
			} else {
				console.error(data)
				alert("Ошибка")
			}
		})
	}

	/**
	 * Удаление домашних заданий
	 **/
	const removeHomework = id => {
		if (window.confirm("Удалить работу?")) {
			Api.delete(`/homework/${id}`, {
				headers: { Authorization }
			}).then(res => {
				const { data, error } = res.data

				if (!error) {
					setHomeworkList(homeworkList.filter(i => i.id !== id))
					alert(data)
				} else {
					alert(data)
					console.error(data)
				}
			})
		}
	}

	useEffect(() => {
		getCourseData()
		getHomeworkList()
	}, [])

	if (!course) {
		return (
			<div className="d-flex ai-center jc-center flex-fill">
				<Spinner/>
			</div>
		)
	}

	return (
		<>
			<div className="page-header">
				<div className="row ai-center jc-between">
					<div className="col-md">
						<h6 className="title">Просмотр курса</h6>
					</div>

					<div className="col-md-auto">
						<div onClick={() => history.goBack()} className="btn btn-primary">
							Назад
						</div>
					</div>
				</div>
			</div>

			<form className="form">
				<div className="row">
					<div className="col-lg-3">
						<div className="card">
							<div className="card-body">
								<img src={course.banner} alt="" className="card-img _block"/>
							</div>
						</div>
					</div>

					<div className="col-lg-9">
						<div className="row">
							<div className="col-md-6">
								<div className="field">
									<Input
										label="Название"
										defaultValue={course.name}
										name="name"
										disabled
									/>
								</div>
							</div>

							<div className="col-md-3">
								<div className="field">
									<Input
										required
										label="Цена"
										defaultValue={course.price}
										name="price"
										disabled
									/>
								</div>
							</div>

							<div className="col-md-3">
								<div className="field">
									<Input
										label="Старая цена"
										defaultValue={course.old_price}
										name="old_price"
										disabled
									/>
								</div>
							</div>

							<div className="col-12">
								<div className="field">
									<Textarea
										required
										label="Описание"
										defaultValue={course.description}
										name="description"
										rows="8"
										disabled
									/>
								</div>
							</div>
							<div className="col-12">
								<div className="field">
									<label className="d-flex ai-center cursor-pointer mt-2">
										<input
											type="checkbox"
											checked={course.isPublic}
											className="input-checkbox mr-2"
											readOnly
										/>
										Публикация
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>

			<div className="mt-5 page-header">
				<div className="row ai-center jc-between">
					<div className="col-md-auto">
						<h6 className="title">Курсовые работы</h6>
					</div>
					
					{!homeworkList.length && (
						<div className="col-md-auto">
							<Link
								to={{
									pathname: "/homework/create",
									state: {
										id: courseId,
										type: "course_id"
									}
								}}
								className="btn btn-primary">
								Добавить домашнее задание
							</Link>
						</div>
					)}
				</div>
			</div>
			<div className="card">
				<div className="card-body">
					<HomeworkListTable data={homeworkList} onRemove={removeHomework}/>
				</div>
			</div>
		</>
	)
}

CourseView.propTypes = {
	history: PropType.object,
	match: PropType.object
}

export default CourseView
