import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import Api from "../../../../../api"
import { Input, Textarea } from "../../../../../components"
import { AppContext } from "../../../../../context/AppContext"
import "./FaqQuestionView.scss"

const FaqQuestionView = ({ location, history }) => {
	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const { faq, sectionId } = location.state

	const [sectionData, setSectionData] = useState([])

	const getSectionList = () => {
		Api.get("/section-faq/list", { headers: { Authorization } }).then(res => {
			const { data, error } = res.data
			if (!error) {
				if (data.length) {
					setSectionData(data.filter(item => item.id === +sectionId))
				} else {
					console.error(data)
					alert("Ошибка")
				}
			}
		})
	}

	useEffect(() => {
		getSectionList()
	}, [])

	return (
		<div className="page-faq-section-view">
			<div className="page-header">
				<div className="row ai-center jc-between">
					<div className="col-md-auto">
						<h6 className="title">Просмотр частого вопроса</h6>
					</div>

					{/*<Link to={`/faq/section/list/${faq.id}`} className="btn btn-primary">*/}
					{/*	Добавить вопрос*/}
					{/*</Link>*/}
					<div className="col-md-auto">
						<button
							onClick={() => history.goBack()}
							className="btn btn-primary btn-lg">
							Назад
						</button>
					</div>
				</div>
			</div>

			<div className="form row">
				<div className="col-md-6">
					<div className="field">
						<Input
							required
							disabled
							label="Раздел"
							placeholder="Раздел"
							value={sectionData[0]?.name || ""}
						/>
					</div>
				</div>

				<div className="col-md-6">
					<div className="field">
						<Input
							required
							disabled
							label="Вопрос"
							placeholder="Вопрос"
							value={faq.question}
						/>
					</div>
				</div>

				<div className="col-12">
					<div className="field">
						<Textarea
							required
							disabled
							label="Ответ"
							placeholder="Ответ"
							rows={20}
							value={faq.answer}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}
FaqQuestionView.propTypes = {
	location: PropTypes.any,
	history: PropTypes.any
}

export default FaqQuestionView
