import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import Api from "../../../../api"
import { Input } from "../../../../components"
import { Modal, Select } from "../../../../components/index"
import { AppContext } from "../../../../context/AppContext"
import { IconDocs } from "../../../../static/icons/index"
import defaultAvatar from "../../../../static/imgs/users/default_avatar.svg"
import { addUrlParam, clearUrlParams, getUrlParams, removeUrlParam } from "../../../../utils/urlManipulation"
import "./UserView.scss"

const STATUS_LIST = ["Отсутствует", "Новый", "В работе", "Завершён", "Провален"]

const UserView = ({ match }) => {
	const { id: userId } = match.params

	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const URL_PARAMS = getUrlParams()
	const directionParamId = URL_PARAMS["direction"]
	const courseParamId = URL_PARAMS["course"]
	const moduleParamId = URL_PARAMS["module"]
	const lessonParamId = URL_PARAMS["lesson"]

	const [showRoleModal, setShowRoleModal] = useState(false)
	const [roleList, setRoleList] = useState([])
	const [lessonInfo, setLessonInfo] = useState({})
	const [user, setUser] = useState({})
	const [isLoading, setIsLoading] = useState(true)
	const [eduList, setEduList] = useState({
		directionList: [],
		courseList: [],
		moduleList: [],
		lessonList: []
	})
	const [eduIds, setEduIds] = useState({
		directionId: null,
		courseId: null,
		moduleId: null,
		lessonId: null
	})

	const changeDirectionIdHandle = e => {
		const value = +e.target.value

		clearUrlParams()
		addUrlParam("direction", value)
		setEduIds({ ...eduIds, directionId: value })
	}
	const changeCourseIdHandle = e => {
		const value = +e.target.value

		removeUrlParam(["module", "lesson"])
		addUrlParam("course", value)
		setEduIds({ ...eduIds, courseId: value })
	}
	const changeModuleIdHandle = e => {
		const value = +e.target.value

		removeUrlParam(["lesson"])
		addUrlParam("module", value)
		setEduIds({ ...eduIds, moduleId: value })
	}
	const changeLessonIdHandle = e => {
		const value = +e.target.value

		addUrlParam("lesson", value)
		setEduIds({ ...eduIds, lessonId: value })
	}

	const getDirectionList = () => {
		setIsLoading(true)

		Api.get(`/direction/list?name&user_id=${userId}`, {
			headers: { Authorization }
		}).then(res => {
			const { data, error } = res.data

			if (!error) {
				setEduList({ ...eduList, directionList: data })

				if (data.length) {
					setEduIds({ ...eduIds, directionId: directionParamId || data[0].id })
				}
			} else {
				console.error(data)
				alert("Ошибка")
			}
		})
	}

	const getCourseList = () => {
		if (eduIds.directionId) {
			setIsLoading(true)

			Api.get(`/course/list/${eduIds.directionId}?user_id=${userId}`, {
				headers: { Authorization }
			}).then(res => {
				const { data, error } = res.data

				if (!error) {
					setEduList({ ...eduList, courseList: data })

					if (data.length) {
						setEduIds({ ...eduIds, courseId: courseParamId || data[0].id })
					}
				} else {
					console.error(data)
					alert("Ошибка")
				}
			})
		}
	}

	const getModuleList = () => {
		if (eduIds.courseId) {
			setIsLoading(true)

			Api.get(`/module/list/${eduIds.courseId}?user_id=${userId}`, {
				headers: { Authorization }
			}).then(res => {
				const { data, error } = res.data

				if (!error) {
					setEduList({ ...eduList, moduleList: data })

					if (data.length) {
						setEduIds({ ...eduIds, moduleId: moduleParamId || data[0].id })
					} else {
						setEduIds({ ...eduIds, moduleId: null })
						setIsLoading(false)
					}
				} else {
					console.error(data)
					alert("Ошибка")
				}
			})
		}
	}

	const getLessonList = () => {
		if (eduIds.moduleId) {
			setIsLoading(true)

			Api.get(`/lesson/list/${eduIds.moduleId}?user_id=${userId}`, {
				headers: { Authorization }
			}).then(res => {
				const { data, error } = res.data

				if (!error) {
					if (data.length) {
						setEduIds({ ...eduIds, lessonId: lessonParamId || data[0].id })
						setEduList({ ...eduList, lessonList: data })
					} else {
						setEduIds({ ...eduIds, lessonId: null })
						setEduList({ ...eduList, lessonList: [] })
						setIsLoading(false)
					}
				} else {
					console.error(data)
					alert("Ошибка")
				}
			})
		}

		setIsLoading(false)
	}

	const getUserInfo = () => {
		Api.get(`/user/${userId}`, { headers: { Authorization } }).then(res => {
			const { error, data } = res.data

			if (!error) {
				setUser({ ...data.profile, email: data.email, roles: data.roles })
			} else {
				alert("Ошибка")
				console.error(data)
			}
		})
	}

	const getLessonInfo = () => {
		Api.get(`/user/user-lesson/${eduIds.lessonId}?user_id=${userId}`, {
			headers: { Authorization }
		}).then(res => {
			const { data, error } = res.data

			if (!error) {
				setLessonInfo(data)
			} else {
				console.error(data)
				alert("Ошибка")
			}
		})
	}

	const downloadFile = (link, fileName) => {
		Api.get(link, { headers: { Authorization } }).then(res => {
			const { error, data } = res.data

			if (!error) {
				downloadBase64File(data, fileName)
			} else {
				console.error(data)
				alert("Ошибка")
			}
		})
	}

	const downloadBase64File = (base64Data, fileName) => {
		const downloadLink = document.createElement("a")
		downloadLink.href = base64Data
		downloadLink.download = fileName
		downloadLink.click()
	}

	const getRoleList = async () => {
		try {
			const res = await Api.get("/access/list", { headers: { Authorization } })
			const { data } = res.data

			setRoleList(data)
		} catch (e) {
			console.error(e)
		}
	}

	const onChangeRole = async (e, role) => {
		e.persist()

		try {
			const res = await Api.post(
				`/access/${e.target.checked ? "add" : "remove"}-role/${userId}`,
				{ role },
				{ headers: { Authorization } }
			)
			const { data, error } = res.data

			if (!error) {
				let roles = user.roles

				if (e.target.checked) {
					roles.push(role)
				} else {
					roles = roles.filter(r => r !== role)
				}

				setUser({ ...user, roles })
			} else {
				alert(data)
			}
		} catch (e) {
			console.error(e)
			alert("Ошибка")
		}
	}
	console.log("USERINFO", user)
	useEffect(() => {
		getUserInfo()
		getDirectionList()
		getRoleList()
	}, [])

	useEffect(() => {
		getCourseList()
	}, [eduIds.directionId])

	useEffect(() => {
		getModuleList()
	}, [eduIds.courseId])

	useEffect(() => {
		getLessonList()
	}, [eduIds.moduleId])

	useEffect(() => {
		eduIds.lessonId && getLessonInfo()
	}, [eduIds.lessonId])

	return (
		<div className="page-user-view">
			<div className="page-header">
				<h6 className="title">Просмотр пользователя</h6>
			</div>

			<form className="form row">
				<div className="col-12 user-icon field">
					<div className="row ai-center">
						<div className="col-auto">
							<img
								src={user?.avatar || defaultAvatar}
								alt=""
								className="avatar-circle avatar-md image-cover"
							/>
						</div>

						{roleList.length ? (
							<div className="col-auto">
								<div
									className="btn btn-primary btn-sm"
									onClick={() => setShowRoleModal(true)}>
									Сменить роль
								</div>
							</div>
						) : null}
					</div>
				</div>

				<div className="col-xs-4">
					<div className="field">
						<Input label="Имя" defaultValue={user?.first_name || ""} disabled/>
					</div>
				</div>

				<div className="col-xs-4">
					<div className="field">
						<Input
							label="Фамилия"
							defaultValue={user?.last_name || ""}
							disabled
						/>
					</div>
				</div>

				<div className="col-xs-4">
					<div className="field">
						<Input
							defaultValue={user?.patronymic || ""}
							label="Отчество"
							disabled
						/>
					</div>
				</div>

				<div className="col-xs-4">
					<div className="field">
						<Input
							label="Дата рождения"
							defaultValue={user?.birthDate || ""}
							disabled
						/>
					</div>
				</div>

				<div className="col-xs-4">
					<div className="field">
						<Input label="Email" value={user.email || ""} disabled/>
					</div>
				</div>

				<div className="col-12 mt-5">
					<div className="row">
						<div className="col-md-3 field">
							<Select
								label="Профессия"
								items={eduList.directionList}
								onChange={changeDirectionIdHandle}
								value={eduIds.directionId || ""}
							/>
						</div>

						<div className="col-md-3 field">
							<Select
								label="Курс"
								items={eduList.courseList}
								onChange={changeCourseIdHandle}
								value={eduIds.courseId || ""}
							/>
						</div>

						<div className="col-md-3 field">
							<Select
								label="Модуль"
								items={eduList.moduleList}
								onChange={changeModuleIdHandle}
								value={eduIds.moduleId || ""}
							/>
						</div>

						<div className="col-md-3 field">
							<Select
								label="Урок"
								items={eduList.lessonList}
								onChange={changeLessonIdHandle}
								value={eduIds.lessonId || ""}
							/>
						</div>
					</div>
				</div>
			</form>

			{!isLoading && (
				<div className="card">
					<div className="card-body f-500">
						<div className="mb-2 h6">
							Прогресс прохождения курса - {lessonInfo?.courseProgress || 0}%
						</div>
						<div className="progress-bar mb-4 pos">
							<div
								className="bar"
								style={{ width: `${lessonInfo?.courseProgress || 0}%` }}></div>
						</div>

						<div className="mb-2">
							Статус квеста:{" "}
							<b className="font-500">
								{STATUS_LIST[lessonInfo?.quest?.status || 0]}
							</b>
						</div>

						<div className="mb-2">
							Статус ДЗ:{" "}
							<b className="font-500">
								{STATUS_LIST[lessonInfo?.homework?.status || 0]}
							</b>
							<div className="mt-2 form">
								{lessonInfo?.homework?.form?.map((question, qIndex) => {
									return (
										<div className="homework card mb-3" key={qIndex}>
											<div className="card-body">
												<p>
													<b className="font-500">
														{question?.question || ""} {question?.prompt || ""}
													</b>

													<span className="mt-1 d-block">
														{question?.answer || ""}
													</span>
												</p>

												<div className="files">
													{question.files.map(file => {
														const fileName = `${file.id}.${file.extension}`
														return (
															<div key={file.id} className="col-sm-6 col-md-4">
																<div
																	className="mt-2 btn btn-primary btn-xs btn-block jc-start"
																	onClick={() =>
																		downloadFile(file.path, fileName)
																	}>
																	<IconDocs
																		size={16}
																		color="#fff"
																		className="mr-2"
																	/>
																	<div>
																		<div className="h6">{fileName}</div>
																		<div className="h7">{file.size}</div>
																	</div>
																</div>
															</div>
														)
													})}
												</div>
											</div>
										</div>
									)
								})}
							</div>
						</div>

						<div className="mb-2">
							Статус теста:{" "}
							<b className="font-500">
								{STATUS_LIST[lessonInfo?.test?.status || 0]}
							</b>
							<div className="mt-2">
								{lessonInfo?.test?.testList?.map((test, index) => {
									return (
										<div
											key={test.answerCorrect}
											className={`mt-1 jc-start cursor-default btn btn-block btn-sm btn-${
												test.answerCorrect === test.selectAnswer
													? "success"
													: "warning"
											}`}>
											Вопрос {index + 1}: {test.question}
										</div>
									)
								})}
							</div>
						</div>
					</div>
				</div>
			)}

			<Modal
				show={roleList.length && showRoleModal}
				onClose={() => setShowRoleModal(false)}>
				<div className="h5 mb-3">Список ролей</div>

				<div className="form">
					{roleList.map(role => {
						return (
							<label
								key={role.role}
								className="d-flex ai-center mt-2 cursor-pointer">
								<input
									type="checkbox"
									className="input-checkbox mr-2"
									onChange={e => onChangeRole(e, role.role)}
									defaultChecked={user?.roles?.includes(role.role)}
								/>
								{role.name}
							</label>
						)
					})}
				</div>
			</Modal>
		</div>
	)
}
UserView.propTypes = {
	match: PropTypes.object
}

export default UserView
