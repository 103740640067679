import PropType from "prop-types"
import React, { useContext } from "react"
import { Link } from "react-router-dom"
import { AppContext } from "../../context/AppContext"
import { IconEdit, IconTrash, IconWatch } from "../../static/icons"

const HomeworkListTable = ({ data, onRemove }) => {
	const { state } = useContext(AppContext)

	const role = state.roleList

	return (
		<table>
			<thead>
				<tr>
					<th>Название</th>
					{(role.homework.update || role.homework.delete) && (
						<th className="actions" />
					)}
				</tr>
			</thead>

			<tbody>
				{data.length > 0 ? (
					data.map(item => {
						const { id } = item

						return (
							<tr key={id}>
								<td>
									<div className="font-500">Домашнее задание № {id}</div>
								</td>
								<td className="actions">
									<div className="row row-xs">
										<div className="col-auto">
											<Link
												to={`/homework/view/${id}`}
												className="btn btn-sm btn-primary">
												<IconWatch size={14} color="#fff" />
											</Link>
										</div>
										{role.homework.update && (
											<div className="col-auto">
												<Link
													to={{
														pathname: `/homework/edit/${id}`,
														state: {
															homework: item,
														},
													}}
													className="btn btn-sm btn-primary">
													<IconEdit size={14} color="#fff" />
												</Link>
											</div>
										)}
										{role.homework.delete && (
											<div className="col-auto">
												<button
													onClick={() => onRemove(id)}
													className="btn btn-sm btn-danger">
													<IconTrash size={14} color="#fff" />
												</button>
											</div>
										)}
									</div>
								</td>
							</tr>
						)
					})
				) : (
					<tr>
						<td colSpan="2" className="ta-center">
							Домашние задания отсутствуют
						</td>
					</tr>
				)}
			</tbody>
		</table>
	)
}
HomeworkListTable.propTypes = {
	data: PropType.array,
	name: PropType.string,
	onRemove: PropType.func,
	type: PropType.string,
}

export default HomeworkListTable
