/* eslint-disable react/prop-types */
import React from "react"
import { useDropzone } from "react-dropzone"
import { IconTrash } from "../../static/icons"
import documentImg from "../../static/imgs/icons/document.svg"
import { formatBytes } from "../../utils/formatBytes"

const Dropzone = ({
	acceptTypes,

	maxSize,
	multiple,
	files,
	onFileRemove,
	onFileUpload,
}) => {
	let acceptTypesText = ""

	if (acceptTypes === "image/svg+xml") {
		acceptTypesText = "SVG"
	} else if (acceptTypes.match(/image\//)) {
		acceptTypesText = "PNG, JPG, JPEG, GIF"
	} else if (acceptTypes.match(/video\//)) {
		acceptTypesText =
			"3gp, 3g2, 3gp2, 3gpp, 3gpp2, asf, asx, avi, bin, dat, drv, f4v, flv, gtp, h264, m4v, mkv, mod, moov, mov, mp4, mpeg, mpg, mts, rm, rmvb, srt, ts, vob, webm, wmv, yuv"
	} else if (acceptTypes.match(/audio\//)) {
		acceptTypesText = "mp3, mp4"
	}

	const { getRootProps, getInputProps } = useDropzone({
		multiple,
		accept: acceptTypes,
		maxSize,
		onDrop: acceptedFiles => {
			onFileUpload(acceptedFiles)
		},
	})

	return (
		<div className="dropzone-wrap">
			<div
				{...getRootProps({
					className: "dropzone d-flex flex-column ai-center jc-center",
				})}>
				<input {...getInputProps()} />

				<h4 className="title">Перетащите файл или нажмите</h4>
				<h6 className="accepted-files">
					{acceptTypes !== "" &&
						`Допустимые расширения: ${acceptTypesText.toUpperCase()}. `}
					Не более {maxSize / 1000000} мб на файл.
				</h6>
			</div>

			<div className="dropzone-previews">
				{files.map(f => (
					<div key={f.path} className="file pos">
						<div className="row row-sm ai-center">
							<div className="col-auto">
								<div className="file-img pos">
									{f.type.match(/image\//) ? (
										<img
											className="image-cover"
											alt={f.name}
											src={URL.createObjectURL(f)}
										/>
									) : (
										<img
											src={documentImg}
											alt={f.name}
											className="image-contain doc-icon pos-abs"
										/>
									)}
								</div>
							</div>

							<div className="col font-700">
								<div className="file-name">{f.name}</div>
								<div className="file-size">{formatBytes(f.size)}</div>
							</div>
						</div>

						<button
							className="file-remove btn btn-danger pos-abs-y"
							onClick={() => onFileRemove(f.name)}>
							<IconTrash size={14} color="#fff" className="pos-abs" />
						</button>
					</div>
				))}
			</div>
		</div>
	)
}

export default Dropzone
