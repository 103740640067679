import PropTypes from "prop-types"
import React, { memo } from "react"
import { IconSave } from "../../static/icons"

const Input = ({ label, register, error, onSave = null, ...inputProps }) => {
	let classArr = "input"
	if (error) {
		classArr = `${classArr} _error`
	}

	return (
		<label className={`label ${inputProps.disabled ? "cursor-default" : ""}`}>
			<span>{label}</span>

			<div className={`input-wrap pos ${onSave ? "_btn" : ""}`}>
				<input ref={register} className={classArr} {...inputProps} />

				{onSave && (
					<span onClick={onSave} className="cursor-pointer save-btn">
						<IconSave color="#fff" size={14} className="pos-abs"/>
					</span>
				)}
			</div>

			{error && <div className="error">{error}</div>}
		</label>
	)
}
Input.propTypes = {
	label: PropTypes.any,
	register: PropTypes.any,
	error: PropTypes.any,
	onSave: PropTypes.any
}

export default memo(Input)
