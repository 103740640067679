/* eslint-disable no-unused-vars */
import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import Tree from "react-d3-tree"
import { useForm } from "react-hook-form"
import Api from "../../../../../api"
import { Dropzone, Input, Modal } from "../../../../../components"
import { AppContext } from "../../../../../context/AppContext"
import { IconEdit, IconTrash } from "../../../../../static/icons"

const NodeLabel = ({
	nodeData,
	className,
	clickAnswer,
	clickQuestion,
	removeAnswer,
	removeQuestion,
	editQuestion,
	editAnswer,
	role
}) => {
	const { question, answer, type, itemId } = nodeData

	return (
		<div className={className}>
			<div>{type === 2 ? question : answer}</div>
			<div className="d-flex ai-end">
				{type === 2 ? (
					<>
						<div
							style={{ fontSize: 12 }}
							onClick={() => clickAnswer(nodeData)}
							className="btn btn-primary btn-sm">
							+
						</div>

						{role.quest["delete-answer"] && (
							<button
								onClick={() => removeQuestion(itemId)}
								className="btn btn-sm btn-danger ml-1">
								<IconTrash size={12} color="#fff"/>
							</button>
						)}

						{role.quest["update-answer"] && (
							<button
								onClick={() => editAnswer(nodeData)}
								className="btn btn-sm btn-info ml-1">
								<IconEdit size={12} color="#fff"/>
							</button>
						)}
					</>
				) : (
					<>
						<div
							style={{ fontSize: 12 }}
							onClick={() => clickQuestion(nodeData)}
							className="btn btn-sm btn-success">
							?
						</div>

						{role.quest["delete-question"] && (
							<button
								onClick={() => removeAnswer(itemId)}
								className="btn btn-sm btn-danger ml-1">
								<IconTrash size={12} color="#fff"/>
							</button>
						)}

						{role.quest["update-question"] && (
							<button
								onClick={() => editQuestion(nodeData)}
								className="btn btn-sm btn-info ml-1">
								<IconEdit size={12} color="#fff"/>
							</button>
						)}
					</>
				)}
			</div>
		</div>
	)
}
NodeLabel.propTypes = {
	nodeData: PropTypes.any,
	className: PropTypes.any,
	clickAnswer: PropTypes.any,
	clickQuestion: PropTypes.any,
	removeAnswer: PropTypes.any,
	removeQuestion: PropTypes.any,
	editQuestion: PropTypes.any,
	editAnswer: PropTypes.any,
	role: PropTypes.any
}

const ModalAnswer = ({ show, onClose, nodeData, onViewQuest }) => {
	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const [isSending, setIsSending] = useState(false)
	const [errorObj, setErrorObj] = useState({})
	const [edit, setEdit] = useState(false)

	const { register, handleSubmit } = useForm({
		defaultValues: {
			answer: nodeData?.answer || ""
		}
	})

	/**
	 * Проверка на то, создается или редактируется ответ
	 */
	const isEdit = () => {
		nodeData.answer !== null && setEdit(true)
	}

	/**
	 * Добавить ответ
	 */
	const onAnswerAdd = data => {
		if (!isSending) {
			setIsSending(true)

			Api.post(`/quest/create-answer/${nodeData.itemId}`, data, {
				headers: { Authorization }
			}).then(res => {
				const { data, error } = res.data

				if (!error) {
					onViewQuest()
					onClose()
					setErrorObj({})
				} else {
					console.error(data)
					alert("Ошибка")
				}
			})
		}

		setIsSending(false)
	}

	/**
	 * Редактировать ответ
	 */
	const onAnswerEdit = data => {
		if (!isSending) {
			setIsSending(true)

			Api.put(`/quest/update-answer/${nodeData.itemId}`, data, {
				headers: { Authorization }
			}).then(res => {
				const { data, error } = res.data

				if (!error) {
					onViewQuest()
					onClose()
					setErrorObj({})
				} else {
					console.error(data)
					alert("Ошибка")
				}
			})
		}

		setIsSending(false)
	}

	useEffect(() => {
		isEdit()
	}, [nodeData])

	return (
		<Modal show={show} onClose={onClose} modalWrapperStyles={{ maxWidth: 600 }}>
			<form
				className="form"
				onSubmit={handleSubmit(edit ? onAnswerEdit : onAnswerAdd)}>
				<div className="field row ai-end">
					<div className="col">
						<Input
							name="answer"
							label="Ответ"
							register={register}
							placeholder="Ответ"
						/>
					</div>

					<div className="col-12">
						<button type="submit" className="btn btn-primary btn-lg">
							{edit ? "Редактировать" : "Создать"}
						</button>
					</div>
				</div>
			</form>
		</Modal>
	)
}
ModalAnswer.propTypes = {
	show: PropTypes.any,
	onClose: PropTypes.any,
	nodeData: PropTypes.any,
	onViewQuest: PropTypes.any
}

const ModalQuestion = ({ show, onClose, nodeData, courseId, onViewQuest }) => {
	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const [isSending, setIsSending] = useState(false)
	const [errorObj, setErrorObj] = useState({})
	const [edit, setEdit] = useState(false)

	const [files, setFiles] = useState([])

	const { register, handleSubmit } = useForm({
		defaultValues: {
			question: nodeData?.question || ""
		}
	})

	/**
	 * Проверка на то, создается или редактируется вопрос
	 */
	const isEdit = () => {
		nodeData.question !== null && setEdit(true)
	}

	/**
	 * Добавить вопрос
	 */
	const onQuestionAdd = data => {
		if (!isSending) {
			setIsSending(true)

			const { question } = data

			const formData = new FormData()
			formData.append("video", files[0])
			formData.append("question", question)

			Api.post(
				`/quest/create/${courseId}?answer_id=${nodeData.itemId}`,
				formData,
				{
					headers: { Authorization }
				}
			).then(res => {
				const { data, error } = res.data

				if (!error) {
					onViewQuest()
					onClose()
					setFiles([])
				} else {
					window.scrollTo(0, 0)
					alert(data)
					setErrorObj(data)
				}
			})

			setIsSending(false)
		}
	}

	/**
	 * Редактировать вопрос
	 */
	const onQuestionEdit = data => {
		if (!isSending) {
			setIsSending(true)

			const { question } = data

			const formData = new FormData()
			formData.append("video", files[0])
			formData.append("question", question)

			Api.post(`/quest/update-question/${nodeData.itemId}`, formData, {
				headers: { Authorization }
			}).then(res => {
				const { data, error } = res.data

				if (!error) {
					onViewQuest()
					onClose()
					setErrorObj({})
				} else {
					console.error(data)
					alert("Ошибка")
				}
			})
		}

		setIsSending(false)
	}

	/**
	 * Действия с файлом
	 */
	const onFileRemove = fileName => {
		setFiles(files.filter(f => f.name !== fileName))
	}

	const onFileUpload = filesData => setFiles(filesData)

	useEffect(() => {
		isEdit()
	}, [nodeData])

	return (
		<Modal show={show} onClose={onClose} modalWrapperStyles={{ maxWidth: 600 }}>
			<form
				className="form"
				onSubmit={handleSubmit(edit ? onQuestionEdit : onQuestionAdd)}>
				<div className="field row ai-end">
					<div className="col">
						<Input
							name="question"
							label="Вопрос"
							register={register}
							placeholder="Вопрос"
						/>
					</div>

					<div className="field">
						<div className="card">
							<div className="card-body">
								<Dropzone
									multiple={false}
									maxSize={5000000}
									acceptTypes="video/*"
									files={files}
									onFileRemove={onFileRemove}
									onFileUpload={onFileUpload}
								/>
								{nodeData?.video && (
									<div style={{ marginTop: 20 }}>
										<iframe
											width="100%"
											height="250"
											src={`https://play.boomstream.com/${nodeData.video}`}
											frameBorder="0"
											scrolling="no"
											allowFullScreen=""></iframe>
									</div>
								)}
							</div>
						</div>
					</div>

					<div className="col-12">
						<button type="submit" className="btn btn-primary btn-lg">
							{edit ? "Редактировать" : "Создать"}
						</button>
					</div>
				</div>
			</form>
		</Modal>
	)
}
ModalQuestion.propTypes = {
	show: PropTypes.any,
	onClose: PropTypes.any,
	nodeData: PropTypes.any,
	courseId: PropTypes.any,
	onViewQuest: PropTypes.any
}

const QuestView = ({ match, location, history }) => {
	const { id } = match.params
	const { courseId } = location.state

	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const role = state.roleList

	const [questData, setQuestData] = useState({})

	const [answerData, setAnswerData] = useState({})
	const [questionData, setQuestionData] = useState({})

	const [answerAddModalVisible, setAnswerAddModalVisible] = useState(false)
	const [questionAddModalVisible, setQuestionAddModalVisible] = useState(false)

	/**
	 * Просмотреть квест
	 */
	const onViewQuest = () => {
		Api.get(`/quest/${id}`, { headers: { Authorization } }).then(res => {
			const { error, data } = res.data

			if (!error) {
				setQuestData(data)
			} else {
				console.error(data)
				alert("Ошибка")
			}
		})
	}

	/**
	 * Удалить ответ
	 */
	const removeAnswer = itemId => {
		if (window.confirm("Вы уверены, что хотите удалить ответ?")) {
			Api.delete(`/quest/delete-answer/${itemId}`, {
				headers: { Authorization }
			}).then(res => {
				const { error, data } = res.data

				if (!error) {
					onViewQuest()
				} else {
					console.error(data)
					alert("Ошибка")
				}
			})
		}
	}

	/**
	 * Удалить вопрос
	 */
	const removeQuestion = itemId => {
		if (window.confirm("Вы уверены, что хотите удалить вопрос?")) {
			Api.delete(`/quest/delete-question/${itemId}`, {
				headers: { Authorization }
			}).then(res => {
				const { error, data } = res.data

				if (!error) {
					onViewQuest()
				} else {
					console.error(data)
					alert("Ошибка")
				}
			})
		}
	}

	/**
	 * Опубликовать квест
	 */
	const onPublishQuest = () => {
		Api.get(`/quest/publish/${id}`, { headers: { Authorization } }).then(
			res => {
				const { error, data } = res.data

				if (!error) {
					alert(data)
				} else {
					alert(data)
				}
			}
		)
	}

	/**
	 * Открыть модальное окно ответа и отправить туда необходимые данные
	 */
	const openAnswerModal = data => {
		setAnswerAddModalVisible(true)
		setAnswerData(data)
	}

	/**
	 * Открыть модальное окно вопроса и отправить туда необходимые данные
	 */
	const openQuestionModal = data => {
		setQuestionAddModalVisible(true)
		setQuestionData(data)
	}

	/**
	 * Закрыть модальное окно ответа
	 */
	const answerAddModalToggleShow = () => {
		setAnswerAddModalVisible(s => !s)
	}

	/**
	 * Закрыть модальное окно вопроса
	 */
	const questionAddModalToggleShow = () => {
		setQuestionAddModalVisible(s => !s)
	}

	useEffect(() => {
		onViewQuest()
	}, [])

	return (
		<>
			<div className="page-header">
				<div className="row ai-center jc-between">
					<div className="col-md-auto">
						<h6 className="title">Просмотр квеста</h6>
					</div>

					<div className="col-md-auto">
						<div className="row row-sm">
							<div className="col-auto">
								<div
									onClick={() => history.goBack()}
									className="btn btn-primary">
									Назад
								</div>
							</div>

							<div className="col-auto">
								<button onClick={onPublishQuest} className="btn btn-primary">
									Опубликовать квест
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div id="treeWrapper" style={{ width: "100%", height: 700 }}>
						<Tree
							data={questData}
							allowForeignObjects
							pathFunc="step"
							nodeLabelComponent={{
								render: (
									<NodeLabel
										className="myLabel"
										role={role}
										nodeData={questData}
										clickAnswer={openAnswerModal}
										clickQuestion={openQuestionModal}
										removeAnswer={removeAnswer}
										removeQuestion={removeQuestion}
										editAnswer={openQuestionModal}
										editQuestion={openAnswerModal}
									/>
								),
								foreignObjectWrapper: {
									y: 15,
									x: -20,
									height: 120
								}
							}}
						/>
					</div>
				</div>
			</div>

			<div>
				{answerAddModalVisible && (
					<ModalAnswer
						show
						onClose={answerAddModalToggleShow}
						nodeData={answerData}
						onViewQuest={onViewQuest}
					/>
				)}

				{questionAddModalVisible && (
					<ModalQuestion
						show
						onClose={questionAddModalToggleShow}
						nodeData={questionData}
						courseId={courseId}
						onViewQuest={onViewQuest}
					/>
				)}
			</div>
		</>
	)
}
QuestView.propTypes = {
	match: PropTypes.object,
	location: PropTypes.object,
	history: PropTypes.object
}

export default QuestView
