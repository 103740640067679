/* eslint-disable react/prop-types */
import * as React from "react"

const SvgComponent = ({ size, color, ...props }) => (
	<svg height={size} viewBox="0 0 512 512" width={size} {...props}>
		<path
			fill={color}
			d="M512 346.5c0-63.535-36.45-120.238-91.04-147.82-1.694 121.82-100.46 220.586-222.28 222.28C226.262 475.55 282.965 512 346.5 512c29.79 0 58.758-7.934 84.21-23.008l80.567 22.285-22.285-80.566C504.066 405.258 512 376.289 512 346.5zm0 0"
		/>
		<path
			fill={color}
			d="M391 195.5C391 87.7 303.3 0 195.5 0S0 87.7 0 195.5c0 35.133 9.352 69.34 27.11 99.371L.718 390.277l95.41-26.386C126.16 381.648 160.367 391 195.5 391 303.3 391 391 303.3 391 195.5zM165.5 150h-30c0-33.086 26.914-60 60-60s60 26.914 60 60c0 16.793-7.11 32.934-19.512 44.277L210.5 217.605V241h-30v-36.605l35.234-32.25c6.297-5.762 9.766-13.625 9.766-22.145 0-16.543-13.457-30-30-30s-30 13.457-30 30zm15 121h30v30h-30zm0 0"
		/>
	</svg>
)

export default SvgComponent
