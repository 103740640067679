import PropType from "prop-types"
import React, { useContext, useState } from "react"
import { useForm } from "react-hook-form"
import Api from "../../../../../../api"
import { Dropzone, Input, Select, Textarea } from "../../../../../../components"
import { Modal, Spinner } from "../../../../../../components/index"
import { AppContext } from "../../../../../../context/AppContext"
import { LESSON_VERSION_TYPE } from "../../../../../../global/data"

const LessonVersionCreate = ({ location, history }) => {
	const { lessonInfo } = location.state

	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const { handleSubmit, register, watch } = useForm()
	const [isSending, setIsSending] = useState(false)
	const [errorObj, setErrorObj] = useState({})
	const [mediaFiles, setMediaFiles] = useState([])
	const [bannerFiles, setBannerFiles] = useState([])
	const [downloadPercent, setDownloadPercent] = useState(0)

	const typeValue = Number(watch("type"))

	const onMediaFileRemove = fileName =>
		setMediaFiles(mediaFiles.filter(f => f.name !== fileName))
	const onMediaFileUpload = files => setMediaFiles(files)

	const onBannerFileRemove = fileName =>
		setBannerFiles(bannerFiles.filter(f => f.name !== fileName))
	const onBannerFileUpload = files => setBannerFiles(files)

	/**
	 * Создание версии урока
	 **/
	const onLessonVersionAdd = data => {
		if (!isSending) {
			setIsSending(true)

			const formData = new FormData()
			formData.append("video", mediaFiles[0])
			formData.append("name", data.name)
			formData.append("type", data.type)
			formData.append("description", data.description)

			Api.post(`/lesson-version/${lessonInfo.id}`, formData, {
				headers: { Authorization },
				onUploadProgress: progressEvent => {
					const percentCompleted = Math.round(
						(progressEvent.loaded * 100) / progressEvent.total
					)
					setDownloadPercent(percentCompleted)
				},
			})

				.then(res => {
					const { data, error } = res.data

					if (!error) {
						setErrorObj({})
						alert(data)
						history.goBack()
					} else {
						window.scrollTo(0, 0)
						setErrorObj(data)
						console.error(data)
						alert("Ошибка")
					}
				})
				.finally(() => {
					setIsSending(false)
					setDownloadPercent(0)
				})
		}
	}

	return (
		<div className="page-les-ver-create">
			<div className="page-header">
				<h6 className="title">
					Создать версию к уроку <b>«{lessonInfo.name}»</b>
				</h6>
			</div>

			<form className="form" onSubmit={handleSubmit(onLessonVersionAdd)}>
				<div className="row">
					<div className="col-12">
						<div className="field">
							<Input
								required
								label="Название"
								placeholder="Название"
								name="name"
								register={register}
								error={errorObj.name}
							/>
						</div>
					</div>

					<div className="col-12">
						<div className="field">
							<Select
								required
								label="Тип урока"
								name="type"
								register={register}
								items={LESSON_VERSION_TYPE}
							/>
						</div>
					</div>

					<div className="col-12">
						<div className="field">
							<Textarea
								required
								label="Описание"
								placeholder="Описание"
								name="description"
								register={register}
								rows="8"
								error={errorObj.description}
							/>
						</div>
					</div>

					{typeValue !== 2 && (
						<>
							<div className="col-12">
								<div className="card field">
									<div className="card-body">
										<div className="h6 mb-2">Загрузка медиафайла</div>
										<Dropzone
											multiple={false}
											maxSize={2000000000}
											acceptTypes="video/*, audio/mp4, audio/mpeg"
											files={mediaFiles}
											onFileRemove={onMediaFileRemove}
											onFileUpload={onMediaFileUpload}
										/>
									</div>
								</div>
							</div>

							{typeValue === 1 && (
								<div className="col-12">
									<div className="card field">
										<div className="card-body">
											<div className="h6 mb-2">Загрузка баннера</div>
											<Dropzone
												multiple={false}
												maxSize={5000000}
												acceptTypes="image/*"
												files={bannerFiles}
												onFileRemove={onBannerFileRemove}
												onFileUpload={onBannerFileUpload}
											/>
										</div>
									</div>
								</div>
							)}
						</>
					)}
				</div>

				<button type="submit" className="btn btn-primary btn-lg">
					Создать
				</button>
			</form>

			<Modal
				show={isSending}
				showClose={false}
				modalWrapperStyles={{ maxWidth: 300 }}>
				<div className="ta-center">
					<Spinner size={100} />
					<div className="font-700 mt-2">{parseInt(downloadPercent)}%</div>
				</div>
			</Modal>
		</div>
	)
}

LessonVersionCreate.propTypes = {
	location: PropType.object,
	history: PropType.object,
}

export default LessonVersionCreate
