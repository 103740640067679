export const LESSON_VERSION_TYPE = [
	{ id: 1, name: "Видео" },
	{ id: 3, name: "Аудио" },
	{ id: 2, name: "Текст" },
]

export const PDF_ORIENTATION = [
	{ id: "portrait", name: "Книжная" },
	{ id: "landscape", name: "Альбомная" },
]
