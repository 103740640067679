import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import Api from "../../../../../api"
import { Input, Modal, Spinner, Textarea } from "../../../../../components"
import { AppContext } from "../../../../../context/AppContext"
import { IconDocs } from "../../../../../static/icons/index"
import "./HomeworkView.scss"

const HomeworkView = ({ history, match }) => {
	const { id } = match.params

	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const [homework, setHomework] = useState({})
	const [documents, setDocuments] = useState([])
	const [doc, setDoc] = useState(null)
	const [isModalVisible, setIsModalVisible] = useState(false)

	const getHomework = () => {
		Api.get(`/homework/${id}`, { headers: { Authorization } }).then(res => {
			const { data, error } = res.data
			if (!error) {
				setHomework(data)
				setDocuments(data.documents)
			} else {
				console.error(data)
				alert("Ошибка")
			}
		})
	}

	const onDocShow = docId => {
		setIsModalVisible(true)

		Api.get(`/document/${docId}`, { headers: { Authorization } }).then(res => {
			const { data, error } = res.data

			if (!error) {
				setDoc(data)
			} else {
				console.error(data)
				alert("Ошибка")
			}
		})
	}

	const onCloseModal = () => {
		setIsModalVisible(false)
		setDoc(null)
	}

	const downloadBase64File = (base64Data, fileName) => {
		const downloadLink = document.createElement("a")
		downloadLink.href = base64Data
		downloadLink.download = fileName
		downloadLink.click()
	}

	const onDocumentDownload = (docUrl, docFileName) => {
		Api.get(docUrl, { headers: { Authorization } }).then(res => {
			const { error, data } = res.data

			if (!error) {
				downloadBase64File(data, docFileName)
			} else {
				console.error(data)
				alert("Ошибка")
			}
		})
	}

	useEffect(() => {
		getHomework()
	}, [])

	return (
		<div className="page-homework-view">
			<div className="page-header">
				<div className="row ai-center jc-between">
					<div className="col-md-auto">
						<h6 className="title">Просмотр задания</h6>
					</div>

					<div className="col-md-auto">
						<button
							onClick={() => history.goBack()}
							className="btn btn-primary btn-lg">
							Назад
						</button>
					</div>
				</div>
			</div>

			{Object.keys(homework).length > 0 && (
				<div className="row">
					<div className="col-xl-9">
						<div className="form">
							<div className="field">
								<Input label="Название" value={homework.name} disabled/>
							</div>
							<div className="field">
								<Textarea
									label="Описание"
									value={homework.description}
									disabled
									rows={10}
								/>
							</div>

							<div className="field">
								{homework.questions.map((question, qIndex) => {
									return (
										<div className="homework card mb-3" key={qIndex}>
											<div className="card-body">
												<h5 className="mb-3">Вопрос {qIndex}</h5>

												<div className="row">
													<div className="col-xxl-6">
														<Input
															label="Вопрос"
															placeholder="Вопрос"
															defaultValue={question?.question || ""}
															disabled
														/>
													</div>

													<div className="col-xxl-6">
														<Input
															label="Подсказка"
															placeholder="Подсказка"
															defaultValue={question?.prompt || ""}
															disabled
														/>
													</div>

													<div className="col-12 mt-3">
														<label className="cursor-default d-flex ai-center">
															<input
																type="checkbox"
																className="input-checkbox mr-2"
																defaultChecked={question.type === 2}
																disabled
															/>
															Прикрепить файл
														</label>
													</div>
												</div>
											</div>
										</div>
									)
								})}
							</div>
						</div>
					</div>

					<div className="col-xl-3">
						<div className="h5 mb-3">Прикрепленные файлы</div>

						{documents.map(doc => {
							const { id, fileName, url, extension, size } = doc

							return (
								<div key={id} className="doc-item card cursor-pointer mb-2">
									<div className="card-body p-2">
										<div className="row row-sm ai-center">
											<div className="col-auto">
												<div
													className="icon-wrap avatar-rounded avatar-sm pos"
													onClick={() => onDocShow(doc.id)}>
													<IconDocs
														size={20}
														color="#000"
														className="pos-abs"
													/>
												</div>
											</div>

											<div className="col">
												<div onClick={() => onDocShow(doc.id)}>
													<p className="font-500">
														{fileName}.{extension}
													</p>
													<div className="h7 mt-1">{size}</div>
												</div>

												<button
													className="btn btn-primary btn-xs mt-2"
													onClick={() =>
														onDocumentDownload(url, `${fileName}.${extension}`)
													}>
													Скачать
												</button>
											</div>
										</div>
									</div>
								</div>
							)
						})}
					</div>
				</div>
			)}

			<Modal
				show={isModalVisible}
				onClose={onCloseModal}
				modalWrapperStyles={{ padding: 0, maxWidth: 1200 }}>
				{doc ? <img src={doc} alt=""/> : <Spinner/>}
			</Modal>
		</div>
	)
}
HomeworkView.propTypes = {
	history: PropTypes.object,
	match: PropTypes.object
}

export default HomeworkView
