import PropType from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import Api from "../../../../../../api"
import { Input, Spinner, Textarea } from "../../../../../../components"
import { Select } from "../../../../../../components/index"
import { AppContext } from "../../../../../../context/AppContext"
import { LESSON_VERSION_TYPE } from "../../../../../../global/data"

const LessonVersionView = ({ history, match }) => {
	const { id: lessonId } = match.params

	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const [lesson, setLesson] = useState(null)

	const getLessonData = () => {
		Api.get(`/lesson-version/${lessonId}`, { headers: { Authorization } }).then(
			res => {
				const { error, data } = res.data

				if (!error) {
					setLesson(data)
				} else {
					alert("Ошибка")
					console.error(res.data)
				}
			}
		)
	}

	useEffect(() => {
		getLessonData()
	}, [])

	if (!lesson) {
		return (
			<div className="d-flex ai-center jc-center flex-fill">
				<Spinner/>
			</div>
		)
	}

	return (
		<div className="page-lesson-view">
			<div className="page-header">
				<div className="row ai-center jc-between">
					<div className="col-md-auto">
						<h6 className="title">Просмотр версии урока</h6>
					</div>

					<div className="col-md-auto">
						<div onClick={() => history.goBack()} className="btn btn-primary">
							Назад
						</div>
					</div>
				</div>
			</div>

			<form className="form">
				<div className="row">
					<div className="col-md-5 col-xl-4 col-xxl-3">
						<div className="card field">
							<div className="card-body">
								{+lesson.type === 1 && (
									<>
										<iframe
											width="100%"
											height="250"
											src={`https://play.boomstream.com/${lesson.video}`}
											frameBorder="0"
											scrolling="no"
											allow="autoplay; fullscreen"
											name="target"
											title={lesson.name || ""}
										/>

										{lesson.banner && (
											<img
												src={lesson.banner}
												alt=""
												className="mt-3"
												style={{ width: "100%" }}
											/>
										)}
									</>
								)}

								{lesson.audio && +lesson.type === 3 && (
									<audio
										src={lesson.audio}
										controls
										style={{ width: "100%" }}
									/>
								)}
							</div>
						</div>

						<label className="d-flex ai-center cursor-pointer">
							<input
								type="checkbox"
								className="input-checkbox mr-2"
								disabled
								defaultChecked={lesson.public === 1}
							/>
							Опубликован
						</label>
					</div>

					<div className="col-md-7 col-xl-8 col-xxl-9">
						<div className="row">
							<div className="col-md-6">
								<div className="field">
									<Input
										defaultValue={lesson.name || ""}
										label="Родительский урок"
										disabled
									/>
								</div>
							</div>

							<div className="col-md-6">
								<div className="field">
									<Input defaultValue={lesson.name} label="Название" disabled/>
								</div>
							</div>

							<div className="col-12">
								<div className="field">
									<Select
										disabled
										required
										label="Тип урока"
										name="type"
										defaultValue={lesson.type}
										items={LESSON_VERSION_TYPE}
									/>
								</div>
							</div>

							<div className="col-12">
								<div className="field">
									<Textarea
										rows={10}
										disabled
										defaultValue={lesson.description}
										label="Описание"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	)
}

LessonVersionView.propTypes = {
	history: PropType.object,
	match: PropType.object
}

export default LessonVersionView
