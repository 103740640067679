/* eslint-disable */
import React from "react"
import spinnerBlueImg from "./spinner-blue.svg"
import spinnerWhiteImg from "./spinner-white.svg"

const Spinner = ({ size = 120, color = "blue", styles, ...props }) => (
	<img
		alt="spinner animation"
		src={color === "blue" ? spinnerBlueImg : spinnerWhiteImg}
		style={{ width: size, height: size, ...styles }}
		{...props}
	/>
)

export default Spinner
