import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import Api from "../../../../../api"
import { Dropzone, Input, Modal, Textarea } from "../../../../../components"
import { Spinner } from "../../../../../components/index"
import { AppContext } from "../../../../../context/AppContext"
import { IconClose, IconDocs } from "../../../../../static/icons/index"
import "./HomeWorkEdit.scss"

const AddQuestionModal = ({ onAddQuestion, onCloseModal }) => {
	const [question, setQuestion] = useState("")
	const [prompt, setPrompt] = useState("")
	const [type, setType] = useState(1)

	const onSubmit = e => {
		e.preventDefault()

		onAddQuestion({ type, question, prompt })
	}

	const onChangeCheckbox = e => {
		e.preventDefault()

		setType(type === 1 ? 2 : 1)
	}

	return (
		<Modal show onClose={onCloseModal} modalWrapperStyles={{ maxWidth: 800 }}>
			<form className="form" onSubmit={onSubmit}>
				<div className="field">
					<Input
						label="Вопрос"
						placeholder="Вопрос"
						value={question}
						onChange={e => setQuestion(e.target.value)}
					/>
				</div>
				<div className="field">
					<Input
						label="Подсказка"
						placeholder="Подсказка"
						value={prompt}
						onChange={e => setPrompt(e.target.value)}
					/>
				</div>
				<div className="field">
					<label
						onClick={onChangeCheckbox}
						className="d-flex ai-center cursor-pointer">
						<input
							type="checkbox"
							className="input-checkbox mr-2"
							checked={type === 2}
							onChange={() => {}}
						/>
						Прикрепить файл
					</label>
				</div>

				<button type="submit" className="mt-0 btn btn-primary btn-block">
					Добавить вопрос
				</button>
			</form>
		</Modal>
	)
}
AddQuestionModal.propTypes = {
	onAddQuestion: PropTypes.any,
	onCloseModal: PropTypes.any,
}

const HomeWorkEdit = ({ location, history, match }) => {
	const { homework } = location.state
	const { id: homeworkId } = match.params

	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const role = state.roleList

	const [isSending, setIsSending] = useState(false)
	const [errorObj, setErrorObj] = useState({})
	const [files, setFiles] = useState([])
	const [documents, setDocuments] = useState([])
	const [questionList, setQuestionList] = useState([])
	const [addQuestionModal, setAddQuestionModal] = useState(false)

	const { register, handleSubmit } = useForm({
		defaultValues: {
			name: homework.name || "",
			description: homework.description || "",
		},
	})

	const getHomework = () => {
		Api.get(`/homework/${homeworkId}`, { headers: { Authorization } }).then(
			res => {
				const { data, error } = res.data
				if (!error) {
					const questions = data.questions.map(question => {
						return { ...question, isSave: true }
					})

					setQuestionList(questions)
					setDocuments(data.documents)
				} else {
					console.error(data)
					alert("Ошибка")
				}
			}
		)
	}

	const closeAddQuestionModal = () => {
		setAddQuestionModal(false)
	}

	const openAddQuestionModal = () => {
		setAddQuestionModal(true)
	}

	const onAddQuestion = ({ type, question, prompt }) => {
		Api.post(
			`/homework/create-question-homework/${homeworkId}`,
			{
				type,
				question,
				prompt,
			},
			{ headers: { Authorization } }
		).then(res => {
			const { data, error } = res.data

			if (!error) {
				closeAddQuestionModal()
				setQuestionList([
					...questionList,
					{ id: data, question, type, prompt, isSave: true },
				])
			} else {
				console.error(data)
				alert("Ошибка")
			}
		})
	}

	const onEditQuestion = (e, questionId) => {
		e.preventDefault()

		const data = questionList.filter(question => question.id === questionId)
		const { type, question, prompt } = data[0]

		Api.put(
			`/homework/update-question-homework/${questionId}`,
			{
				type,
				question,
				prompt,
			},
			{ headers: { Authorization } }
		).then(res => {
			const { data, error } = res

			if (!error) {
				const data = questionList.map(question => {
					if (question.id === questionId) {
						return { ...question, isSave: true }
					}

					return question
				})

				setQuestionList(data)
			} else {
				console.error(data)
				alert("Ошибка")
			}
		})
	}

	const onRemoveQuestion = (e, questionId) => {
		e.preventDefault()

		if (window.confirm("Удалить вопрос?")) {
			Api.delete(`/homework/delete-question-homework/${questionId}`, {
				headers: { Authorization },
			}).then(res => {
				const { data, error } = res

				if (!error) {
					const data = questionList.filter(q => q.id !== questionId)
					setQuestionList(data)
				} else {
					console.error(data)
					alert("Ошибка")
				}
			})
		}
	}

	const onCheckboxChange = (e, qIndex) => {
		e.preventDefault()

		const data = questionList.map((question, index) => {
			if (index === qIndex) {
				if (question.type === 1) {
					return { ...question, type: 2, isSave: false }
				} else {
					return { ...question, type: 1, isSave: false }
				}
			}

			return question
		})

		setQuestionList(data)
	}

	const changeQuestionValue = (value, qIndex) => {
		const data = questionList.map((q, index) => {
			if (qIndex === index) {
				return { ...q, question: value, isSave: false }
			}

			return q
		})

		setQuestionList(data)
	}

	const changePromptValue = (value, qIndex) => {
		const data = questionList.map((q, index) => {
			if (qIndex === index) {
				return { ...q, prompt: value, isSave: false }
			}

			return q
		})

		setQuestionList(data)
	}

	/**
	 * редактирование домашнего задания
	 **/
	const onHomeworkEdit = data => {
		if (!isSending) {
			setIsSending(true)

			const { name, description } = data

			const formData = new FormData()
			formData.append("name", name)
			formData.append("description", description)

			if (files.length) {
				files.forEach(file => {
					formData.append("document[]", file)
				})
			}

			questionList.forEach((item, index) => {
				formData.append(`questions[${index}][question]`, item.question)
				formData.append(`questions[${index}][type]`, item.type)
				formData.append(`questions[${index}][prompt]`, item.prompt)
			})

			Api.post(`/homework/update/${homework.id}`, formData, {
				headers: { Authorization },
			})
				.then(res => {
					const { data, error } = res.data

					if (!error) {
						alert(data)
						setFiles([])
						history.goBack()
					} else {
						window.scrollTo(0, 0)
						setErrorObj(data)
					}
				})
				.finally(() => setIsSending(false))
		}
	}

	const onFileRemove = file => setFiles(files.filter(f => f.name !== file))
	const onFileUpload = fileList => setFiles(fileList)

	const downloadBase64File = (base64Data, fileName) => {
		const downloadLink = document.createElement("a")
		downloadLink.href = base64Data
		downloadLink.download = fileName
		downloadLink.click()
	}

	const onDocumentDownload = (docUrl, docFileName) => {
		Api.get(docUrl, { headers: { Authorization } }).then(res => {
			const { error, data } = res.data

			if (!error) {
				downloadBase64File(data, docFileName)
			} else {
				console.error(data)
				alert("Ошибка")
			}
		})
	}

	const onRemoveDoc = id => {
		if (window.confirm("Удалить документ?")) {
			Api.delete(`/homework/document/${id}`, {
				headers: { Authorization },
			}).then(res => {
				const { error, data } = res.data

				if (!error) {
					setDocuments(documents.filter(doc => doc.id !== id))
				} else {
					console.error(data)
					alert("Ошибка")
				}
			})
		}
	}

	useEffect(() => {
		getHomework()
	}, [])

	return (
		<div className="page-homework-edit">
			<div className="page-header">
				<div className="row ai-center jc-between">
					<div className="col-md-auto">
						<h6 className="title">Редактировать задание</h6>
					</div>

					<div className="col-md-auto">
						<button
							onClick={() => history.goBack()}
							className="btn btn-primary btn-lg">
							Назад
						</button>
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-xl-9">
					<form className="form" onSubmit={handleSubmit(onHomeworkEdit)}>
						<div className="row">
							<div className="col-12">
								<div className="field">
									<Input
										required
										label="Название"
										register={register}
										name="name"
									/>
									{errorObj.name && (
										<div className="error">{errorObj.name}</div>
									)}
								</div>
							</div>

							<div className="col-12">
								<div className="field">
									<Textarea
										required
										label="Описание"
										name="description"
										rows="8"
										register={register}
									/>
									{errorObj.description && (
										<div className="error">{errorObj.description}</div>
									)}
								</div>
							</div>

							<div className="col-12 field">
								{questionList.map((question, qIndex) => {
									return (
										<div className="homework card mb-3" key={qIndex}>
											<div className="card-body">
												<div className="row mb-3 ai-center">
													<h5 className="col-sm">Вопрос {qIndex}</h5>

													<div className="col-sm-auto">
														<div className="row row-sm">
															{!question.isSave && (
																<div className="col-auto">
																	<button
																		className="btn btn-primary btn-xs"
																		onClick={e =>
																			onEditQuestion(e, question.id)
																		}>
																		Сохранить
																	</button>
																</div>
															)}

															<div className="col-auto">
																<button
																	onClick={e =>
																		onRemoveQuestion(e, question.id)
																	}
																	className="btn btn-danger btn-xs">
																	Удалить
																</button>
															</div>
														</div>
													</div>
												</div>

												<div className="row">
													<div className="col-xxl-6">
														<Input
															label="Вопрос"
															placeholder="Вопрос"
															value={question?.question || ""}
															onChange={e =>
																changeQuestionValue(e.target.value, qIndex)
															}
														/>
													</div>
													<div className="col-xxl-6">
														<Input
															label="Подсказка"
															placeholder="Подсказка"
															value={question?.prompt || ""}
															onChange={e =>
																changePromptValue(e.target.value, qIndex)
															}
														/>
													</div>

													<div className="col-12 mt-3">
														<label
															className="cursor-pointer d-flex ai-center"
															onClick={e => onCheckboxChange(e, qIndex)}>
															<input
																type="checkbox"
																className="input-checkbox mr-2"
																checked={question.type === 2}
																onChange={() => {}}
															/>
															Прикрепить файл
														</label>
													</div>
												</div>
											</div>
										</div>
									)
								})}

								<div
									onClick={openAddQuestionModal}
									className="btn btn-primary btn-block">
									Добавить вопрос
								</div>
							</div>

							<div className="col-12">
								<div className="field">
									<Dropzone
										maxSize={10000000}
										acceptTypes=""
										files={files}
										onFileRemove={onFileRemove}
										onFileUpload={onFileUpload}
									/>
								</div>
							</div>
						</div>

						<button type="submit" className="btn btn-primary btn-lg">
							Редактировать
						</button>
					</form>
				</div>

				<div className="col-xl-3">
					<div className="h5 mb-3">Прикрепленные файлы</div>

					{documents.map(doc => {
						const { id, url, fileName, extension, size } = doc

						return (
							<div key={id} className="doc-item card cursor-pointer mb-2">
								<div className="card-body p-2">
									<div className="row row-sm ai-center">
										<div className="col-auto">
											<div
												className="icon-wrap avatar-rounded avatar-sm pos"
												onClick={() =>
													onDocumentDownload(url, `${fileName}.${extension}`)
												}>
												<IconDocs size={20} color="#000" className="pos-abs" />
											</div>
										</div>

										<div className="col">
											<div
												onClick={() =>
													onDocumentDownload(url, `${fileName}.${extension}`)
												}>
												<p className="font-500">
													{fileName}.{extension}
												</p>
												<div className="h7 mt-1">{size}</div>
											</div>
										</div>

										{role.homework["delete-document"] && (
											<div className="col-auto">
												<div
													onClick={() => onRemoveDoc(id)}
													className="icon-wrap _remove pos avatar-rounded avatar-xs">
													<IconClose
														size={14}
														color="#fff"
														className="pos-abs"
													/>
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
						)
					})}
				</div>
			</div>

			{addQuestionModal && (
				<AddQuestionModal
					onAddQuestion={onAddQuestion}
					onCloseModal={closeAddQuestionModal}
				/>
			)}

			<Modal
				show={isSending}
				showClose={false}
				modalWrapperStyles={{ maxWidth: 300 }}>
				<div className="ta-center">
					<Spinner size={100} />
				</div>
			</Modal>
		</div>
	)
}
HomeWorkEdit.propTypes = {
	location: PropTypes.any,
	history: PropTypes.any,
	match: PropTypes.any,
}

export default HomeWorkEdit
