import PropTypes from "prop-types"
import React, { useContext, useState } from "react"
import { useForm } from "react-hook-form"
import Api from "../../../../api"
import { Input } from "../../../../components"
import { AppContext } from "../../../../context/AppContext"

const UserInfo = ({ location }) => {
	const { user } = location.state
	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const [isSending, setIsSending] = useState(false)
	const [errorObj, setErrorObj] = useState({})

	const { register, handleSubmit } = useForm({
		defaultValues: {
			id: user?.id || "",
			email: user?.email || "",
			status: user?.status || "",
			first_name: user?.profile.first_name || "",
			last_name: user?.profile.last_name || "",
			patronymic: user?.profile.patronymic || "",
			avatar: user?.profile.avatar || "",
			birtdate: user?.profile.birthDate
				? user?.profile.birthDate.replace(/(\d+).(\d+).(\d+)/, "$3-$2-$1")
				: ""
		}
	})

	const onUserEdit = data => {
		if (!isSending) {
			setIsSending(true)

			Api.post(`/profile/${user.id}`, data, {
				headers: { Authorization }
			}).then(res => {
				const { data, error } = res.data

				if (!error) {
					alert("Пользователь успешно отредактирован")
				} else {
					window.scrollTo(0, 0)
					setErrorObj(data)
					console.error(data)
					alert("Ошибка")
				}
				setIsSending(false)
			})
		}
	}

	return (
		<>
			<div className="page-header">
				<h6 className="title">Информация о пользователе</h6>
			</div>

			<form className="form" onSubmit={handleSubmit(onUserEdit)}>
				<div className="row">
					<div className="col-xxl-12">
						<div className="row">
							<div className="col-xs-4">
								<div className="field">
									<Input
										required
										label="Имя"
										placeholder="Имя"
										name="first_name"
										register={register}
										error={errorObj.first_name}
									/>
								</div>
							</div>

							<div className="col-xs-4">
								<div className="field">
									<Input
										required
										label="Фамилия"
										placeholder="Фамилия"
										name="last_name"
										register={register}
										error={errorObj.last_name}
									/>
								</div>
							</div>

							<div className="col-xs-4">
								<div className="field">
									<Input
										required
										label="Отчество"
										placeholder="Отчество"
										name="patronymic"
										register={register}
										error={errorObj.patronymic}
									/>
								</div>
							</div>

							<div className="col-xs-4">
								<div className="field">
									<Input
										required
										label="Дата рождения"
										placeholder="Дата рождения"
										name="birtdate"
										type="date"
										register={register}
										error={errorObj.birtdate}
									/>
								</div>
							</div>

							<div className="col-xs-4">
								<div className="field">
									<Input
										required
										label="Email"
										placeholder="Email"
										name="email"
										register={register}
										error={errorObj.email}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<button className="btn btn-primary btn-lg">Отредактировать</button>
			</form>
		</>
	)
}
UserInfo.propTypes = {
	location: PropTypes.any
}

export default UserInfo
