import React, { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Api from "../../../../../api"
import { Select, Spinner } from "../../../../../components"
import { Pagination } from "../../../../../components/index"
import { AppContext } from "../../../../../context/AppContext"
import { IconEdit, IconTrash, IconWatch } from "../../../../../static/icons"
import { addUrlParam, clearUrlParams, getUrlParams } from "../../../../../utils/urlManipulation"

const ModuleList = () => {
	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const role = state.roleList.module

	const [page, setPage] = useState(1)
	const [lastPage, setLastPage] = useState(null)

	const URL_PARAMS = getUrlParams()
	const directionParamId = URL_PARAMS["direction"]
	const courseParamId = URL_PARAMS["course"]

	const [isLoading, setIsLoading] = useState(true)
	const [sortAttrName, setSortAttrName] = useState(null)
	const [eduList, setEduList] = useState({
		directionList: [],
		courseList: [],
		moduleList: []
	})
	const [eduIds, setEduIds] = useState({
		directionId: null,
		courseId: null
	})

	console.log("modules", eduList.moduleList)

	const changeDirectionIdHandle = e => {
		const value = +e.target.value

		clearUrlParams()
		addUrlParam("direction", value)
		setEduIds({ ...eduIds, directionId: value })
	}
	const changeCourseIdHandle = e => {
		const value = +e.target.value

		addUrlParam("course", value)
		setEduIds({ ...eduIds, courseId: value })
	}

	const getDirectionList = () => {
		setIsLoading(true)

		Api.get("/direction/list?sort=name", { headers: { Authorization } }).then(
			res => {
				const { data, error } = res.data

				if (!error) {
					setEduList({ ...eduList, directionList: data })

					if (data.length) {
						setEduIds({
							...eduIds,
							directionId: directionParamId || data[0].id
						})
					}
				} else {
					console.error(data)
					alert("Ошибка")
				}
			}
		)
	}

	const getCourseList = () => {
		if (eduIds.directionId) {
			setIsLoading(true)

			Api.get(`/course/list/${eduIds.directionId}`, {
				headers: { Authorization }
			}).then(res => {
				const { data, error } = res.data

				if (!error) {
					setEduList({ ...eduList, courseList: data })

					if (data.length) {
						setEduIds({ ...eduIds, courseId: courseParamId || data[0].id })
					}
				} else {
					console.error(data)
					alert("Ошибка")
				}
			})
		}
	}

	const getModuleList = () => {
		if (eduIds.courseId) {
			let link = `/module/list/${eduIds.courseId}?page=${page}&limit=10`
			console.log(link)

			if (sortAttrName) {
				link = `${link}&sort=${sortAttrName}`
			}

			Api.get(link, { headers: { Authorization } })
				.then(res => {
					setLastPage(res.data.lastPage)
					const { data, error } = res.data

					if (!error) {
						setEduList({ ...eduList, moduleList: data })
					} else {
						console.error(data)
						alert("Ошибка")
					}
				})
				.finally(() => setIsLoading(false))
		}
	}

	const removeModule = id => {
		if (window.confirm("Удалить модуль ?")) {
			Api.delete(`/module/${id}`, {
				headers: { Authorization }
			}).then(res => {
				const { error, data } = res.data
				if (!error) {
					setEduList({
						...eduList,
						moduleList: eduList.moduleList.filter(item => item.id !== id)
					})
				} else {
					console.error(data)
					alert("Ошибка")
				}
			})
		}
	}

	const sortDataHandle = sortName => {
		let direction = sortName

		if (sortAttrName === sortName) {
			direction = `-${sortName}`
		} else if (sortAttrName === `-${sortName}`) {
			direction = sortName
		}

		setSortAttrName(direction)
	}

	const getClassName = name =>
		sortAttrName === name ? "_asc" : sortAttrName === `-${name}` ? "_desc" : ""

	useEffect(() => {
		getDirectionList()
	}, [])

	useEffect(() => {
		getCourseList()
	}, [eduIds.directionId])

	useEffect(() => {
		getModuleList()
	}, [eduIds.courseId, sortAttrName])

	return (
		<>
			<div className="page-header">
				<div className="row ai-center jc-between">
					<div className="col-md-auto">
						<h6 className="title">Модули</h6>
					</div>

					{role.create && (
						<div className="col-md-auto">
							<Link to="/module/create" className="btn btn-primary">
								Добавить модуль
							</Link>
						</div>
					)}
				</div>
			</div>

			<div className="form row">
				<div className="col-md-6 field">
					<Select
						label="Профессия"
						disabled={eduList.directionList === 0}
						items={eduList.directionList}
						value={eduIds.directionId || ""}
						onChange={changeDirectionIdHandle}
					/>
				</div>

				<div className="col-md-6 field">
					<Select
						label="Курс"
						disabled={eduList.courseList === 0}
						items={eduList.courseList}
						value={eduIds.courseId || ""}
						onChange={changeCourseIdHandle}
					/>
				</div>
			</div>

			{!isLoading ? (
				<div className="card">
					<div className="card-body">
						<table>
							<thead>
								<tr>
									<th
										className={`sortable ${getClassName("name")}`}
										onClick={() => sortDataHandle("name")}>
										Название
									</th>
									<th
										className={`sortable ${getClassName("price")}`}
										onClick={() => sortDataHandle("price")}>
										Цена
									</th>
									<th
										className={`sortable ${getClassName("old_price")}`}
										onClick={() => sortDataHandle("old_price")}>
										Старая цена
									</th>
									<th>Описание</th>
									{(role.update || role.view || role.delete) && <th></th>}
								</tr>
							</thead>

							<tbody>
								{eduList.moduleList.map(item => (
									<tr key={item.id}>
										<td>
											<div className="row row-xs ai-center">
												<div className="col-auto">
													<img
														src={item.banner}
														alt=""
														className="image-cover avatar-xs avatar-rounded"
													/>
												</div>

												<div className="col font-500">{item.name}</div>
											</div>
										</td>
										<td>{item.price} ₽</td>
										<td>{item.old_price && `${item.old_price} ₽`}</td>
										<td>
											<p className="short-text">{item.description}</p>
										</td>
										<td>{item.isPublic ? "Опубликован" : "Не опубликован"}</td>
										<td className="actions">
											<div className="row row-xs">
												{role.view && (
													<div className="col-auto">
														<Link
															className="btn btn-sm btn-primary"
															to={{
																pathname: `/module/view/${item.id}`,
																state: {
																	module: item,
																	course: eduList.courseList.filter(
																		i => i.id === eduIds.courseId
																	)
																}
															}}>
															<IconWatch size={14} color="#fff"/>
														</Link>
													</div>
												)}

												{role.update && (
													<div className="col-auto">
														<Link
															to={{
																pathname: `/module/edit/${item.id}`,
																state: {
																	module: item
																}
															}}
															className="btn btn-sm btn-primary ">
															<IconEdit size={14} color="#fff"/>
														</Link>
													</div>
												)}

												{role.delete && (
													<div className="col-auto">
														<button
															className="btn btn-sm btn-danger"
															onClick={() => removeModule(item.id)}>
															<IconTrash size={14} color="#fff"/>
														</button>
													</div>
												)}
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
						<Pagination
							currentPage={page}
							totalPages={lastPage || 1}
							onChangePage={setPage}
						/>
					</div>
				</div>
			) : (
				<div className="d-flex jc-center">
					<Spinner size={100} styles={{ marginTop: 100 }}/>
				</div>
			)}
		</>
	)
}

export default ModuleList
