import React, { useContext, useState } from "react"
import { useForm } from "react-hook-form"
import Api from "../../../api"
import Input from "../../../components/Input/Input"
import { AppContext } from "../../../context/AppContext"
import authImg from "../../../static/imgs/auth-img.png"
import "../index.scss"

const AuthPage = () => {
	const { login } = useContext(AppContext)

	const [errorObj, setErrorObj] = useState({})
	const { register, handleSubmit } = useForm()

	const authHandle = async data => {
		const { email, password } = data

		let errObj = {}

		if (password.length < 6) {
			errObj = { password: "Пароль должен быть не менее 6 символов" }
		}

		setErrorObj(errObj)

		if (Object.keys(errObj).length === 0) {
			const res = await Api.post("/auth/login", {
				email,
				password
			})
			const { data, error } = res.data

			if (!error) {
				const resRole = await Api.get("/access-user", {
					headers: { Authorization: `Bearer ${data.token}` }
				})

				login(data.profile, data.token, resRole.data)
			} else {
				setErrorObj(data)
			}
		}
	}

	return (
		<div className="container">
			<div className="row jc-center">
				<div className="col-md-8 col-lg-6 col-xxl-4">
					<div className="card auth-wrap">
						<div className="wrap-header">
							<div className="row ai-end">
								<div className="col-7">
									<div className="text">
										<h4>Авторизация</h4>
										<p>Для управления админ-панелью необходимо войти.</p>
									</div>
								</div>
								<div className="col-5">
									<img src={authImg} alt=""/>
								</div>
							</div>
						</div>

						<div className="wrap-body">
							<form className="form" onSubmit={handleSubmit(authHandle)}>
								<div className="field">
									<Input
										label="E-Mail"
										placeholder="E-Mail"
										name="email"
										register={register}
										error={errorObj.email}
									/>
								</div>

								<div className="field">
									<Input
										label="Пароль"
										placeholder="Пароль"
										name="password"
										type="password"
										register={register}
										error={errorObj.password}
									/>
								</div>

								<button className="btn btn-primary   btn-block">Войти</button>
							</form>

							{/* <div className="thumb-btns h6 d-flex flex-column ai-center">
								<p>
									Нет аккаунта? <Link to="/reg">Зарегистрироваться</Link>
								</p>
							</div> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default AuthPage
