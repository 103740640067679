import React, { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Api from "../../../../../api"
import { Spinner } from "../../../../../components"
import { AppContext } from "../../../../../context/AppContext"
import "./DirectionsList.scss"

const DirectionList = () => {
	const { state } = useContext(AppContext)

	const role = state.roleList.direction

	const [directionList, setDirectionList] = useState([])

	console.log("dirdir", directionList)

	const Authorization = `Bearer ${state.authToken}`

	const getDirectionList = () => {
		Api.get("/direction/list", {
			headers: { Authorization }
		}).then(res => {
			const { data, error } = res.data

			console.log("data", data)

			if (!error) {
				setDirectionList(data)
			} else {
				console.error(data)
			}
		})
	}

	const removeDirection = id => {
		if (window.confirm("Удалить профессию?")) {
			Api.delete(`/direction/${id}`, {
				headers: { Authorization }
			}).then(res => {
				const { error, data } = res.data

				if (!error) {
					setDirectionList(directionList.filter(d => d.id !== id))
				} else {
					alert(data)
					console.error(data)
				}
			})
		}
	}

	const togglePublic = (id, published) => {
		console.log("PUBLISHED", published)
		let value
		if (published == true) {
			value = false
		} else value = true

		console.log(published)

		Api.post(
			`/publish/index?direction_id=${id}`,
			{ public: value },
			{ headers: { Authorization } }
		).then(res => {
			const { data, error } = res.data

			if (!error) {
				getDirectionList()
			} else {
				console.error(data)
				alert("Ошибка")
			}
		})
	}

	useEffect(() => {
		getDirectionList()
	}, [])

	console.log("123123", directionList)

	return (
		<div className="page-directions">
			<div className="page-header">
				<div className="row ai-center jc-between">
					<div className="col-md-auto">
						<h6 className="title">Профессии</h6>
					</div>

					{role.create && (
						<div className="col-md-auto">
							<Link to="/direction/create" className="btn btn-primary">
								Добавить профессию
							</Link>
						</div>
					)}
				</div>
			</div>

			<div className="row">
				{directionList.length > 0 ? (
					directionList.map(item => {
						const {
							id,
							name,
							description,
							banner,
							price,
							old_price,
							isPublic
						} = item

						return (
							<div key={id} className="col-sm-6 col-md-4 col-xl-3 mb-4">
								<div className="card mb-0 d-flex flex-column">
									<img src={banner} alt="" className="image-cover card-img"/>

									<div className="card-body flex-fill d-flex flex-column jc-between">
										<div>
											<div className="card-price">
												{old_price && <u>{old_price} ₽</u>}{" "}
												<span>{price} ₽</span>
											</div>

											<h5 className="card-title">{name}</h5>
											<p className="card-text">{description}</p>
										</div>

										<div className="card-btns">
											{role.view && (
												<Link
													to={{
														pathname: `/direction/view/${id}`,
														state: { direction: item }
													}}
													className="btn btn-sm btn-primary">
													Подробнее
												</Link>
											)}

											{role.update && (
												<Link
													to={{
														pathname: `/direction/edit/${id}`,
														state: { direction: item }
													}}
													className="btn btn-sm btn-primary">
													Редактировать
												</Link>
											)}

											{role.update && (
												<button
													className="btn btn-sm btn-primary"
													onClick={() =>
														togglePublic(id, isPublic)
													}>
													{isPublic ? "Снять с публикации" : "Опубликовать"}
												</button>
											)}

											{role.delete && (
												<button
													className="btn btn-sm btn-danger"
													onClick={() => removeDirection(id)}>
													Удалить
												</button>
											)}
										</div>
									</div>
								</div>
							</div>
						)
					})
				) : (
					<div className="col d-flex jc-center">
						<Spinner size={100} styles={{ marginTop: 100 }}/>
					</div>
				)}
			</div>
		</div>
	)
}

export default DirectionList
