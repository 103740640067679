import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Api from "../../../../../api"
import { Spinner } from "../../../../../components"
import ExtMaterialListTable from "../../../../../components/ExtMetarialList/ExtMaterialList"
import HomeworkListTable from "../../../../../components/HomeworkListTable/HomeworkListTable"
import PresentationListTable from "../../../../../components/PresentationListTable/PresentationListTable"
import TestTableList from "../../../../../components/TestTableList/TestTableList"
import { AppContext } from "../../../../../context/AppContext"
import { IconEdit, IconTrash, IconWatch } from "../../../../../static/icons"
import "./LessonView.scss"

const STATUS_LIST = ["Не опубликован", "Опубликован"]

const LessonView = ({ match, history }) => {
	const { id: lessonId } = match.params

	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const role = state.roleList

	const [lesson, setLesson] = useState(null)
	const [isLoading, setIsLoading] = useState(true)
	const [lessonVersionList, setLessonVersionList] = useState([])
	const [lessonTestList, setLessonTestList] = useState({})
	// const [lessonQuestList, setLessonQuestList] = useState([])
	const [homeworkList, setHomeworkList] = useState([])

	const getLessonData = () => {
		Api.get(`/lesson/${lessonId}`, { headers: { Authorization } }).then(res => {
			const { data, error } = res.data

			if (!error) {
				setLesson(data)
			} else {
				alert(data)
			}
		})
	}

	/**
	 * Получение версий урока
	 **/
	const getLessonVersionList = () => {
		Api.get(`/lesson-version/list/${lesson.id}`, {
			headers: { Authorization },
		}).then(res => {
			const { data, error } = res.data

			if (!error) {
				setLessonVersionList(data)
				setIsLoading(false)
			} else {
				console.error(data)
				alert("Ошибка")
			}
		})
	}

	/**
	 * Получение тестов
	 **/
	const getLessonTestList = () => {
		Api.get(`/test?lesson_id=${lesson.id}`, {
			headers: { Authorization },
		}).then(res => {
			const { data, error } = res.data

			if (!error) {
				if (data.questions && data.questions.length > 0) {
					setLessonTestList(data)
				} else {
					setLessonTestList({})
				}

				setIsLoading(false)
			} else {
				console.error(data)
				alert("Ошибка")
			}
		})
	}

	/**
	 * Получение квеста к уроку
	 **/
	// const getLessonQuestList = () => {
	// 	Api.get(`/quest/list?course_id=${lesson.course_id}&lesson_id=${lesson.id}`, {
	// 		headers: { Authorization },
	// 	}).then(res => {
	// 		const { data, error } = res.data

	// 		if (!error) {
	// 			if (data.length) {
	// 				setLessonQuestList(data)
	// 			}

	// 			setIsLoading(false)
	// 		} else {
	// 			console.error(data)
	// 			alert("Ошибка")
	// 		}
	// 	})
	// }

	/**
	 * Получение домашних заданий
	 **/
	const getHomeworkList = () => {
		Api.get(`/homework/list?lesson_id=${lesson.id}`, {
			headers: { Authorization },
		}).then(res => {
			const { data, error } = res.data

			if (!error) {
				if (data.length) {
					setHomeworkList(data)
				}

				setIsLoading(false)
			} else {
				console.error(data)
				alert("Ошибка")
			}
		})
	}

	/**
	 * Удаление версии урока
	 **/
	const removeLessonVersion = id => {
		if (window.confirm("Удалить версию урока?")) {
			Api.delete(`/lesson-version/${id}`, { headers: { Authorization } }).then(
				res => {
					const { error, data } = res.data

					if (!error) {
						getLessonVersionList()
					} else {
						console.error(data)
					}
				}
			)
		}
	}

	/**
	 * Удаление квеста
	 **/
	// const removeQuest = id => {
	// 	if (window.confirm("Удалить квест?")) {
	// 		Api.delete(`/quest/delete/${id}`, {
	// 			headers: { Authorization },
	// 		}).then(res => {
	// 			const { data, error } = res.data

	// 			if (!error) {
	// 				setLessonQuestList(lessonQuestList.filter(i => i.id !== id))
	// 			} else {
	// 				alert(data)
	// 			}
	// 		})
	// 	}
	// }

	/**
	 * Удаление домашних заданий
	 **/
	const removeHomework = id => {
		if (window.confirm("Удалить задание?")) {
			Api.delete(`/homework/${id}`, { headers: { Authorization } }).then(
				res => {
					const { data, error } = res.data

					if (!error) {
						setHomeworkList(homeworkList.filter(i => i.id !== id))
						alert(data)
					} else {
						alert(data)
					}
				}
			)
		}
	}

	/**
	 * Удаление дополнительного материала
	 * @param {int} docId - id документа
	 */
	const onRemoveExtMaterial = docId => {
		if (window.confirm("Удалить документ?")) {
			Api.delete(`/document/${docId}`, { headers: { Authorization } }).then(
				res => {
					const { data, error } = res.data

					if (!error) {
						// const documents = lesson.documents.filter(i => i.id !== docId)
						//
						// setLesson({ ...lesson, documents })

						getLessonData()
						alert(data)
					} else {
						alert(data)
					}
				}
			)
		}
	}

	/**
	 * Удаление презентации
	 * @param {int} docId - id презентации
	 */
	const onRemovePresentation = docId => {
		if (window.confirm("Удалить документ?")) {
			Api.delete(`/document/${docId}`, { headers: { Authorization } }).then(
				res => {
					const { data, error } = res.data

					if (!error) {
						setLesson({ ...lesson, presentation: {} })
						alert(data)
					} else {
						alert(data)
					}
				}
			)
		}
	}

	useEffect(() => {
		getLessonData()
	}, [])

	useEffect(() => {
		if (lesson) {
			getLessonVersionList()
			getLessonTestList()
			// getLessonQuestList()

			role.homework["lesson-index"] && getHomeworkList()
		}
	}, [lesson])

	if (!lesson) {
		return (
			<div className="d-flex ai-center jc-center flex-fill">
				<Spinner />
			</div>
		)
	}

	return (
		<div className="page-lesson-view">
			<div className="page-header">
				<div className="row ai-center jc-between">
					<div className="col-md">
						<h6 className="title">{lesson.name}</h6>
					</div>

					<div className="col-md-auto">
						<div onClick={() => history.goBack()} className="btn btn-primary">
							Назад
						</div>
					</div>
				</div>
			</div>

			{role["lesson-version"].index && (
				<div className="version-list-wrap">
					<div className="page-header">
						<div className="row ai-center jc-between">
							<div className="col-md-auto">
								<h6 className="title">Версии урока</h6>
							</div>

							{role["lesson-version"].create && (
								<div className="col-md-auto">
									<div>
										<Link
											to={{
												pathname: "/lesson-version/create",
												state: {
													lessonInfo: lesson,
												},
											}}
											className="btn btn-primary">
											Добавить версию
										</Link>
									</div>
								</div>
							)}
						</div>
					</div>

					{!isLoading ? (
						<div className="card">
							<div className="card-body">
								<table>
									<thead>
										<tr>
											<th>Название</th>
											<th>Описание</th>
											<th>Статус</th>
											{(role["lesson-version"].delete ||
												role["lesson-version"].update ||
												role["lesson-version"].view) && <th></th>}
										</tr>
									</thead>

									<tbody>
										{lessonVersionList.map(item => (
											<tr key={item.id}>
												<td>
													<div className="font-500">{item.name}</div>
												</td>
												<td>
													<p className="short-text">{item.description}</p>
												</td>
												<td>{STATUS_LIST[item.public]}</td>
												<td className="actions">
													<div className="row row-xs">
														{role["lesson-version"].view && (
															<div className="col-auto">
																<Link
																	to={{
																		pathname: `/lesson-version/view/${item.id}`,
																		state: {
																			lesson: item,
																			originLessonName: lesson.name,
																		},
																	}}
																	className="btn btn-sm btn-primary">
																	<IconWatch size={14} color="#fff" />
																</Link>
															</div>
														)}

														{role["lesson-version"].update && (
															<div className="col-auto">
																<Link
																	to={{
																		pathname: `/lesson-version/edit/${item.id}`,
																		state: {
																			lesson: item,
																			originLessonName: lesson.name,
																		},
																	}}
																	className="btn btn-sm btn-primary">
																	<IconEdit size={14} color="#fff" />
																</Link>
															</div>
														)}

														{role["lesson-version"].delete && (
															<div className="col-auto">
																<button
																	onClick={() => removeLessonVersion(item.id)}
																	className="btn btn-sm btn-danger">
																	<IconTrash size={14} color="#fff" />
																</button>
															</div>
														)}
													</div>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					) : (
						<div className="d-flex jc-center">
							<Spinner size={100} styles={{ marginTop: 100 }} />
						</div>
					)}
				</div>
			)}

			<div className="mt-5 page-header">
				<div className="row ai-center jc-between">
					<div className="col-md-auto">
						<h6 className="title">Тест к уроку</h6>
					</div>

					<div className="col-md-auto">
						{!lessonTestList.questions && role.test.create && (
							<Link
								to={{
									pathname: "/test/create",
									state: {
										id: lesson.id,
										courseId: lesson.course_id,
										type: "lesson_id",
									},
								}}
								className="btn btn-primary">
								Добавить тест
							</Link>
						)}
					</div>
				</div>
			</div>
			<div className="card">
				<div className="card-body">
					<TestTableList
						testDelete={getLessonTestList}
						list={lessonTestList?.questions || []}
						courseId={lesson.course_id}
						id={lesson.id}
						type="lesson_id"
						lessonVersions={lessonVersionList}
					/>
				</div>
			</div>

			{/* <div className="mt-5 page-header">
				<div className="row ai-center jc-between">
					<div className="col-md-auto">
						<h6 className="title">Квесты к уроку</h6>
					</div>

					{lessonQuestList.length === 0 && role.quest.create && (
						<div className="col-md-auto">
							<Link
								to={{
									pathname: "/quest/create",
									state: {
										id: lesson.id,
										type: "lesson_id",
									},
								}}
								className="btn btn-primary">
								Добавить квест
							</Link>
						</div>
					)}
				</div>
			</div> */}
			{/* <div className="card">
				<div className="card-body">
					<table>
						<thead>
							<tr>
								<th>Название</th>
								{(role.quest.view || role.quest.delete) && (
									<th className="actions" />
								)}
							</tr>
						</thead>

						<tbody>
							{lessonQuestList.length > 0 ? (
								lessonQuestList.map(item => (
									<tr key={item.id}>
										<td>
											<div className="font-500">{item.name}</div>
										</td>
										<td className="actions">
											<div className="row jc-end row-xs">
												{role.quest.view && (
													<div className="col-auto">
														<Link
															to={{
																pathname: `/quest/view/${item.id}`,
																state: {
																	id: lesson.id,
																	courseId: lesson.course_id,
																},
															}}
															className="btn btn-sm btn-primary">
															<IconWatch size={14} color="#fff" />
														</Link>
													</div>
												)}

												{role.quest.delete && (
													<div className="col-auto">
														<button
															onClick={() => removeQuest(item.id)}
															className="btn btn-sm btn-danger">
															<IconTrash size={14} color="#fff" />
														</button>
													</div>
												)}
											</div>
										</td>
									</tr>
								))
							) : (
								<tr>
									<td colSpan="2" className="ta-center">
										Квесты отсутствуют
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div> */}

			<div className="mt-5 page-header">
				<div className="row ai-center jc-between">
					<div className="col-md-auto">
						<h6 className="title">Домашние задания</h6>
					</div>

					{homeworkList.length === 0 && role.homework.create && (
						<div className="col-md-auto">
							<Link
								to={{
									pathname: "/homework/create",
									state: {
										id: lesson.id,
										type: "lesson_id",
									},
								}}
								className="btn btn-primary">
								Добавить домашнее задание
							</Link>
						</div>
					)}
				</div>
			</div>
			<div className="card">
				<div className="card-body">
					<HomeworkListTable data={homeworkList} onRemove={removeHomework} />
				</div>
			</div>

			<div className="mt-5 page-header">
				<div className="row ai-center jc-between">
					<div className="col-md-auto">
						<h6 className="title">Дополнительные материалы</h6>
					</div>

					{role.lesson["upload-document"] && (
						<div className="col-md-auto">
							<Link
								to={{
									pathname: "/ext-material/add",
									state: {
										lessonId: lesson.id,
										docType: 1,
									},
								}}
								className="btn btn-primary">
								Добавить материал
							</Link>
						</div>
					)}
				</div>
			</div>
			<div className="card">
				<div className="card-body">
					{lesson.documents && (
						<ExtMaterialListTable
							data={lesson.documents}
							onRemove={onRemoveExtMaterial}
						/>
					)}
				</div>
			</div>

			<div className="mt-5 page-header">
				<div className="row ai-center jc-between">
					<div className="col-md-auto">
						<h6 className="title">Презентация</h6>
					</div>

					{role.lesson["upload-document"] && !lesson.presentation?.id ? (
						<div className="col-md-auto">
							<Link
								to={{
									pathname: "/ext-material/add",
									state: {
										lessonId: lesson.id,
										docType: 2,
									},
								}}
								className="btn btn-primary">
								Добавить презентацию
							</Link>
						</div>
					) : null}
				</div>
			</div>
			<div className="card">
				<div className="card-body">
					<PresentationListTable
						data={lesson?.presentation || {}}
						onRemove={onRemovePresentation}
					/>
				</div>
			</div>
		</div>
	)
}
LessonView.propTypes = {
	match: PropTypes.any,
	history: PropTypes.any,
}

export default LessonView
