import React, { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Api from "../../../../../api"
import { Select, Spinner } from "../../../../../components"
import { Pagination } from "../../../../../components/index"
import { AppContext } from "../../../../../context/AppContext"
import { IconEdit, IconTrash, IconWatch } from "../../../../../static/icons"
import { addUrlParam, getUrlParams } from "../../../../../utils/urlManipulation"

const CourseList = () => {
	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const role = state.roleList.course

	const URL_PARAMS = getUrlParams()
	const directionParamId = URL_PARAMS["direction"]

	const [page, setPage] = useState(1)
	const [lastPage, setLastPage] = useState(null)

	const [directionId, setDirectionId] = useState(null)
	const [isLoading, setIsLoading] = useState(true)
	const [sortAttrName, setSortAttrName] = useState(null)
	const [eduList, setEduList] = useState({
		directionList: [],
		courseList: [],
	})

	console.log(eduList.courseList)

	const getDirectionList = () => {
		Api.get("/direction/list?sort=name", { headers: { Authorization } }).then(
			res => {
				const { data, error } = res.data

				if (!error) {
					setEduList({ ...eduList, directionList: data })

					if (data.length) {
						setDirectionId(directionParamId || data[0].id)
					}
				} else {
					console.error(data)
				}
			}
		)
	}

	const getCourseList = () => {
		if (directionId) {
			setIsLoading(true)

			let link = `/course/list/${directionId}?page=${page}&limit=10`

			if (sortAttrName) {
				link = `${link}&sort=${sortAttrName}`
			}

			Api.get(link, { headers: { Authorization } }).then(res => {
				setLastPage(res.data.lastPage)
				const { data, error } = res.data

				if (!error) {
					setEduList({ ...eduList, courseList: data })
				} else {
					console.error(data)
				}

				setIsLoading(false)
			})
		}
	}

	const removeCourse = id => {
		if (window.confirm("Удалить курс ?")) {
			Api.delete(`/course/${id}`, {
				headers: { Authorization },
			}).then(res => {
				const { error, data } = res.data

				if (!error) {
					setEduList({
						...eduList,
						courseList: eduList.courseList.filter(i => i.id !== id),
					})
				} else {
					alert(data)
					console.error(data)
				}
			})
		}
	}

	const sortDataHandle = sortName => {
		let direction = sortName

		if (sortAttrName === sortName) {
			direction = `-${sortName}`
		} else if (sortAttrName === `-${sortName}`) {
			direction = sortName
		}

		setSortAttrName(direction)
	}

	const changeDirectionIdHandle = e => {
		const value = +e.target.value

		addUrlParam("direction", value)
		setDirectionId(value)
	}

	const getClassName = name =>
		sortAttrName === name ? "_asc" : sortAttrName === `-${name}` ? "_desc" : ""

	useEffect(() => {
		getDirectionList()
	}, [])

	useEffect(() => {
		getCourseList()
	}, [directionId, sortAttrName])

	return (
		<>
			<div className="page-header">
				<div className="row ai-center jc-between">
					<div className="col-md-auto">
						<h6 className="title">Курсы</h6>
					</div>

					{role.create && (
						<div className="col-md-auto">
							<Link to="/course/create" className="btn btn-primary">
								Добавить курс
							</Link>
						</div>
					)}
				</div>
			</div>

			<div className="form">
				<div className="field">
					<Select
						label="Профессия"
						disabled={eduList.directionList === 0}
						items={eduList.directionList}
						value={directionId || ""}
						onChange={changeDirectionIdHandle}
					/>
				</div>
			</div>

			{!isLoading ? (
				<div className="card">
					<div className="card-body">
						<table>
							<thead>
								<tr>
									<th
										className={`sortable ${getClassName("name")}`}
										onClick={() => sortDataHandle("name")}>
										Название
									</th>
									<th
										className={`sortable ${getClassName("price")}`}
										onClick={() => sortDataHandle("price")}>
										Цена
									</th>
									<th
										className={`sortable ${getClassName("old_price")}`}
										onClick={() => sortDataHandle("old_price")}>
										Старая цена
									</th>
									<th>Описание</th>
									{(role.update || role.delete || role.view) && <th></th>}
								</tr>
							</thead>

							<tbody>
								{eduList.courseList.map(item => {
									const {
										id,
										name,
										banner,
										description,
										price,
										old_price,
										isPublic,
									} = item

									return (
										<tr key={id}>
											<td>
												<div className="row row-xs ai-center">
													<div className="col-auto">
														<img
															src={banner}
															alt={name}
															className="image-cover avatar-xs avatar-rounded"
														/>
													</div>

													<div className="col font-500">{name}</div>
												</div>
											</td>
											<td>{price} ₽</td>
											<td>{old_price && `${old_price} ₽`} </td>
											<td>
												<p className="short-text">{description}</p>
											</td>
											<td>{isPublic ? "Опубликован" : "Не опубликован"}</td>
											<td className="actions">
												<div className="row row-xs">
													{role.view && (
														<div className="col-auto">
															<Link
																to={{
																	pathname: `/course/view/${id}`,
																	state: {
																		course: item,
																	},
																}}
																className="btn btn-sm btn-primary">
																<IconWatch size={14} color="#fff" />
															</Link>
														</div>
													)}

													{role.update && (
														<div className="col-auto">
															<Link
																to={{
																	pathname: `/course/edit/${id}`,
																	state: {
																		course: {
																			...item,
																			price,
																			old_price,
																		},
																	},
																}}
																className="btn btn-sm btn-primary">
																<IconEdit size={14} color="#fff" />
															</Link>
														</div>
													)}

													{role.delete && (
														<div className="col-auto">
															<button
																className="btn btn-sm btn-danger"
																onClick={() => removeCourse(id)}>
																<IconTrash size={14} color="#fff" />
															</button>
														</div>
													)}
												</div>
											</td>
										</tr>
									)
								})}
							</tbody>
						</table>
						<Pagination
							currentPage={page}
							totalPages={lastPage || 1}
							onChangePage={setPage}
						/>
					</div>
				</div>
			) : (
				<div className="d-flex jc-center">
					<Spinner size={100} styles={{ marginTop: 100 }} />
				</div>
			)}
		</>
	)
}

export default CourseList
