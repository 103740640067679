import PropTypes from "prop-types"
import React, { Fragment, useContext } from "react"
import { Link, useLocation } from "react-router-dom"
import { AppContext } from "../../context/AppContext"
import { LEFT_MENU_LIST } from "../../global/leftMenuRoutes"
import "./LeftMenu.scss"

const LeftMenu = ({ roleList }) => {
	const { state } = useContext(AppContext)

	const location = useLocation()

	return (
		<div id="left-menu" className="scroll-y">
			<ul>
				{LEFT_MENU_LIST.map(block => (
					<Fragment key={block.blockTitle}>
						<li className="menu-block-title">{block.blockTitle}</li>

						<li>
							<ul>
								{block.menu.map(i => {
									const Icon = i.icon

									if (!roleList[i.key]?.index) {
										return null
									}

									return (
										<li key={i.title}>
											<Link
												to={i.path}
												className={`d-flex ai-center jc-between ${
													i.pathMatch.includes(location.pathname.split("/")[1]) ? "_active" : ""
												}`}
											>
												<Icon size={18} className="pos-abs-y" /> {i.title}
												{i.key === "homework" && state.unreadMessages.homework ? (
													<span className="badge">{state.unreadMessages.homework}</span>
												) : null}
												{i.key === "support" && state.unreadMessages.support ? (
													<span className="badge">{state.unreadMessages.support}</span>
												) : null}
												{i.key === "graduate-work" && state.unreadMessages["graduate_work"] ? (
													<span className="badge">{state.unreadMessages["graduate_work"]}</span>
												) : null}
												{i.key === "course-work" && state.unreadMessages["course_work"] ? (
													<span className="badge">{state.unreadMessages["course_work"]}</span>
												) : null}
											</Link>
										</li>
									)
								})}
							</ul>
						</li>
					</Fragment>
				))}
			</ul>
		</div>
	)
}
LeftMenu.propTypes = {
	roleList: PropTypes.object
}

export default LeftMenu
