/* eslint-disable react/prop-types */
import * as React from "react"

const SvgComponent = ({ size, color, ...props }) => (
	<svg height={size} viewBox="0 0 515.556 515.556" width={size} {...props}>
		<path
			fill={color}
			d="M257.778 64.444C138.666 64.444 37.609 145.218 0 257.778c37.609 112.56 138.666 193.333 257.778 193.333s220.169-80.774 257.778-193.333C477.947 145.218 376.89 64.444 257.778 64.444zm0 322.223c-71.184 0-128.889-57.706-128.889-128.889 0-71.184 57.705-128.889 128.889-128.889s128.889 57.705 128.889 128.889c0 71.182-57.705 128.889-128.889 128.889z"
		/>
		<path
			fill={color}
			d="M303.347 212.209c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138 65.971-25.167 91.138 0"
		/>
	</svg>
)

export default SvgComponent
