/* eslint-disable */
import React, { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Api from "../../../../api"
import { Input, Modal, Select, Spinner } from "../../../../components"
import { Pagination } from "../../../../components/index"
import { AppContext } from "../../../../context/AppContext"
import useDebounce from "../../../../hooks/useDebounce"
import { IconWatch } from "../../../../static/icons"
import default_avatar from "../../../../static/imgs/users/default_avatar.svg"

const statusList = [
	{ name: "Все статусы", id: "#" },
	{
		name: "Подтвержденный",
		id: "10",
	},
	{ name: "Не подтвержденный", id: "9" },
]

const UserList = () => {
	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const role = state.roleList.user

	const [page, setPage] = useState(1)
	const [lastPage, setLastPage] = useState(null)

	const [isLoading, setIsLoading] = useState(true)
	const [roleList, setRoleList] = useState([])
	const [userList, setUserList] = useState([])
	const [userAddModalVisible, setUserAddModalVisible] = useState(false)
	const [userAddEmail, setUserAddEmail] = useState("")
	const [errObj, setErrObj] = useState({})
	const [isUserAddSending, setIsUserAddSending] = useState(false)
	const [roleListId, setRoleListId] = useState("")
	const [statusListId, setStatusListId] = useState("")
	const [filterNameUserText, setFilterNameUserText] = useState("")
	const [filterEmailUserText, setFilterEmailUserText] = useState("")
	const [valueUsers, setValueUsers] = useState("")

	let getUserListLink = `/user/list?page=${page}&limit=10`

	const debouncedUserSearchName = useDebounce(filterNameUserText, 300)
	if (debouncedUserSearchName.length) {
		getUserListLink += `&fullname=${debouncedUserSearchName}`
	}

	if (statusListId !== "#") {
		getUserListLink += `&status=${statusListId}`
	}
	if (statusListId === "#") {
		getUserListLink += `&status=`
	}

	if (roleListId !== "#") {
		getUserListLink += `&role=${roleListId}`
	}
	if (roleListId === "#") {
		getUserListLink += `&role=`
	}

	const debouncedUserSearchEmail = useDebounce(filterEmailUserText, 300)
	if (debouncedUserSearchEmail.length) {
		getUserListLink += `&email=${debouncedUserSearchEmail}`
	}

	const getUserList = () => {
		Api.get(getUserListLink, {
			headers: { Authorization },
		}).then(res => {
			setValueUsers(res.data.count)
			setLastPage(res.data.lastPage)
			const { error, data } = res.data
			if (!error) {
				setUserList(data)
			} else {
				console.error(data)
				alert("Ошибка")
			}
			setIsLoading(false)
		})
	}

	useEffect(() => {
		getUserList()
	}, [debouncedUserSearchName])

	useEffect(() => {
		getUserList()
	}, [statusListId])

	useEffect(() => {
		getUserList()
	}, [roleListId])

	useEffect(() => {
		getUserList()
	}, [debouncedUserSearchEmail])

	const showUserAddModal = () => {
		setUserAddModalVisible(true)
		setUserAddEmail("")
		setErrObj({})
	}

	const closeUserAddModal = () => {
		setUserAddModalVisible(false)
	}

	const onUserCreate = e => {
		e.preventDefault()

		if (!isUserAddSending) {
			setIsUserAddSending(true)

			Api.post(
				`/user/create-user`,
				{ email: userAddEmail },
				{ headers: { Authorization } }
			)
				.then(res => {
					const { error, data } = res.data

					if (!error) {
						getUserList()
						alert("Пользователь успешно создан")

						closeUserAddModal()
					} else {
						setErrObj(data)

						if (data.userExist) {
							if (
								window.confirm(
									"Пользователь существует. Отправить новые авторизационные данные?"
								)
							) {
								Api.post(
									`/user/send-reset-password/${data.userId}`,
									{},
									{ headers: { Authorization } }
								).then(res => {
									const { data, error } = res.data

									if (!error) {
										alert(data)
										closeUserAddModal()
									} else {
										console.error(data)
										alert("Ошибка")
									}
								})
							}
						}
					}
				})
				.finally(() => setIsUserAddSending(false))
		}
	}

	const getRoleList = async () => {
		try {
			const res = await Api.get("/access/list", { headers: { Authorization } })
			const { data } = res.data

			let obj = { role: "#", name: "Все роли" }
			let obj1 = { role: "student", name: "Студент" }
			data.unshift(obj)
			data.push(obj1)

			const rebuildingObject = arr => {
				arr.forEach(obj => {
					for (let key in obj) {
						obj["id"] = obj["role"]
					}
				})
			}

			rebuildingObject(data)

			console.log("data", data)

			setRoleList(data)
		} catch (e) {
			console.error(e)
		}
	}
	console.log("rolelist", roleList)
	const filterNameUser = e => {
		setFilterNameUserText(e)
		setPage(1)
	}

	const filterEmailUser = e => {
		setFilterEmailUserText(e)
		setPage(1)
	}

	const filterStatusUser = e => {
		const value = e.target.value
		setStatusListId(value)
		setPage(1)
	}

	const filterRoleUser = e => {
		const value = e.target.value
		setRoleListId(value)
		setPage(1)
	}

	useEffect(() => {
		getUserList()
	}, [page])

	useEffect(() => {
		getRoleList(), getUserList()
	}, [])

	return (
		<>
			<div className="page-header">
				<div className="row mb-4 ai-c jc-between">
					<div className="col-auto d-flex">
						<h6 className="title">Пользователи</h6>
					</div>

					{role["create-user"] && (
						<div className="col-auto">
							<button className="btn btn-primary" onClick={showUserAddModal}>
								Создать пользователя
							</button>
						</div>
					)}
				</div>
				<div className="row ai-c jc-between form">
					<div className="col-md-3 col-12">
						<div className="field">
							<Input
								required
								onChange={e => filterNameUser(e.target.value)}
								label="ФИО"
								placeholder="Введите ФИО.."
								name="name"
							/>
						</div>
					</div>
					<div className="col-md-3 col-12">
						<div>
							<div className="field">
								<Input
									required
									onChange={e => filterEmailUser(e.target.value)}
									label="E-mail"
									placeholder="Введите E-mail.."
									name="name"
								/>
							</div>
						</div>
					</div>
					<div className="col-md-3 col-12">
						<div className="field">
							<Select
								label="Статус"
								items={statusList}
								value={statusListId || ""}
								onChange={filterStatusUser}
							/>
						</div>
					</div>
					<div className="col-md-3 col-12">
						<div className="field">
							<Select
								label="Роль"
								items={roleList}
								value={roleListId || ""}
								onChange={filterRoleUser}
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12 f-700">Количество : {valueUsers}</div>
				</div>
			</div>

			{!isLoading ? (
				<div className="card">
					<div className="card-body">
						<table>
							<thead className="sortable">
								<tr>
									<th>ФИО</th>
									<th>Email</th>
									<th>Дата рождения</th>
									<th>Статус</th>
									<th>Роль</th>
									{role.view && <th></th>}
								</tr>
							</thead>

							<tbody>
								{userList.map(item => (
									<tr key={item.id}>
										<td>
											<div className="row row-xs ai-center">
												<div className="col-auto">
													<img
														src={item.profile.avatarThumb || default_avatar}
														className="image-cover avatar-xs avatar-circle"
													/>
												</div>
												<div className="col font-500">
													{item.profile.last_name} {item.profile.first_name}{" "}
													{item.profile.patronymic}{" "}
												</div>
											</div>
										</td>
										<td>{item.email} </td>
										<td>{item.profile.birthDate} </td>
										<td>
											{item.status === 10
												? "Подтвержденный"
												: "Не подтвержденный"}
										</td>
										<td>{item.userRolesName} </td>
										{role.view && (
											<td className="actions">
												<Link
													to={`/user/view/${item.id}`}
													className="btn btn-sm btn-primary">
													<IconWatch size={14} color="#fff" />
												</Link>
											</td>
										)}
									</tr>
								))}
							</tbody>
						</table>
						<Pagination
							currentPage={page}
							totalPages={lastPage || 1}
							onChangePage={setPage}
						/>
					</div>
				</div>
			) : (
				<div className="d-flex jc-center">
					<Spinner size={100} styles={{ marginTop: 100 }} />
				</div>
			)}

			<Modal show={userAddModalVisible} onClose={closeUserAddModal}>
				<form className="form" onSubmit={onUserCreate}>
					<Input
						label="E-Mail"
						value={userAddEmail}
						onChange={e => setUserAddEmail(e.target.value)}
						error={errObj?.email || ""}
					/>

					<button type="submit" className="btn btn-primary btn-block">
						Создать
					</button>
				</form>
			</Modal>
		</>
	)
}

export default UserList
