/* eslint-disable react/prop-types */
import React, { useContext, useState } from "react"
import Api from "../../../../../../api"
import { Input, Modal } from "../../../../../../components"
import { AppContext } from "../../../../../../context/AppContext"

const AddAnswerModal = ({
	onCloseModal,
	questionId,
	onAddAnswer,
	qIndex,
	influences,
}) => {
	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const [formData, setFormData] = useState({
		answer: "",
		correct: "",
	})

	const addAnswer = () => {
		const { answer, correct } = formData

		Api.post(
			`/test/add-answer/${questionId}`,
			{ answer, correct },
			{ headers: { Authorization } }
		).then(res => {
			const { error, data } = res.data

			if (!error) {
				onAddAnswer(formData =>
					formData.map((q, qInd) => {
						if (qInd === qIndex) {
							return {
								...q,
								answers: [
									...q.answers,
									{ id: data.id, answer, correct, influences },
								],
							}
						}

						return q
					})
				)

				onCloseModal()
			} else {
				console.error(data)
				alert("Ошибка")
			}
		})
	}

	return (
		<Modal show onClose={onCloseModal} modalWrapperStyles={{ maxWidth: 800 }}>
			<div className="form">
				<div className="field row ai-end">
					<div className="col">
						<Input
							label="Ответ"
							defaultValue={formData.answer}
							onChange={e =>
								setFormData({ ...formData, answer: e.target.value })
							}
							placeholder="Ответ"
						/>
					</div>

					<div className="col-auto d-flex ai-center" style={{ height: 38 }}>
						<label className="cursor-pointer">
							<input
								defaultValue={formData.correct}
								onChange={() =>
									setFormData({
										...formData,
										correct: formData.correct === "on" ? "" : "on",
									})
								}
								type="checkbox"
								className="input-checkbox"
							/>
						</label>
					</div>

					<div className="col-12">
						<span className="btn btn-primary" onClick={addAnswer}>
							Добавить ответ
						</span>
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default AddAnswerModal
