import PropType from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import Api from "../../../../../api"
import { Dropzone, Input, Select, Textarea } from "../../../../../components"
import { Modal, Spinner } from "../../../../../components/index"
import { AppContext } from "../../../../../context/AppContext"

const CourseCreate = ({ history }) => {
	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`
	const [filesBanner, setFilesBanner] = useState([])
	const [filesVideo, setFilesVideo] = useState([])

	const [isSending, setIsSending] = useState(false)
	const [directionId, setDirectionId] = useState(null)
	const [directionList, setDirectionList] = useState([])
	const [errorObj, setErrorObj] = useState({})

	const [downloadPercent, setDownloadPercent] = useState(0)

	const { register, handleSubmit } = useForm()

	/*
	 * получение листа с профессиими
	 */
	const getDirectionList = () => {
		Api.get("/direction/list", { headers: { Authorization } }).then(res => {
			const { data, error } = res.data

			if (!error) {
				setDirectionList(data)

				if (data.length) {
					setDirectionId(data[0].id)
				}
			} else {
				console.error(data)
			}
		})
	}

	/*
	 * получение id профессии
	 */
	const changeDirectionIdHandle = e => setDirectionId(e.target.value)

	/*
	 * Загрузка превью и удаление видео и баннера в дропзоне
	 */
	const onFileRemoveBanner = fileName => {
		setFilesBanner(filesBanner.filter(f => f.name !== fileName))
	}

	// const onFileRemoveVideo = fileName => {
	// 	setFilesVideo(filesVideo.filter(f => f.name !== fileName))
	// }

	const onFileUploadBanner = filesData => {
		setFilesBanner(filesData)
	}

	// const onFileUploadVideo = filesData => {
	// 	setFilesVideo(filesData)
	// }

	const onCourseAdd = data => {
		if (!isSending) {
			setIsSending(true)

			const { name, description, price, old_price } = data

			let errObj = {}

			if (price < 0) errObj = { ...errObj, price: "Некорректная сумма" }
			if (old_price && +old_price < +price)
				errObj = { ...errObj, old_price: "Старая цена должна быть больше" }

			setErrorObj(errObj)

			if (Object.keys(errObj).length === 0) {
				const formData = new FormData()
				formData.append("name", name)
				formData.append("description", description)
				formData.append("price", price)
				formData.append("old_price", old_price)
				formData.append("banner", filesBanner[0])
				formData.append("video", filesVideo[0])

				Api.post(`/course/${directionId}`, formData, {
					headers: { Authorization },
					onUploadProgress: progressEvent => {
						const percentCompleted = Math.round(
							(progressEvent.loaded * 100) / progressEvent.total
						)
						setDownloadPercent(percentCompleted)
					},
				})
					.then(res => {
						const { data, error } = res.data

						if (!error) {
							alert("Курс успешно создан")
							setFilesBanner([])
							setFilesVideo([])
							history.goBack()
						} else {
							setErrorObj(data)
							console.error(data)
							data.banner && alert(data.banner)
						}
					})
					.finally(() => {
						setDownloadPercent(0)
						setIsSending(false)
					})
			}
		}
	}

	useEffect(() => {
		getDirectionList()
	}, [])

	return (
		<>
			<div className="page-header">
				<h6 className="title">Создать курс</h6>
			</div>

			<form className="form" onSubmit={handleSubmit(onCourseAdd)}>
				<div className="row">
					<div className="col-xl-4">
						<div className="field">
							<Input
								required
								label="Название"
								placeholder="Название"
								name="name"
								register={register}
								error={errorObj.name}
							/>
						</div>
					</div>

					<div className="col-xl-4">
						<div className="field">
							<Select
								label="Профессия"
								disabled={directionList === 0}
								items={directionList}
								onChange={changeDirectionIdHandle}
							/>
						</div>
					</div>

					<div className="col-xl-2">
						<div className="field">
							<Input
								required
								label="Цена"
								placeholder="Цена"
								name="price"
								register={register}
								error={errorObj.price}
							/>
						</div>
					</div>

					<div className="col-xl-2">
						<div className="field">
							<Input
								label="Старая цена"
								placeholder="Старая цена"
								name="old_price"
								register={register}
								error={errorObj.old_price}
							/>
						</div>
					</div>
				</div>

				<div className="field">
					<Textarea
						required
						label="Описание"
						placeholder="Описание"
						name="description"
						register={register}
						rows="8"
						error={errorObj.description}
					/>
				</div>

				<div className="row">
					<div className="col-xl-6">
						<div className="field">
							<div className="h6 mb-2">Загрузка баннера</div>
							<Dropzone
								multiple={false}
								maxSize={5000000}
								acceptTypes="image/png, image/jpeg, image/gif"
								files={filesBanner}
								onFileRemove={onFileRemoveBanner}
								onFileUpload={onFileUploadBanner}
							/>
						</div>
					</div>

					{/* <div className="col-xl-6">
						<div className="field">
							<div className="h6 mb-2">Загрузка видео</div>
							<Dropzone
								multiple={false}
								maxSize={2000000000}
								acceptTypes="video/*"
								files={filesVideo}
								onFileRemove={onFileRemoveVideo}
								onFileUpload={onFileUploadVideo}
							/>
						</div>
					</div> */}
				</div>

				<button className="btn btn-primary btn-lg">Создать</button>

				<Modal
					show={isSending}
					showClose={false}
					modalWrapperStyles={{ maxWidth: 300 }}>
					<div className="ta-center">
						<Spinner size={100} />
						<div className="font-700 mt-2">{parseInt(downloadPercent)}%</div>
					</div>
				</Modal>
			</form>
		</>
	)
}

CourseCreate.propTypes = {
	history: PropType.object,
}

export default CourseCreate
