import PropType from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import Api from "../../../../../api"
import { Input, Textarea } from "../../../../../components"
import { Modal, Spinner } from "../../../../../components/index"
import { AppContext } from "../../../../../context/AppContext"

const LessonEdit = ({ history, location }) => {
	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const { lesson, moduleId } = location.state

	const { handleSubmit, register } = useForm({
		defaultValues: {
			name: lesson.name || "",
			description: lesson.description || "",
			public: lesson.public || ""
		}
	})

	const [isSending, setIsSending] = useState(false)
	const [errorObj, setErrorObj] = useState({})
	// const [moduleId, setModuleId] = useState(null)

	// const [directionList, setDirectionList] = useState([])
	// const [courseList, setCourseList] = useState([])
	// const [moduleList, setModuleList] = useState([])

	// const getDirectionIdHandle = e => {
	// 	getCourseList(e.target.value)
	// }
	// const getCourseIdHandle = e => {
	// 	getModuleList(e.target.value)
	// }
	// const getModuleIdHandle = e => {
	// 	setModuleId(e.target.value)
	// }

	const onLessonEdit = data => {
		if (!isSending) {
			setIsSending(true)

			Api.post(
				`/lesson/update/${lesson.id}`,
				{ ...data, module_id: moduleId },
				{
					headers: { Authorization }
				}
			).then(res => {
				const { data, error } = res.data

				if (!error) {
					setErrorObj({})
					alert(data)
					history.goBack()
				} else {
					console.error(data)
					alert("Ошибка")
				}
			})
		}

		setIsSending(false)
	}

	console.log("lesson", lesson)

	const togglePublic = () => {

		let value
		if (lesson.public == 1) {
			value = false
		} else value = true

		console.log(lesson.public)

		Api.post(
			`/publish/index?lesson_id=${lesson.id}`,
			{ public: value },
			{ headers: { Authorization } }
		).then(res => {
			const { data, error } = res.data

			if (!error) {
				history.goBack()
				alert("Настройки публикации изменены")
			} else {
				console.error(data)
				alert("Ошибка")
			}
		})
	}

	// const getDirectionList = () => {
	// 	Api.get(`/direction/list?sort=name`, { headers: { Authorization } }).then(res => {
	// 		const { data, error } = res.data

	// 		if (!error) {
	// 			if (data.length) {
	// 				setDirectionList(data)
	// 				getCourseList(data[0].id)
	// 			}
	// 		} else {
	// 			console.error(data)
	// 		}
	// 	})
	// }

	// const getCourseList = directionId => {
	// 	setCourseList([])

	// 	Api.get(`/course/list/${directionId}`, { headers: { Authorization } }).then(res => {
	// 		const { data, error } = res.data

	// 		if (!error) {
	// 			if (data.length) {
	// 				setCourseList(data)
	// 				getModuleList(data[0].id)
	// 			} else {
	// 			}
	// 		} else {
	// 			console.error(data)
	// 		}
	// 	})
	// }

	// const getModuleList = courseId => {
	// 	setModuleList([])

	// 	Api.get(`/module/list/${courseId}`, { headers: { Authorization } }).then(res => {
	// 		const { data, error } = res.data

	// 		if (!error) {
	// 			if (data.length) {
	// 				setModuleList(data)
	// 				setModuleId(data[0].id)
	// 			}
	// 		} else {
	// 			console.error(data)
	// 		}
	// 	})
	// }

	useEffect(() => {
		// getDirectionList()
	}, [])

	return (
		<>
			<div className="page-header">
				<div className="row ai-center jc-between">
					<div className="col-md-auto">
						<h6 className="title">Редактировать урок</h6>
					</div>

					<div className="col-md-auto">
						<button
							onClick={() => history.goBack()}
							className="btn btn-primary btn-lg">
							Назад
						</button>
					</div>
				</div>
			</div>

			<form className="form" onSubmit={handleSubmit(onLessonEdit)}>
				<div className="row">
					{/* <div className="col-md-4">
						<div className="field">
							<Select label="Профессия" items={directionList} onChange={getDirectionIdHandle} />
						</div>
					</div>

					<div className="col-md-4">
						<div className="field">
							<Select label="Курс" items={courseList} onChange={getCourseIdHandle} />
						</div>
					</div>

					<div className="col-md-4">
						<div className="field">
							<Select label="Модуль" items={moduleList} onChange={getModuleIdHandle} />
						</div>
					</div> */}

					<div className="col-12">
						<div className="field">
							<Input
								required
								label="Название"
								placeholder="Название"
								name="name"
								register={register}
								error={errorObj.name}
							/>
						</div>
					</div>

					<div className="col-12">
						<div className="field">
							<Textarea
								required
								label="Описание"
								placeholder="Описание"
								name="description"
								register={register}
								rows="8"
								error={errorObj.description}
							/>
						</div>
					</div>
					<div className="col-12">
						<div className="field">
							<label className="d-flex ai-center cursor-pointer mt-2">
								<input
									type="checkbox"
									onChange={() => togglePublic()}
									checked={lesson.isPublic}
									className="input-checkbox mr-2"
									ref={register}
									name="public"
								/>
								Опубликовать
							</label>
						</div>
					</div>
				</div>

				<button type="submit" className="btn btn-primary btn-lg">
					Редактировать
				</button>
			</form>

			<Modal
				show={isSending}
				showClose={false}
				modalWrapperStyles={{ maxWidth: 300 }}>
				<div className="ta-center">
					<Spinner size={100}/>
				</div>
			</Modal>
		</>
	)
}

LessonEdit.propTypes = {
	history: PropType.object,
	location: PropType.object
}

export default LessonEdit
