import pluralize from "pluralize-ru"
import PropType from "prop-types"
import React, { useContext, useState } from "react"
import Api from "../../../../../api"
import { Input } from "../../../../../components"
import { Modal, Spinner } from "../../../../../components/index"
import { AppContext } from "../../../../../context/AppContext"
import { IconTrash } from "../../../../../static/icons"

const TestCreate = ({ location, history }) => {
	const { id, type, courseId } = location.state

	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const [isSending, setIsSending] = useState(false)
	const [formData, setFormData] = useState([
		{
			question: "",
			answers: [{ answer: "", correct: "" }],
		},
	])
	const [incorrectQuestionCount, setIncorrectQuestionCount] = useState("")

	const onAddTest = e => {
		e.preventDefault()

		if (!isSending) {
			setIsSending(true)

			Api.post(`/test/create/${courseId}?${type}=${id}`, formData, {
				headers: { Authorization },
			})
				.then(res => {
					const { error, data } = res.data
					if (!error) {
						onSaveIncorrectQuestionCount()
					} else {
						console.error(data)
						alert("Ошибка")
					}
				})
				.finally(() => setIsSending(false))
		}
	}

	const onSaveIncorrectQuestionCount = () => {
		Api.post(
			`/test/correct?${type}=${id}`,
			{ not_correct: +incorrectQuestionCount },
			{ headers: { Authorization } }
		).then(res => {
			const { error, data } = res.data

			if (!error) {
				alert("Тест успешно создан")
				history.goBack()
			} else {
				console.error(data)
			}
		})
	}

	const removeQuestion = index => {
		setFormData(formData.filter((i, ind) => ind !== index))
	}

	const removeAnswer = index => {
		const data = formData.map(q => ({
			...q,
			answers: q.answers.filter((a, ind) => ind !== index),
		}))

		setFormData(data)
	}

	const addAnswer = qIndex => {
		const data = formData.map((q, qInd) => {
			if (qInd === qIndex) {
				return { ...q, answers: [...q.answers, { answer: "", correct: "" }] }
			}

			return q
		})

		setFormData(data)
	}

	const addQuestion = () => {
		setFormData([...formData, { question: "", answers: [] }])
	}

	const changeQuestionValue = (question, qIndex) => {
		const data = formData.map((q, qInd) => {
			if (qInd === qIndex) {
				return { ...q, question }
			}

			return q
		})

		setFormData(data)
	}

	const changeAnswerValue = (answer, qIndex, aIndex) => {
		const answ = formData[qIndex].answers.map((a, aInd) => {
			if (aInd === aIndex) {
				return { ...a, answer }
			}

			return a
		})
		const formD = [...formData]
		formD[qIndex].answers = answ

		setFormData(formD)
	}

	const changeAnswerCorrect = (qIndex, aIndex) => {
		const answ = formData[qIndex].answers.map((a, aInd) => {
			if (aInd === aIndex) {
				if (a.correct === "") {
					return { ...a, correct: "on" }
				} else {
					return { ...a, correct: "" }
				}
			}
			return a
		})

		const formD = [...formData]
		formD[qIndex].answers = answ

		setFormData(formD)
	}

	return (
		<>
			<div className="page-header">
				<div className="row ai-center jc-between">
					<div className="col-md-auto">
						<h6 className="title">Создание теста</h6>
					</div>

					<div className="col-md-auto">
						<button
							onClick={() => history.goBack()}
							className="btn btn-primary btn-lg">
							Назад
						</button>
					</div>
				</div>
			</div>

			<form className="form" onSubmit={onAddTest}>
				<div className="card">
					<div className="card-body">
						<div className="mb-4">
							<Input
								label={`Максимальное количество ошибок в тесте из ${
									formData.length
								} ${pluralize(
									formData.length,
									"вопросов",
									"вопроса",
									"вопросов",
									"вопросов"
								)}`}
								placeholder="0"
								value={incorrectQuestionCount}
								onChange={e => setIncorrectQuestionCount(e.target.value)}
								type="number"
							/>
						</div>

						{formData.map((item, qIndex) => (
							<div className="card" key={qIndex}>
								<div className="card-body">
									<div className="field">
										<div className="row row-sm ai-end">
											<div className="col">
												<Input
													label={`Вопрос ${qIndex + 1}`}
													name={`test[${qIndex}].question`}
													placeholder="Вопрос"
													value={item.question}
													onChange={e =>
														changeQuestionValue(e.target.value, qIndex)
													}
												/>
											</div>

											<div className="col-auto">
												<div
													className="btn btn-danger"
													onClick={() => removeQuestion(qIndex)}>
													<IconTrash size={13} color="#fff" />
												</div>
											</div>
										</div>
									</div>

									<div className="pl-md-3">
										{item.answers.map((item, aIndex) => {
											return (
												<div className="field" key={aIndex}>
													<div className="row row-sm ai-end">
														<div className="col">
															<Input
																label={`Ответ ${aIndex + 1}`}
																value={item.answer}
																onChange={e =>
																	changeAnswerValue(
																		e.target.value,
																		qIndex,
																		aIndex
																	)
																}
																placeholder="Ответ"
															/>
														</div>

														<div
															className="col-auto d-flex ai-center"
															style={{ height: 38 }}>
															<label className="cursor-pointer">
																<input
																	type="checkbox"
																	className="input-checkbox"
																	onChange={() =>
																		changeAnswerCorrect(qIndex, aIndex)
																	}
																	value={item.correct}
																/>
															</label>
														</div>

														<div className="col-auto">
															<div
																className="btn btn-danger"
																onClick={() => removeAnswer(aIndex)}>
																<IconTrash size={13} color="#fff" />
															</div>
														</div>
													</div>
												</div>
											)
										})}

										<div
											className="btn btn-primary btn-sm"
											onClick={() => addAnswer(qIndex)}>
											Добавить ответ
										</div>
									</div>
								</div>
							</div>
						))}

						<div className="btn btn-primary" onClick={() => addQuestion()}>
							Добавить вопрос
						</div>
					</div>
				</div>

				<div className="d-flex jc-end">
					<button type="submit" className="btn btn-primary btn-lg">
						Создать тест
					</button>
				</div>
			</form>

			<Modal
				show={isSending}
				showClose={false}
				modalWrapperStyles={{ maxWidth: 300 }}>
				<div className="ta-center">
					<Spinner size={100} />
				</div>
			</Modal>
		</>
	)
}

TestCreate.propTypes = {
	location: PropType.object,
	history: PropType.object,
}

export default TestCreate
