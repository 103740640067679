import PropType from "prop-types"
import React, { useContext, useState } from "react"
import { useForm } from "react-hook-form"
import Api from "../../../../../api"
import { Dropzone, Input, Textarea } from "../../../../../components"
import { Modal, Spinner } from "../../../../../components/index"
import { AppContext } from "../../../../../context/AppContext"

const HomeWorkCreate = ({ location, history }) => {
	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const { id, type } = location.state

	const [isSending, setIsSending] = useState(false)
	const [errorObj, setErrorObj] = useState({})
	const [files, setFiles] = useState([])
	const [questionList, setQuestionList] = useState([
		{ question: "", type: 1, prompt: "" },
	])

	const { register, handleSubmit } = useForm()

	/**
	 * Создание домашнего задания
	 **/
	const onHomeWorkAdd = data => {
		if (!isSending) {
			setIsSending(true)

			const { name, description } = data

			const formData = new FormData()
			formData.append("name", name)
			formData.append("description", description)

			if (files.length) {
				files.forEach(file => {
					formData.append("document[]", file)
				})
			}

			questionList.forEach((item, index) => {
				formData.append(`questions[${index}][question]`, item.question)
				formData.append(`questions[${index}][type]`, item.type)
				formData.append(`questions[${index}][prompt]`, item.prompt)
			})

			Api.post(`/homework?${type}=${id}`, formData, {
				headers: { Authorization },
			})
				.then(res => {
					const { data, error } = res.data

					if (!error) {
						alert(data)
						setFiles([])
						history.goBack()
					} else {
						window.scrollTo(0, 0)
						setErrorObj(data)
					}
				})
				.finally(() => setIsSending(false))
		}
	}

	const onAddQuestion = e => {
		e.preventDefault()

		setQuestionList([...questionList, { question: "", type: 1, prompt: "" }])
	}

	const onRemoveQuestion = (e, qIndex) => {
		e.preventDefault()

		if (window.confirm("Удалить вопрос?")) {
			const data = questionList.filter((_, index) => index !== qIndex)

			setQuestionList(data)
		}
	}

	const onCheckboxChange = (e, qIndex) => {
		e.preventDefault()

		const data = questionList.map((question, index) => {
			if (index === qIndex) {
				if (question.type === 1) {
					return { ...question, type: 2 }
				} else {
					return { ...question, type: 1 }
				}
			}

			return question
		})

		setQuestionList(data)
	}

	const changeQuestionValue = (value, qIndex) => {
		const data = questionList.map((q, index) => {
			if (qIndex === index) {
				return { ...q, question: value }
			}

			return q
		})

		setQuestionList(data)
	}

	const changePromptValue = (value, qIndex) => {
		const data = questionList.map((q, index) => {
			if (qIndex === index) {
				return { ...q, prompt: value }
			}

			return q
		})

		setQuestionList(data)
	}

	/**
	 * Загрузка удаление файла в дропзоне
	 **/
	const onFileRemove = file => setFiles(files.filter(f => f.name !== file))
	const onFileUpload = fileList => setFiles(fileList)

	return (
		<>
			<div className="page-header">
				<div className="row ai-center jc-between">
					<div className="col-auto">
						<h6 className="title">Создать домашнее задание</h6>
					</div>

					<div className="col-auto">
						<button
							onClick={() => history.goBack()}
							className="btn btn-primary btn-lg">
							Назад
						</button>
					</div>
				</div>
			</div>

			<form className="form" onSubmit={handleSubmit(onHomeWorkAdd)}>
				<div className="row">
					<div className="col-12">
						<div className="field">
							<Input
								required
								label="Название"
								placeholder="Название"
								name="name"
								register={register}
								error={errorObj.name}
							/>
						</div>
					</div>

					<div className="col-12">
						<div className="field">
							<Textarea
								required
								label="Задание"
								placeholder="Задание"
								name="description"
								register={register}
								rows="8"
								error={errorObj.description}
							/>
						</div>
					</div>

					<div className="col-12 field">
						{questionList.map((question, qIndex) => {
							return (
								<div className="homework card mb-3" key={qIndex}>
									<div className="card-body">
										<div className="row mb-3 ai-center">
											<h5 className="col-sm">Вопрос {qIndex+1}</h5>
											<div className="col-sm-auto">
												<button
													onClick={e => onRemoveQuestion(e, qIndex)}
													className="btn btn-danger btn-xs">
													Удалить
												</button>
											</div>
										</div>

										<div className="row">
											<div className="col-xxl-6">
												<Input
													label="Вопрос"
													placeholder="Вопрос"
													value={question.question}
													onChange={e =>
														changeQuestionValue(e.target.value, qIndex)
													}
												/>
											</div>
											<div className="col-xxl-6">
												<Input
													label="Подсказка"
													placeholder="Подсказка"
													value={question.prompt}
													onChange={e =>
														changePromptValue(e.target.value, qIndex)
													}
												/>
											</div>

											<div className="col-12 mt-3">
												<label
													className="cursor-pointer d-flex ai-center"
													onClick={e => onCheckboxChange(e, qIndex)}>
													<input
														type="checkbox"
														className="input-checkbox mr-2"
														checked={question.type === 2}
														onChange={() => {}}
													/>
													Прикрепить файл
												</label>
											</div>
										</div>
									</div>
								</div>
							)
						})}

						<button
							onClick={onAddQuestion}
							className="btn btn-primary btn-block">
							Добавить вопрос
						</button>
					</div>

					<div className="col-12">
						<div className="field">
							<Dropzone
								maxSize={10000000}
								acceptTypes=""
								files={files}
								onFileRemove={onFileRemove}
								onFileUpload={onFileUpload}
							/>
						</div>
					</div>
				</div>

				<button type="submit" className="btn btn-primary btn-lg">
					Создать
				</button>
			</form>

			<Modal
				show={isSending}
				showClose={false}
				modalWrapperStyles={{ maxWidth: 300 }}>
				<div className="ta-center">
					<Spinner size={100} />
				</div>
			</Modal>
		</>
	)
}

HomeWorkCreate.propTypes = {
	location: PropType.object,
	history: PropType.object,
}

export default HomeWorkCreate
