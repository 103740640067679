/* eslint-disable react/prop-types */
import * as React from "react"

const SvgComponent = ({ size, color, ...props }) => (
	<svg viewBox="0 0 511.999 511.999" width={size} height={size} {...props}>
		<path
			fill={color}
			d="M483.694 50.624c-18.473-18.679-40.704-28.526-64.357-28.526-.368 0-.737.002-1.106.007-22.542.292-44.66 9.872-62.278 26.973l-.166.161-228.705 232.522c-22.883 23.123-22.889 56.695-.014 79.825 11.093 11.216 25.244 17.392 39.848 17.392h.007c14.781-.002 29.025-6.178 40.177-17.459l158.073-161.49c7.724-7.891 7.589-20.549-.303-28.274-7.89-7.724-20.55-7.589-28.274.302l-158.004 161.42c-3.515 3.555-7.66 5.513-11.676 5.514-3.951 0-7.793-1.858-11.419-5.523-10.525-10.642-3.502-20.035.049-23.623L383.958 77.62c21.828-21.058 49.796-20.625 71.305 1.123 25.497 25.78 18.557 55.864.37 74.244-7.767 7.848-7.7 20.507.147 28.275 7.851 7.768 20.509 7.699 28.275-.148 17.548-17.732 27.467-40.276 27.928-63.475.483-24.282-9.564-48.081-28.289-67.015z"
		/>
		<path
			fill={color}
			d="M420.9 217.665c-7.931-7.68-20.59-7.477-28.271.456L195.287 421.935c-17.865 18.04-40.468 27.976-63.656 27.979h-.012c-23.18 0-45.172-9.846-63.594-28.472-43.121-43.599-31.082-94.804-.012-126.229l197.04-196.996c7.809-7.807 7.81-20.466.003-28.275-7.806-7.809-20.465-7.81-28.275-.003L39.703 266.973l-.075.076C14.914 292.023.856 323.513.039 355.72c-.871 34.344 12.808 66.794 39.556 93.84 25.729 26.015 58.409 40.341 92.024 40.341h.018c33.984-.005 66.713-14.184 92.157-39.925l197.562-204.04c7.681-7.933 7.477-20.591-.456-28.271z"
		/>
	</svg>
)

export default SvgComponent
