import React, { useContext, useRef } from "react"
import { Link } from "react-router-dom"
import { AppContext } from "../../context/AppContext"
import useOnClickOutside from "../../hooks/useOnClickOutside"
import { IconChevron, IconLogout, IconProfile } from "../../static/icons"
import Logo from "../../static/imgs/Logo"
import "./Header.scss"

const Header = () => {
	const { state, reset } = useContext(AppContext)

	const profileRef = useRef()

	useOnClickOutside(profileRef, () => {
		profileRef.current.classList.remove("dropdown-visible")
	})

	const dropdownToggle = () => {
		profileRef.current.classList.toggle("dropdown-visible")
	}

	const onLogout = () => {
		reset()
	}

	return (
		<header className="d-flex jc-between">
			<Link to="/" className="logo d-flex ai-center">
				<Logo/>
			</Link>

			<div className="right-side d-flex">
				<div ref={profileRef} className="header-dropdown-wrap" onClick={dropdownToggle}>
					<button id="header-profile-btn" className="dropdown-btn">
						<img src={state.user.avatarThumb} alt="" className="avatar pos-abs-y image-cover"/>
						<span>{state.user.first_name}</span>
						<IconChevron size={10} color="#000" className="chevron pos-abs-y" />
					</button>

					<div id="header-profile-dropdown" className="dropdown-wrap">
						<Link to="/profile" className="dropdown-link">
							<IconProfile size={14} className="pos-abs-y" /> Профиль
						</Link>

						<div className="devider-line"></div>

						<div className="dropdown-link _logout" onClick={onLogout}>
							<IconLogout size={14} className="pos-abs-y" /> Выход
						</div>
					</div>
				</div>
			</div>
		</header>
	)
}

export default Header
