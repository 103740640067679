/* eslint-disable indent */
import PropTypes from "prop-types"
import React from "react"
import { IconDocs } from "../../static/icons"
import "./Message.scss"

const Message = ({ data, userId, download, onMoveToHomework }) => {
	const { homeworkStatus, type, text, date } = data

	if (type === "form") {
		return (
			<div
				className={`msg-wrap cursor-pointer _homework ${
					homeworkStatus === "formReject"
						? "_reject"
						: homeworkStatus === "formDone"
						? "_success"
						: ""
				}`}
				onClick={() => onMoveToHomework()}
			>
				<div className="text-wrap pos">{text}</div>

				<div className="time">{date}</div>
			</div>
		)
	}

	return (
		<div className={`msg-wrap ${data.user_id === userId ? "_me" : ""}`}>
			<div className="text-wrap pos">
				{data.type === "text" && data.text}

				{data.type === "image" &&
				data.documents.map((i, index) => (
					<img key={index} className="image-cover doc-img mt-2" src={i.thumb} alt=""/>
				))}

				{data.type === "file" &&
				data.documents.map((i, index) => (
					<div
						key={index}
						className="d-flex ai-center cursor-pointer"
						onClick={() => download(i.url, `${i.fileName}.${i.extension}`)}
					>
						<IconDocs size={20} className="mr-2"/>
						<span>скачать файл</span>
					</div>
				))}
			</div>

			<div className="time">{data.date}</div>
		</div>
	)
}
Message.propTypes = {
	data: PropTypes.object,
	userId: PropTypes.number,
	download: PropTypes.func,
	onMoveToHomework: PropTypes.func
}

export default Message
