import PropTypes from "prop-types"
import React, { useState } from "react"

const initialState = {
	user: {},
	authToken: null,
	roleList: {},
	unreadMessages: {
		support: null,
		homework: null
	}
}

export const AppContext = React.createContext()

export const AppProvider = ({ children }) => {
	const [state, setState] = useState(initialState)

	const reset = () => {
		localStorage.clear()
		setState(initialState)
	}

	const login = (user, authToken, roleList = {}) => {
		localStorage.setItem("authToken", authToken)
		localStorage.setItem("user", JSON.stringify(user))

		setState({ ...state, user, authToken, roleList })
	}

	const setRoleList = roleList => {
		setState({ ...state, roleList })
	}

	const setUnreadMessages = unreadMessages => {
		setState({ ...state, unreadMessages })
	}

	return (
		<AppContext.Provider
			value={{ state, reset, login, setRoleList, setUnreadMessages }}>
			{children}
		</AppContext.Provider>
	)
}
AppProvider.propTypes = {
	children: PropTypes.any
}
