import { IconBook, IconEducation, IconFaq, IconHome, IconSupport, IconUsers } from "../static/icons"

export const LEFT_MENU_LIST = [
	{
		blockTitle: "Основное",
		menu: [
			{
				key: "dashboard",
				title: "Главная",
				path: "/",
				icon: IconHome,
				pathMatch: [""]
			},
			{
				key: "user",
				title: "Пользователи",
				path: "/user/list",
				icon: IconUsers,
				pathMatch: ["user"]
			},
			{
				key: "section-faq",
				title: "FAQ",
				path: "/faq/section/list",
				icon: IconFaq,
				pathMatch: ["faq"]
			},
			{
				key: "support",
				title: "Тех. поддержка",
				path: "/support",
				icon: IconSupport,
				pathMatch: ["support"]
			}
		]
	},
	{
		blockTitle: "Задания",
		menu: [
			{
				key: "graduate-work",
				title: "Дипломные работы",
				path: "/graduate-work",
				icon: IconBook,
				pathMatch: ["graduate-work"]
			},
			{
				key: "course-work",
				title: "Курсовые работы",
				path: "/course-work",
				icon: IconBook,
				pathMatch: ["course-work"]
			},
			{
				key: "homework",
				title: "Домашние работы",
				path: "/homeworks",
				icon: IconBook,
				pathMatch: ["homeworks"]
			}
		]
	},
	{
		blockTitle: "Обучение",
		menu: [
			{
				key: "direction",
				title: "Профессии",
				path: "/direction/list",
				icon: IconEducation,
				pathMatch: ["direction"]
			},
			{
				key: "course",
				title: "Курсы",
				path: "/course/list",
				icon: IconEducation,
				pathMatch: ["course"]
			},
			{
				key: "module",
				title: "Модули",
				path: "/module/list",
				icon: IconEducation,
				pathMatch: ["module"]
			},
			{
				key: "lesson",
				title: "Уроки",
				path: "/lesson/list",
				icon: IconEducation,
				pathMatch: ["lesson", "lesson-version"]
			},
			{
				key: "question",
				title: "Частые вопросы",
				path: "/questions",
				icon: IconFaq,
				pathMatch: ["questions"]
			}
		]
	}
]
