import PropType from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import Api from "../../../../../api"
import { Dropzone, Input, Select, Textarea } from "../../../../../components"
import { Modal, Spinner } from "../../../../../components/index"
import { AppContext } from "../../../../../context/AppContext"

const ModuleEdit = ({ location, history }) => {
	const { module } = location.state
	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const [isSending, setIsSending] = useState(false)
	const [errorObj, setErrorObj] = useState({})
	const [bannerFiles, setBannerFiles] = useState([])
	const [videoFiles, setVideoFiles] = useState([])
	const [directionId] = useState(module.direction_id)
	const [courseId, setCourseId] = useState(module.course_id)
	const [courseList, setCourseList] = useState([])

	console.log("module", module)

	const [downloadPercent, setDownloadPercent] = useState(0)

	const { register, handleSubmit } = useForm({
		defaultValues: {
			name: module.name || "",
			price: module.price || "",
			old_price: module.old_price || "",
			description: module.description || "",
		},
	})

	const changeCourseIdHandle = e => {
		setCourseId(e.target.value)
	}

	const getCourseList = () => {
		Api.get(`/course/list/${directionId}`, { headers: { Authorization } }).then(
			res => {
				const { data, error } = res.data

				if (!error) {
					setCourseList(data)
				} else {
					console.error(data)
				}
			}
		)
	}

	const onModuleEdit = data => {
		if (!isSending) {
			setIsSending(true)

			const { name, description, price, old_price } = data

			let errObj = {}

			if (price < 0) errObj = { ...errObj, price: "Некорректная сумма" }
			if (old_price && +old_price < +price)
				errObj = { ...errObj, old_price: "Старая цена должна быть больше" }

			setErrorObj(errObj)

			if (Object.keys(errObj).length === 0) {
				const formData = new FormData()
				formData.append("name", name)
				formData.append("description", description)
				formData.append("price", price)
				formData.append("old_price", old_price)
				formData.append("course_id", courseId)

				if (bannerFiles.length > 0) formData.append("banner", bannerFiles[0])
				if (videoFiles.length > 0) formData.append("video", bannerFiles[0])

				Api.post(`/module/update/${module.id}`, formData, {
					headers: { Authorization },
					onUploadProgress: progressEvent => {
						const percentCompleted = Math.round(
							(progressEvent.loaded * 100) / progressEvent.total
						)
						setDownloadPercent(percentCompleted)
					},
				})
					.then(res => {
						const { data, error } = res.data

						if (!error) {
							alert(data)
							setBannerFiles([])
							setVideoFiles([])
							history.goBack()
						} else {
							window.scrollTo(0, 0)
							setErrorObj(data)
						}
					})
					.finally(() => setDownloadPercent(0))
			}
			setIsSending(false)
		}
	}

	const togglePublic = () => {
		let value
		if (module.isPublic == true) {
			value = false
		} else value = true

		console.log(module.isPublic)

		Api.post(
			`/publish/index?module_id=${module.id}`,
			{ public: value },
			{ headers: { Authorization } }
		).then(res => {
			const { data, error } = res.data

			if (!error) {
				history.goBack()
				alert("Настройки публикации изменены")
			} else {
				console.error(data)
				alert("Ошибка")
			}
		})
	}

	const onFileBannerRemove = fileName => {
		setBannerFiles(bannerFiles.filter(f => f.name !== fileName))
	}
	const onFileBannerUpload = filesData => setBannerFiles(filesData)

	const onFileVideoRemove = fileName => {
		setVideoFiles(videoFiles.filter(f => f.name !== fileName))
	}

	const onFileVideoUpload = filesData => setVideoFiles(filesData)

	useEffect(() => {
		getCourseList()
	}, [])

	return (
		<>
			<div className="page-header">
				<div className="row ai-center jc-between">
					<div className="col-md-auto">
						<h6 className="title">Редактирование модуль</h6>
					</div>

					<div className="col-md-auto">
						<button
							onClick={() => history.goBack()}
							className="btn btn-primary btn-lg">
							Назад
						</button>
					</div>
				</div>
			</div>

			<form className="form" onSubmit={handleSubmit(onModuleEdit)}>
				<div className="row">
					<div className="col-md-5 col-xl-4 col-xxl-3">
						<div className="card field">
							<div className="card-body">
								<img src={module.banner} alt="" className="card-img _block" />
							</div>
						</div>
					</div>

					<div className="col-md-7 col-xl-8 col-xxl-9">
						<div className="row">
							<div className="col-sm-6">
								<div className="field">
									<Select
										label="Курс"
										disabled={courseList === 0}
										items={courseList}
										onChange={changeCourseIdHandle}
										value={courseId}
									/>
								</div>
							</div>

							<div className="col-sm-6">
								<div className="field">
									<Input
										required
										label="Название"
										placeholder="Название"
										name="name"
										register={register}
										error={errorObj.name}
									/>
								</div>
							</div>

							<div className="col-sm-6">
								<div className="field">
									<Input
										required
										label="Цена"
										placeholder="Цена"
										name="price"
										register={register}
										error={errorObj.price}
									/>
								</div>
							</div>

							<div className="col-sm-6">
								<div className="field">
									<Input
										label="Старая цена"
										placeholder="Старая цена"
										name="old_price"
										register={register}
										error={errorObj.old_price}
									/>
								</div>
							</div>

							<div className="col-12">
								<div className="field">
									<Textarea
										required
										label="Описание"
										placeholder="Описание"
										name="description"
										register={register}
										rows="9"
										error={errorObj.description}
									/>
								</div>
							</div>
							<div className="col-12">
								<div className="field">
									<label className="d-flex ai-center cursor-pointer mt-2">
										<input
											type="checkbox"
											onChange={() => togglePublic()}
											checked={module.isPublic}
											className="input-checkbox mr-2"
											ref={register}
											name="public"
										/>
										Опубликовать
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-6">
						<div className="field">
							<div className="h6 mb-2">Загрузка баннера</div>
							<Dropzone
								multiple={false}
								maxSize={5000000}
								acceptTypes="image/png, image/jpeg, image/gif"
								files={bannerFiles}
								onFileRemove={onFileBannerRemove}
								onFileUpload={onFileBannerUpload}
							/>
						</div>
					</div>

					<div className="col-6">
						<div className="field">
							<div className="h6 mb-2">Загрузка видео</div>
							<Dropzone
								multiple={false}
								maxSize={2000000000}
								acceptTypes="video/*"
								files={videoFiles}
								onFileRemove={onFileVideoRemove}
								onFileUpload={onFileVideoUpload}
							/>
						</div>
					</div>
				</div>

				<button type="submit" className="btn btn-primary btn-lg">
					Отредактировать
				</button>

				<Modal
					show={isSending}
					showClose={false}
					modalWrapperStyles={{ maxWidth: 300 }}>
					<div className="ta-center">
						<Spinner size={100} />
						<div className="font-700 mt-2">{parseInt(downloadPercent)}%</div>
					</div>
				</Modal>
			</form>
		</>
	)
}

ModuleEdit.propTypes = {
	location: PropType.object,
	history: PropType.object,
}

export default ModuleEdit
