import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Api from "../../../../../api"
import { Spinner } from "../../../../../components"
import { AppContext } from "../../../../../context/AppContext"
import { IconEdit, IconTrash, IconWatch } from "../../../../../static/icons"
import "./FaqQuestionList.scss"

const FaqQuestionList = ({ match, location, history }) => {
	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const [faqList, setFaqList] = useState([])
	const [isLoading, setIsLoading] = useState(true)

	const getFaqListQuestions = () => {
		Api.get(`/faq/list?section=${match.params.id}`, {
			headers: { Authorization },
		}).then(res => {
			const { error, data } = res.data

			if (!error) {
				setFaqList(data)
			} else {
				console.error(data)
				alert("Ошибка")
			}

			setIsLoading(false)
		})
	}

	const removeQuestionList = id => {
		if (window.confirm("Удалить вопрос")) {
			Api.delete(`/faq/${id}`, { headers: { Authorization } }).then(res => {
				const { error, data } = res.data
				if (!error) {
					setFaqList(faqList.filter(i => i.id !== id))
				} else {
					alert(data)
					console.error(data)
				}
			})
		}
	}

	useEffect(() => {
		getFaqListQuestions()
	}, [])

	return (
		<div className="page-faq-list">
			<div className="page-header">
				<div className="row ai-center jc-between">
					<div className="col-md-auto">
						<h6 className="title">
							Раздел {`${location.state.faqDirectionName}`}
						</h6>
					</div>

					<div className="col-md-auto">
						<div className="row row-sm">
							<div className="col-auto">
								<button
									onClick={() => history.goBack()}
									className="btn btn-primary">
									Назад
								</button>
							</div>

							<div className="col-auto">
								<Link to="/faq/question/create" className="btn btn-primary">
									Добавить вопрос
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>

			{!isLoading ? (
				<div className="card">
					<div className="card-body">
						<table>
							<thead>
								<tr>
									<th>Вопрос</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{faqList.map(item => (
									<tr key={item.id}>
										<td>{item.question}</td>
										<td className="actions">
											<div className="row row-xs">
												<div className="col-auto">
													<Link
														to={{
															pathname: `/faq/question/view/${item.id}`,
															state: {
																faq: item,
																sectionId: match.params.id,
															},
														}}
														className="btn btn-sm btn-primary">
														<IconWatch size={14} color="#fff" />
													</Link>
												</div>

												<div className="col-auto">
													<Link
														to={{
															pathname: `/faq/question/edit/${item.id}`,
															state: {
																faq: item,
																sectionId: match.params.id,
															},
														}}
														className="btn btn-sm btn-primary">
														<IconEdit size={14} color="#fff" />
													</Link>
												</div>

												<div className="col-auto">
													<button
														className="btn btn-sm btn-danger"
														onClick={() => removeQuestionList(item.id)}>
														<IconTrash size={14} color="#fff" />
													</button>
												</div>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			) : (
				<div className="col d-flex jc-center">
					<Spinner size={100} styles={{ marginTop: 100 }} />
				</div>
			)}
		</div>
	)
}
FaqQuestionList.propTypes = {
	match: PropTypes.any,
	location: PropTypes.any,
	history: PropTypes.any,
}

export default FaqQuestionList
