/* eslint-disable no-unused-vars */
import PropType from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Api from "../../../../../api"
import { Input, Textarea } from "../../../../../components"
import HomeworkListTable from "../../../../../components/HomeworkListTable/HomeworkListTable"
import { Select, Spinner } from "../../../../../components/index"
import { AppContext } from "../../../../../context/AppContext"

const DirectionView = ({ match }) => {
	const { id: directionId } = match.params

	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const [direction, setDirection] = useState(null)
	const [userList, setUserList] = useState([])
	const [curatorId, setCuratorId] = useState(null)
	const [homeworkList, setHomeworkList] = useState([])

	console.log("drtrr", direction)


	const getCourseData = () => {
		Api.get(`/direction/${directionId}`, { headers: { Authorization } }).then(
			res => {
				const { data, error } = res.data

				if (!error) {
					setDirection(data)
				} else {
					alert(data)
				}
			}
		)
	}

	// получение домашек
	const getHomeworkList = () => {
		Api.get(`/homework/list?direction_id=${directionId}`, {
			headers: { Authorization }
		}).then(res => {
			const { data, error } = res.data

			if (!error) {
				if (data.length) {
					setHomeworkList(data)
				}
			} else {
				console.error(data)
				alert("Ошибка")
			}
		})
	}

	const getCuratorList = () => {
		Api.get("/user/index-role?role=curator", {
			headers: { Authorization }
		}).then(res => {
			const { error, data } = res.data

			if (!error) {
				const list = data.map(user => ({
					id: user.user_id,
					name: `${user.user_id} - ${user.last_name} ${user.first_name}`
				}))

				setUserList([{ id: 0, name: "Выберите пользователя" }, ...list])
			} else {
				alert("Ошибка")
				console.error(data)
			}
		})
	}

	const onCuratorChange = e => {
		const value = +e.target.value

		Api.post(
			`/direction/bind-curator/${directionId}`,
			{ curator: value },
			{ headers: { Authorization } }
		).then(res => {
			const { data, error } = res.data

			if (!error) {
				setCuratorId(value)
			} else {
				console.error(data)
				alert("Ошибка")
			}
		})
	}

	/**
	 * Удаление домашних заданий
	 **/
	const removeHomework = id => {
		if (window.confirm("Удалить работу?")) {
			Api.delete(`/homework/${id}`, {
				headers: { Authorization }
			}).then(res => {
				const { data, error } = res.data

				if (!error) {
					setHomeworkList(homeworkList.filter(i => i.id !== id))
					alert(data)
				} else {
					alert(data)
					console.error(data)
				}
			})
		}
	}

	useEffect(() => {
		getCourseData()
		getCuratorList()
		getHomeworkList()
	}, [])

	useEffect(() => {
		if (direction) {
			setCuratorId(direction.curator?.id)
		}
	}, [direction])

	if (!direction) {
		return (
			<div className="d-flex ai-center jc-center flex-fill">
				<Spinner/>
			</div>
		)
	}

	return (
		<>
			<div className="page-header">
				<h6 className="title">Редактирование профессии</h6>
			</div>

			<div className="form">
				<div className="row">
					<div className="col-md-5 col-xl-4 col-xxl-3">
						<div className="card field">
							<div className="card-body">
								<img
									src={direction.banner}
									alt=""
									className="card-img _block"
								/>
							</div>
						</div>
					</div>

					<div className="col-md-7 col-xl-8 col-xxl-9">
						<div className="row">
							<div className="col-12">
								<div className="field">
									<Select
										label="Куратор направления"
										items={userList}
										value={curatorId || 0}
										onChange={e => onCuratorChange(e)}
									/>
								</div>
							</div>

							<div className="col-12">
								<div className="field">
									<Input
										disabled
										defaultValue={direction.name}
										label="Название"
										placeholder="Название"
										name="name"
									/>
								</div>
							</div>

							<div className="col-sm-6">
								<div className="field">
									<Input
										disabled
										defaultValue={direction.price}
										label="Цена"
										placeholder="Цена"
										name="price"
									/>
								</div>
							</div>

							<div className="col-sm-6">
								<div className="field">
									<Input
										disabled
										defaultValue={direction.old_price}
										label="Старая цена"
										placeholder="Старая цена"
										name="old_price"
									/>
								</div>
							</div>

							<div className="col-12">
								<div className="field">
									<Textarea
										disabled
										defaultValue={direction.description}
										label="Описание"
										placeholder="Описание"
										name="description"
										rows="9"
									/>
								</div>
							</div>
							<div className="col-12">
								<div className="field">
									<label className="d-flex ai-center cursor-pointer mt-2">
										<input
											type="checkbox"
											readOnly
											checked={direction.isPublic}
											className="input-checkbox mr-2"
										/>
										Публикация
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="mt-5 page-header">
				<div className="row ai-center jc-between">
					<div className="col-md-auto">
						<h6 className="title">Дипломные работы</h6>
					</div>

					{!homeworkList.length && (
						<div className="col-md-auto">
							<Link
								to={{
									pathname: "/homework/create",
									state: {
										id: directionId,
										type: "direction_id"
									}
								}}
								className="btn btn-primary">
								Добавить домашнее задание
							</Link>
						</div>
					)}
				</div>
			</div>
			<div className="card">
				<div className="card-body">
					<HomeworkListTable data={homeworkList} onRemove={removeHomework}/>
				</div>
			</div>
		</>
	)
}
DirectionView.propTypes = {
	match: PropType.object
}

export default DirectionView
