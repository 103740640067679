/* eslint-disable react/prop-types */
import React from "react"
import CloseImg from "../../static/imgs/icons/close-modal-icon.svg"
import Backdrop from "../Backdrop/Backdrop"
import "./Modal.scss"

const Modal = ({
	show,
	onClose = () => {},
	showClose = true,
	modalWrapperStyles = {},
	children,
}) => {
	if (!show) {
		return null
	}

	return (
		<div className="modal scroll-y">
			<Backdrop show={true} onClose={onClose} />

			<div className="wrapper" style={modalWrapperStyles}>
				{showClose && (
					<div onClick={onClose} className="js-close-modal-icon">
						<img src={CloseImg} alt="" className="image-contain" />
					</div>
				)}

				{children}
			</div>
		</div>
	)
}

export default Modal
