import React from "react"
import { Redirect, Route } from "react-router"
import AuthPage from "./Login/Login"

// import RegistrationPage from "./Registration/Registration"

const AuthFlow = () => (
	<>
		{/* <Route path="/reg" component={RegistrationPage} /> */}
		<Route path="/" exact component={AuthPage}/>

		<Redirect to="/"/>
	</>
)

export default AuthFlow
