import PropType from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import Api from "../../../../../api"
import { Input, Select, Textarea } from "../../../../../components"
import { Modal, Spinner } from "../../../../../components/index"
import { AppContext } from "../../../../../context/AppContext"

const LessonCreate = ({ history }) => {
	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const { handleSubmit, register } = useForm()

	const [isSending, setIsSending] = useState(false)
	const [errorObj, setErrorObj] = useState({})
	const [moduleId, setModuleId] = useState(null)

	const [directionList, setDirectionList] = useState([])
	const [courseList, setCourseList] = useState([])
	const [moduleList, setModuleList] = useState([])

	const getDirectionIdHandle = e => {
		getCourseList(e.target.value)
	}
	const getCourseIdHandle = e => {
		getModuleList(e.target.value)
	}
	const getModuleIdHandle = e => {
		setModuleId(e.target.value)
	}

	const onLessonAdd = data => {
		if (!isSending) {
			setIsSending(true)

			Api.post(`/lesson/${moduleId}`, data, {
				headers: { Authorization }
			}).then(res => {
				const { data, error } = res.data

				if (!error) {
					setErrorObj({})
					alert(data)
					history.goBack()
				} else {
					console.error(data)
					alert("Ошибка")
				}
			})
		}

		setIsSending(false)
	}

	const getDirectionList = () => {
		Api.get("/direction/list?sort=name", { headers: { Authorization } }).then(
			res => {
				const { data, error } = res.data

				if (!error) {
					if (data.length) {
						setDirectionList(data)
						getCourseList(data[0].id)
					}
				} else {
					console.error(data)
				}
			}
		)
	}

	const getCourseList = directionId => {
		setCourseList([])

		Api.get(`/course/list/${directionId}`, { headers: { Authorization } }).then(
			res => {
				const { data, error } = res.data

				if (!error) {
					if (data.length) {
						setCourseList(data)
						getModuleList(data[0].id)
					}
				} else {
					console.error(data)
				}
			}
		)
	}

	const getModuleList = courseId => {
		setModuleList([])

		Api.get(`/module/list/${courseId}`, { headers: { Authorization } }).then(
			res => {
				const { data, error } = res.data

				if (!error) {
					if (data.length) {
						setModuleList(data)
						setModuleId(data[0].id)
					}
				} else {
					console.error(data)
				}
			}
		)
	}

	useEffect(() => {
		getDirectionList()
	}, [])

	return (
		<>
			<div className="page-header">
				<h6 className="title">Создать урок</h6>
			</div>

			<form className="form" onSubmit={handleSubmit(onLessonAdd)}>
				<div className="row">
					<div className="col-md-4">
						<div className="field">
							<Select
								label="Профессия"
								items={directionList}
								onChange={getDirectionIdHandle}
							/>
						</div>
					</div>

					<div className="col-md-4">
						<div className="field">
							<Select
								label="Курс"
								items={courseList}
								onChange={getCourseIdHandle}
							/>
						</div>
					</div>

					<div className="col-md-4">
						<div className="field">
							<Select
								label="Модуль"
								items={moduleList}
								onChange={getModuleIdHandle}
							/>
						</div>
					</div>

					<div className="col-12">
						<div className="field">
							<Input
								required
								label="Название"
								placeholder="Название"
								name="name"
								register={register}
								error={errorObj.name}
							/>
						</div>
					</div>

					<div className="col-12">
						<div className="field">
							<Textarea
								required
								label="Описание"
								placeholder="Описание"
								name="description"
								register={register}
								rows="8"
								error={errorObj.description}
							/>
						</div>
					</div>
				</div>

				<button type="submit" className="btn btn-primary btn-lg">
					Создать
				</button>

				<Modal
					show={isSending}
					showClose={false}
					modalWrapperStyles={{ maxWidth: 300 }}>
					<div className="ta-center">
						<Spinner size={100}/>
					</div>
				</Modal>
			</form>
		</>
	)
}

LessonCreate.propTypes = {
	history: PropType.object
}

export default LessonCreate
