import PropTypes from "prop-types"
import React, { memo } from "react"

const Textarea = ({ label, register, error, ...textAreaProps }) => {
	let classArr = "input"
	if (error) {
		classArr = `${classArr} _error`
	}

	return (
		<label
			className={`label ${textAreaProps.disabled ? "cursor-default" : ""}`}>
			<span>{label}</span>

			<textarea ref={register} className={classArr} {...textAreaProps} />

			<div className="error">{error || ""}</div>
		</label>
	)
}
Textarea.propTypes = {
	label: PropTypes.any,
	register: PropTypes.any,
	error: PropTypes.any
}

export default memo(Textarea)
