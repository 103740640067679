import PropTypes from "prop-types"
import React, { memo } from "react"

const Select = ({
	register = null,
	label,
	items = [],
	error,
	value,
	disabled = false,
	...selectProps
}) => {
	let classArr = "input _select"
	if (error) {
		classArr = `${classArr} _error`
	}

	return (
		<label className="label">
			<span className={disabled ? "cursor-default" : ""}>{label}</span>

			<select
				ref={register}
				disabled={disabled}
				className={classArr}
				value={value}
				{...selectProps}>
				{items.length > 0 ? (
					items.map(i => (
						<option key={i.id} value={i.id}>
							{i.name}
						</option>
					))
				) : (
					<option value="0">Нет записей</option>
				)}
			</select>

			<div className="error">{error || ""}</div>
		</label>
	)
}
Select.propTypes = {
	register: PropTypes.any,
	label: PropTypes.any,
	items: PropTypes.any,
	error: PropTypes.any,
	value: PropTypes.any,
	disabled: PropTypes.any,
}

export default memo(Select)
