/* eslint-disable no-unused-vars */
import PropTypes from "prop-types"
import React from "react"
import "./Pagination.scss"

const Pagination = ({ currentPage, totalPages, onChangePage }) => {
	if (totalPages <= 1) {
		return null
	}

	const onClick = page => {
		onChangePage(page)
	}

	return (
		<div className="d-flex jc-center">
			<div className="pagination d-inline-flex mt-4">
				{Array.from({ length: totalPages }).map((_, index) => {
					const page = index + 1

					return (
						<span
							key={index + ""}
							className={`link ${currentPage === page ? "_active" : ""}`}
							onClick={() => onClick(page)}>
							{page}
						</span>
					)
				})}
			</div>
		</div>
	)
}
Pagination.propTypes = {
	currentPage: PropTypes.number.isRequired,
	totalPages: PropTypes.number.isRequired,
	onChangePage: PropTypes.func.isRequired
}

export default Pagination
