/* eslint-disable react/prop-types */
import * as React from "react"

const SvgComponent = ({ size, color, ...props }) => (
	<svg width={size} height={size} viewBox="0 0 512 512" fill={color} {...props}>
		<path d="M288.96 227L241 258.97v-63.94z"/>
		<path
			d="M436 90H91c-10.92 0-21.17-2.93-30-8.05V467c0 24.81 20.19 45 45 45h330c8.28 0 15-6.72 15-15V105c0-8.28-6.72-15-15-15zm-225 77c0-11.962 13.363-19.125 23.32-12.48l90 60c8.891 5.927 8.906 19.023 0 24.96l-90 60c-9.926 6.604-23.32-.463-23.32-12.48zm165 225H238.42c-6.18 17.46-22.87 30-42.42 30s-36.24-12.54-42.42-30H136c-8.28 0-15-6.72-15-15s6.72-15 15-15h17.58c6.18-17.46 22.87-30 42.42-30s36.24 12.54 42.42 30H376c8.28 0 15 6.72 15 15s-6.72 15-15 15zm-180-30c-8.27 0-15 6.73-15 15s6.73 15 15 15 15-6.73 15-15-6.73-15-15-15z"/>
		<g>
			<path d="M436 0H91C74.458 0 61 13.458 61 30s13.458 30 30 30h330V30h15c8.284 0 15-6.716 15-15s-6.716-15-15-15z"/>
		</g>
	</svg>
)

export default SvgComponent
