import React, { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Api from "../../../../../api"
import { Select, Spinner } from "../../../../../components"
import { AppContext } from "../../../../../context/AppContext"
import { IconTrash, IconWatch } from "../../../../../static/icons"
import defaultAvatar from "../../../../../static/imgs/users/default_avatar.svg"
import {
	addUrlParam,
	clearUrlParams,
	getUrlParams,
	removeUrlParam,
} from "../../../../../utils/urlManipulation"
import "./EducationQuestionsList.scss"

const PUBLIC_TYPES = ["Не опубликован", "Опубликован"]
const STATUS_TYPES = ["Не прочитано", "Прочитано"]

const EducationQuestions = () => {
	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const questionRole = state.roleList["question"]

	const URL_PARAMS = getUrlParams()
	const directionParamId = URL_PARAMS["direction"]
	const courseParamId = URL_PARAMS["course"]
	const moduleParamId = URL_PARAMS["module"]
	const lessonParamId = URL_PARAMS["lesson"]

	const [questionList, setQuestionList] = useState([])
	const [isLoading, setIsLoading] = useState(true)
	const [eduList, setEduList] = useState({
		directionList: [],
		courseList: [],
		moduleList: [],
		lessonList: [],
	})
	const [eduIds, setEduIds] = useState({
		directionId: null,
		courseId: null,
		moduleId: null,
		lessonId: null,
	})

	const changeDirectionIdHandle = e => {
		const value = +e.target.value

		clearUrlParams()
		addUrlParam("direction", value)
		setEduIds({ ...eduIds, directionId: value })
	}
	const changeCourseIdHandle = e => {
		const value = +e.target.value

		removeUrlParam(["module", "lesson"])
		addUrlParam("course", value)
		setEduIds({ ...eduIds, courseId: value })
	}
	const changeModuleIdHandle = e => {
		const value = +e.target.value

		removeUrlParam(["lesson"])
		addUrlParam("module", value)
		setEduIds({ ...eduIds, moduleId: value })
	}
	const changeLessonIdHandle = e => {
		const value = +e.target.value

		addUrlParam("lesson", value)
		setEduIds({ ...eduIds, lessonId: value })
	}

	const getDirectionList = () => {
		setIsLoading(true)

		Api.get("/direction/list?sort=name", { headers: { Authorization } }).then(
			res => {
				const { data, error } = res.data

				if (!error) {
					setEduList({ ...eduList, directionList: data })

					if (data.length) {
						setEduIds({
							...eduIds,
							directionId: directionParamId || data[0].id,
						})
					}
				} else {
					console.error(data)
					alert("Ошибка")
				}
			}
		)
	}

	const getCourseList = () => {
		if (eduIds.directionId) {
			setIsLoading(true)

			Api.get(`/course/list/${eduIds.directionId}`, {
				headers: { Authorization },
			}).then(res => {
				const { data, error } = res.data

				if (!error) {
					setEduList({ ...eduList, courseList: data })

					if (data.length) {
						setEduIds({ ...eduIds, courseId: courseParamId || data[0].id })
					}
				} else {
					console.error(data)
					alert("Ошибка")
				}
			})
		}
	}

	const getModuleList = () => {
		if (eduIds.courseId) {
			setIsLoading(true)

			Api.get(`/module/list/${eduIds.courseId}`, {
				headers: { Authorization },
			}).then(res => {
				const { data, error } = res.data

				if (!error) {
					setEduList({ ...eduList, moduleList: data })

					if (data.length) {
						setEduIds({ ...eduIds, moduleId: moduleParamId || data[0].id })
					} else {
						setEduIds({ ...eduIds, moduleId: null })
						setIsLoading(false)
					}
				} else {
					console.error(data)
					alert("Ошибка")
				}
			})
		}
	}

	const getLessonList = () => {
		if (eduIds.moduleId) {
			setIsLoading(true)

			Api.get(`/lesson/list/${eduIds.moduleId}`, {
				headers: { Authorization },
			}).then(res => {
				const { data, error } = res.data

				if (!error) {
					if (data.length) {
						setEduIds({ ...eduIds, lessonId: lessonParamId || data[0].id })
						setEduList({ ...eduList, lessonList: data })
					} else {
						setEduIds({ ...eduIds, lessonId: null })
						setEduList({ ...eduList, lessonList: [] })
						setIsLoading(false)
					}
				} else {
					console.error(data)
					alert("Ошибка")
				}
			})
		}

		setIsLoading(false)
	}

	const getQuestions = () => {
		Api.get(`/question/list?lesson_id=${eduIds.lessonId}`, {
			headers: { Authorization },
		}).then(res => {
			const { data, error } = res.data

			if (!error) {
				setQuestionList(data)
			} else {
				console.error(data)
				alert("Ошибка")
			}
		})
	}

	const onRemoveQuestion = questionId => {
		Api.delete(`/question/delete/${questionId}`, {
			headers: { Authorization },
		}).then(res => {
			const { data, error } = res.data

			if (!error) {
				alert("Вопрос удален")
				getQuestions()
			} else {
				console.error(data)
				alert("Ошибка")
			}
		})
	}

	useEffect(() => {
		getDirectionList()
	}, [])

	useEffect(() => {
		getCourseList()
	}, [eduIds.directionId])

	useEffect(() => {
		getModuleList()
	}, [eduIds.courseId])

	useEffect(() => {
		getLessonList()
	}, [eduIds.moduleId])

	useEffect(() => {
		getQuestions()
	}, [eduIds.lessonId])

	return (
		<div className="page-questions">
			<div className="page-header">
				<h6 className="title">Частые вопросы</h6>
			</div>

			<div className="form row">
				<div className="col-md-3 field">
					<Select
						label="Профессия"
						items={eduList.directionList}
						onChange={changeDirectionIdHandle}
						value={eduIds.directionId || ""}
					/>
				</div>

				<div className="col-md-3 field">
					<Select
						label="Курс"
						items={eduList.courseList}
						onChange={changeCourseIdHandle}
						value={eduIds.courseId || ""}
					/>
				</div>

				<div className="col-md-3 field">
					<Select
						label="Модуль"
						items={eduList.moduleList}
						onChange={changeModuleIdHandle}
						value={eduIds.moduleId || ""}
					/>
				</div>

				<div className="col-md-3 field">
					<Select
						label="Урок"
						items={eduList.lessonList}
						onChange={changeLessonIdHandle}
						value={eduIds.lessonId || ""}
					/>
				</div>
			</div>

			{!isLoading ? (
				<div className="card">
					<div className="card-body">
						<table>
							<thead>
								<tr>
									<th>Пользователь</th>
									<th>Сообщение</th>
									<th>Опубликован</th>
									<th>Прочитано</th>
									{(questionRole.delete || questionRole.view) && (
										<th className="actions" />
									)}
								</tr>
							</thead>

							<tbody>
								{questionList.length > 0 ? (
									questionList.map(item => {
										return (
											<tr key={item.id}>
												<td>
													<div className="row row-xs ai-center">
														<div className="col-auto">
															<img
																src={item.user.avatarThumb || defaultAvatar}
																alt=""
																className="avatar-img image-cover"
															/>
														</div>
														<div className="col-auto">
															{item.user.last_name} {item.user.first_name}
														</div>
													</div>
												</td>

												<td>
													<p className="short-text">{item.message}</p>
												</td>

												<td>
													<div
														className={`btn btn-sm btn-${
															item.public ? "primary" : "danger"
														} cursor-default`}>
														{PUBLIC_TYPES[item.public]}
													</div>
												</td>

												<td className="actions">
													<div
														className={`btn btn-sm btn-${
															item.status ? "primary" : "danger"
														} cursor-default`}>
														{STATUS_TYPES[item.status]}
													</div>
												</td>

												<td className="actions">
													<div className="row row-xs">
														{questionRole.view && (
															<div className="col-auto">
																<Link
																	to={{
																		pathname: `/questions/${item.id}`,
																		state: {
																			lesson: item,
																		},
																	}}
																	className="btn btn-sm btn-primary">
																	<IconWatch size={14} color="#fff" />
																</Link>
															</div>
														)}

														{questionRole.delete && (
															<div className="col-auto">
																<button
																	onClick={() => onRemoveQuestion(item.id)}
																	className="btn btn-sm btn-danger">
																	<IconTrash size={14} color="#fff" />
																</button>
															</div>
														)}
													</div>
												</td>
											</tr>
										)
									})
								) : (
									<tr>
										<td colSpan="5" className="ta-center">
											Нет частых вопросов у данного урока
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				</div>
			) : (
				<div className="d-flex jc-center">
					<Spinner size={100} styles={{ marginTop: 100 }} />
				</div>
			)}
		</div>
	)
}

export default EducationQuestions
