import PropType from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Api from "../../../../../api"
import { Input, Textarea } from "../../../../../components"
import HomeworkListTable from "../../../../../components/HomeworkListTable/HomeworkListTable"
import { Spinner } from "../../../../../components/index"
import TestTableList from "../../../../../components/TestTableList/TestTableList"
import { AppContext } from "../../../../../context/AppContext"

const ModuleView = ({ location, history, match }) => {
	const { course } = location.state
	const { id: moduleId } = match.params

	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const [module, setModule] = useState(null)
	const [moduleTestList, setModuleTestList] = useState([])
	const [homeworkList, setHomeworkList] = useState([])

	const getModuleData = () => {
		Api.get(`/module/${moduleId}`, { headers: { Authorization } }).then(res => {
			const { data, error } = res.data

			if (!error) {
				setModule(data)
			} else {
				alert(data)
			}
		})
	}

	/**
	 * Получение домашних заданий
	 **/
	const getHomeworkList = () => {
		Api.get(`/homework/list?module_id=${moduleId}`, {
			headers: { Authorization }
		}).then(res => {
			const { data, error } = res.data

			if (!error) {
				if (data.length) {
					setHomeworkList(data)
				}
			} else {
				console.error(data)
				alert("Ошибка")
			}
		})
	}

	/**
	 * Получение тестов
	 **/
	const getModuleTestList = () => {
		Api.get(`/test?module_id=${moduleId}`, {
			headers: { Authorization }
		}).then(res => {
			const { data, error } = res.data
			if (!error) {
				if (data.questions && data.questions.length > 0) {
					setModuleTestList(data.questions)
				} else {
					setModuleTestList([])
				}
			} else {
				console.error(data)
				alert("Ошибка")
			}
		})
	}

	// получение домашек
	const getModuleHomeworkList = () => {
		Api.get(`/homework?module_id=${moduleId}`, {
			headers: { Authorization }
		}).then(res => {
			const { data, error } = res.data

			if (!error) {
				if (data.length) {
					setHomeworkList(data)
				}
			} else {
				console.error(data)
				alert("Ошибка")
			}
		})
	}

	/**
	 * Удаление домашних заданий
	 **/
	const removeHomework = id => {
		if (window.confirm("Удалить задание?")) {
			Api.delete(`/homework/${id}`, {
				headers: { Authorization }
			}).then(res => {
				const { data, error } = res.data

				if (!error) {
					setHomeworkList(homeworkList.filter(i => i.id !== id))
					alert(data)
				} else {
					alert(data)
				}
			})
		}
	}

	useEffect(() => {
		getModuleData()
		getModuleTestList()
		getModuleHomeworkList()
		getHomeworkList()
	}, [])

	if (!module) {
		return (
			<div className="d-flex ai-center jc-center flex-fill">
				<Spinner/>
			</div>
		)
	}

	return (
		<>
			<div className="page-header">
				<div className="row ai-center jc-between">
					<div className="col-md">
						<h6 className="title">Просмотр модуля</h6>
					</div>

					<div className="col-md-auto">
						<div onClick={() => history.goBack()} className="btn btn-primary">
							Назад
						</div>
					</div>
				</div>
			</div>

			<form className="form">
				<div className="row">
					<div className="col-md-5 col-xl-3">
						<div className="card field">
							<div className="card-body">
								<img src={module.banner} alt="" className="card-img _block"/>
							</div>
						</div>
					</div>
					<div className="col-md-5 col-xl-3">
						<div className="card field">
							<div className="card-body">
								<iframe
									width="100%"
									height="250"
									src={`https://play.boomstream.com/${module.video}`}
									frameBorder="0"
									scrolling="no"
									allow="autoplay; fullscreen"
									name="target"></iframe>
							</div>
						</div>
					</div>

					<div className="col-md-7 col-xl-6">
						<div className="row">
							<div className="col-sm-6">
								<div className="field">
									<Input label="Курс" value={course[0]?.name || ""} disabled/>
								</div>
							</div>

							<div className="col-sm-6">
								<div className="field">
									<Input
										required
										label="Название"
										value={module?.name || ""}
										disabled
									/>
								</div>
							</div>

							<div className="col-sm-6">
								<div className="field">
									<Input
										required
										label="Цена"
										value={module?.price || ""}
										disabled
									/>
								</div>
							</div>

							<div className="col-sm-6">
								<div className="field">
									<Input
										required
										label="Старая цена"
										value={module?.old_price || ""}
										disabled
									/>
								</div>
							</div>

							<div className="col-12">
								<div className="field">
									<Textarea
										required
										value={module?.description || ""}
										disabled
										rows="9"
									/>
								</div>
							</div>
							<div className="col-12">
								<div className="field">
									<label className="d-flex ai-center cursor-pointer mt-2">
										<input
											type="checkbox"
											checked={module.isPublic}
											className="input-checkbox mr-2"
											readOnly
										/>
										Публикация
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>

			<div className="mt-5 page-header">
				<div className="row ai-center jc-between">
					<div className="col-auto">
						<h6 className="title">Тесты к модулю</h6>
					</div>

					{moduleTestList.length === 0 && (
						<div className="col-auto">
							<Link
								to={{
									pathname: "/test/create",
									state: {
										id: moduleId,
										type: "module_id",
										courseId: module.course_id
									}
								}}
								className="btn btn-primary">
								Добавить тест
							</Link>
						</div>
					)}
				</div>
			</div>
			<div className="card">
				<div className="card-body">
					<TestTableList
						testDelete={getModuleTestList}
						list={moduleTestList}
						courseId={module.course_id}
						type="module_id"
						id={moduleId}
					/>
				</div>
			</div>

			<div className="mt-5 page-header">
				<div className="row ai-center jc-between">
					<div className="col-md-auto">
						<h6 className="title">Домашние задания</h6>
					</div>

					{!homeworkList.length && (
						<div className="col-md-auto">
							<Link
								to={{
									pathname: "/homework/create",
									state: {
										id: moduleId,
										type: "module_id"
									}
								}}
								className="btn btn-primary">
								Добавить домашнее задание
							</Link>
						</div>
					)}
				</div>
			</div>
			<div className="card">
				<div className="card-body">
					<HomeworkListTable data={homeworkList} onRemove={removeHomework}/>
				</div>
			</div>
		</>
	)
}
ModuleView.propTypes = {
	history: PropType.object,
	location: PropType.object,
	match: PropType.object
}

export default ModuleView
