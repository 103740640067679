import PropType from "prop-types"
import * as React from "react"

const SvgComponent = ({ size = 14, color = "#fff", ...props }) => (
	<svg height={size} viewBox="0 0 512 512" width={size} {...props}>
		<path
			fill={color}
			d="M256 0C148.059 0 60.234 87.824 60.234 195.766v180.703c0 24.914 20.266 45.18 45.176 45.18 24.914 0 45.18-20.266 45.18-45.18V225.883c0-24.91-20.266-45.176-45.18-45.176-5.07 0-9.867 1.027-14.426 2.574C97.41 97.77 168.887 30.117 256 30.117c87.066 0 158.523 67.59 165.008 153.043-4.531-1.55-9.356-2.453-14.418-2.453-24.914 0-45.18 20.266-45.18 45.176v150.586c0 24.914 20.266 45.18 45.18 45.18 5.305 0 10.324-1.087 15.058-2.774v17.832c0 8.309-6.765 15.059-15.058 15.059H298.402c-6.238-17.493-22.797-30.118-42.402-30.118-24.91 0-45.176 20.266-45.176 45.176C210.824 491.734 231.09 512 256 512c19.605 0 36.164-12.629 42.402-30.117H406.59c24.91 0 45.176-20.266 45.176-45.176V195.766C451.766 87.824 363.94 0 256 0zm0 0M481.883 213.598v175.156C499.37 382.52 512 365.96 512 346.352V256c0-19.605-12.629-36.164-30.117-42.402zm0 0M0 256v90.352c0 19.609 12.629 36.168 30.117 42.402V213.598C12.63 219.836 0 236.395 0 256zm0 0"
		/>
	</svg>
)
SvgComponent.propTypes = {
	size: PropType.number,
	color: PropType.string
}

export default SvgComponent
