import PropType from "prop-types"
import React, { useContext, useState } from "react"
import { useForm } from "react-hook-form"
import Api from "../../../../../api"
import { Dropzone, Input, Textarea } from "../../../../../components"
import { Modal, Spinner } from "../../../../../components/index"
import { AppContext } from "../../../../../context/AppContext"

const DirectionEdit = ({ location, history }) => {
	const { direction } = location.state

	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const [isSending, setIsSending] = useState(false)
	const [errorObj, setErrorObj] = useState({})
	const [files, setFiles] = useState([])

	const { register, handleSubmit } = useForm({
		defaultValues: {
			name: direction.name || "",
			price: direction.price || "",
			old_price: direction.old_price || "",
			description: direction.description || "",
			isPublic: direction.isPublic || ""
		}
	})

	const onDirectionEdit = data => {
		if (!isSending) {
			setIsSending(true)

			const { name, description, price, old_price } = data

			let errObj = {}

			if (price < 0) errObj = { ...errObj, price: "Некорректная сумма" }
			if (old_price && +old_price < +price)
				errObj = { ...errObj, old_price: "Старая цена должна быть больше" }

			setErrorObj(errObj)

			if (Object.keys(errObj).length === 0) {
				const formData = new FormData()
				formData.append("name", name)
				formData.append("description", description)
				formData.append("price", price)
				formData.append("old_price", old_price)

				if (files.length > 0) formData.append("banner", files[0])

				Api.post(`/direction/update/${direction.id}`, formData, {
					headers: { Authorization }
				}).then(res => {
					const { data, error } = res.data

					if (!error) {
						alert(data)
						setFiles([])
						history.goBack()
					} else {
						window.scrollTo(0, 0)
						console.error(data)
						alert("Ошибка")
						setErrorObj(data)
					}
				})
			}
			setIsSending(false)
		}
	}


	const togglePublic = () => {

		let value
		if (direction.isPublic == true) {
			value = false
		} else value = true

		console.log(direction.isPublic)

		Api.post(
			`/publish/index?direction_id=${direction.id}`,
			{ public: value },
			{ headers: { Authorization } }
		).then(res => {
			const { data, error } = res.data

			if (!error) {
				alert("Настройки публикации изменены")
				history.goBack()
			} else {
				console.error(data)
				alert("Ошибка")
			}
		})
	}


	const onFileRemove = fileName => {
		setFiles(files.filter(f => f.name !== fileName))
	}

	const onFileUpload = filesData => setFiles(filesData)

	return (
		<>
			<div className="page-header">
				<h6 className="title">Редактирование профессии</h6>
			</div>

			<form className="form" onSubmit={handleSubmit(onDirectionEdit)}>
				<div className="row">
					<div className="col-md-5 col-xl-4 col-xxl-3">
						<div className="card field">
							<div className="card-body">
								<img
									src={direction.banner}
									alt=""
									className="card-img _block"
								/>
							</div>
						</div>
					</div>

					<div className="col-md-7 col-xl-8 col-xxl-9">
						<div className="row">
							<div className="col-12">
								<div className="field">
									<Input
										required
										label="Название"
										placeholder="Название"
										name="name"
										register={register}
										error={errorObj.name}
									/>
								</div>
							</div>

							<div className="col-sm-6">
								<div className="field">
									<Input
										required
										label="Цена"
										placeholder="Цена"
										name="price"
										register={register}
										error={errorObj.price}
									/>
								</div>
							</div>

							<div className="col-sm-6">
								<div className="field">
									<Input
										label="Старая цена"
										placeholder="Старая цена"
										name="old_price"
										register={register}
										error={errorObj.old_price}
									/>
								</div>
							</div>

							<div className="col-12">
								<div className="field">
									<Textarea
										required
										label="Описание"
										placeholder="Описание"
										name="description"
										register={register}
										rows="9"
										error={errorObj.description}
									/>
								</div>
							</div>
							<div className="col-12">
								<div className="field">
									<label className="d-flex ai-center cursor-pointer mt-2">
										<input
											type="checkbox"
											onChange={() => togglePublic()}
											checked={direction.isPublic}
											className="input-checkbox mr-2"
											ref={register}
											name="public"
										/>
										Опубликовать
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="field">
					<Dropzone
						multiple={false}
						maxSize={5000000}
						acceptTypes="image/png, image/jpeg, image/gif"
						files={files}
						onFileRemove={onFileRemove}
						onFileUpload={onFileUpload}
					/>
				</div>

				<button className="btn btn-primary btn-lg">Отредактировать</button>
			</form>

			<Modal
				show={isSending}
				showClose={false}
				modalWrapperStyles={{ maxWidth: 300 }}>
				<div className="ta-center">
					<Spinner size={100}/>
				</div>
			</Modal>
		</>
	)
}
DirectionEdit.propTypes = {
	location: PropType.object,
	history: PropType.object
}

export default DirectionEdit
