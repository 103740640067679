/* eslint-disable no-unused-vars */
import PropType from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import Api from "../../../../../api"
import { Modal, Spinner, Textarea } from "../../../../../components"
import { AppContext } from "../../../../../context/AppContext"
// import { IconQuestionArrow } from "../../../../../static/icons"
import "./EducationQuestionsView.scss"

const EducationQuestionsView = ({ match, location, history }) => {
	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const role = state.roleList

	const { id: questionId } = match.params

	const [lessonData, setLessonData] = useState(location.state.lesson)
	const [questionData, setQuestionData] = useState({})
	const [isLoading, setIsLoading] = useState(true)
	const [isShowQuestionModal, setIsShowQuestionModal] = useState(false)
	const [isShowAnswerModal, setIsShowAnswerModal] = useState(false)
	const [answerId, setAnswerId] = useState(null)
	const [answer, setAnswer] = useState("")
	const [questionAnswer, setQuestionAnswer] = useState("")

	const [questionStatus, setQuestionStatus] = useState(lessonData.public)
	const [answerList, setAnswerList] = useState([])

	/**
	 * Просмотр вопросов
	 */
	const getQuestionData = () => {
		setIsLoading(true)

		Api.get(`/question/${questionId}`, { headers: { Authorization } })
			.then(res => {
				const { data, error } = res.data

				if (!error) {
					setQuestionData(data)
					setAnswerList(data.answers)
				} else {
					console.error(data)
					alert("Ошибка")
				}
			})
			.finally(() => setIsLoading(false))
	}

	/**
	 * Добавление ответа к вопросу
	 * @param {*} e - event
	 */
	const onAddQuestionAnswer = e => {
		e.preventDefault()

		Api.put(
			`/question/create-answer/${questionId}?id=${questionId}`,
			{ message: questionAnswer },
			{ headers: { Authorization } }
		).then(res => {
			const { data, error } = res.data

			if (!error) {
				setIsShowQuestionModal(false)
				getQuestionData()
			} else {
				console.error(data)
				alert("Ошибка")
			}
		})
	}

	/**
	 * Добавление ответа к ответу
	 * @param {*} e - event
	 */
	const onAddAnswer = e => {
		e.preventDefault()

		Api.put(
			`/question/create-answer/${questionId}?answer_id=${answerId}`,
			{ message: answer },
			{ headers: { Authorization } }
		)
			.then(res => {
				const { data, error } = res.data

				if (!error) {
					getQuestionData()
				} else {
					console.error(data)
					alert("Ошибка")
				}
			})
			.finally(() => setIsShowAnswerModal(false))
	}

	/**
	 * Удаление вопроса
	 */
	const onRemoveQuestion = () => {
		if (window.confirm("Удалить вопрос?")) {
			Api.delete(`/question/delete/${questionId}`, {
				headers: { Authorization },
			}).then(res => {
				const { data, error } = res.data

				if (!error) {
					history.goBack()
				} else {
					console.error(data)
					alert("Ошибка")
				}
			})
		}
	}

	/**
	 * Удаление ответа
	 * @param {int} id - id ответа
	 */
	const onRemoveAnswer = id => {
		if (window.confirm("Удалить ответ?")) {
			Api.delete(`/question/delete-answer/${id}`, {
				headers: { Authorization },
			}).then(res => {
				const { data, error } = res.data

				if (!error) {
					getQuestionData()
				} else {
					console.error(data)
					alert("Ошибка")
				}
			})
		}
	}

	/**
	 * Прочтение вопроса
	 */
	const onReadQuestion = () => {
		Api.get(`/question/viewed/${questionId}`, {
			headers: { Authorization },
		}).then(res => {
			const { data, error } = res.data

			if (!error) {
				alert(data)

				setLessonData({ ...lessonData, status: 1 })
			} else {
				console.error(data)
				alert("Ошибка")
			}
		})
	}

	/**
	 * Открытие модалки
	 */
	const onShowModal = id => {
		setIsShowAnswerModal(true)
		setAnswerId(id)
	}

	/**
	 * Смена статуса вопроса
	 * @param {*} e - event
	 */
	const onQuestionStatusChange = e => {
		const value = e.target.value

		setQuestionStatus(value)

		Api.post(
			`/question/public/${questionId}`,
			{ public: value },
			{ headers: { Authorization } }
		).then(res => {
			const { data, error } = res.data

			if (error) {
				console.error(data)
				alert("Ошибка")
			}
		})
	}

	/**
	 * Смена статуса ответа
	 * @param {*} e - event
	 * @param {int} answerId - id ответа
	 */
	const onAnswerListChange = (e, answerId) => {
		const value = +e.target.value

		Api.post(
			`/question/public-answer/${answerId}`,
			{ public: value },
			{ headers: { Authorization } }
		).then(res => {
			const { data, error } = res.data

			if (error) {
				console.error(data)
				alert("Ошибка")
			}
		})
	}

	/**
	 * Рендер древовидной структуры ответов
	 * @param {array} answers
	 */
	const renderAnswerDepth = answersList => {
		if (answersList) {
			return (
				<div className="mt-2">
					{answersList.map(answer => {
						return (
							<div key={answer.id} className="pl-2 mb-2 answer-wrap">
								{answer.message}

								<div className="mt-1 row ai-center jc-between">
									<div className="col-auto">
										<div className="row row-sm">
											{role.question["create-answer"] && (
												<div className="col-auto">
													<button
														onClick={() => onShowModal(answer.id)}
														className="btn btn-xs btn-primary">
														Ответить
													</button>
												</div>
											)}

											{role.question.delete && (
												<div className="col-auto">
													<button
														onClick={() => onRemoveAnswer(answer.id)}
														className="btn btn-xs btn-danger">
														Удалить
													</button>
												</div>
											)}
										</div>
									</div>

									<div className="col-auto">
										<select
											className="input input-sm _select"
											defaultValue={answer.public || ""}
											onChange={e => onAnswerListChange(e, answer.id)}>
											<option value="0">Не опубликован</option>
											<option value="1">Опубликован</option>
										</select>
									</div>
								</div>

								{renderAnswerDepth(answer.children)}
							</div>
						)
					})}
				</div>
			)
		}

		return null
	}

	useEffect(() => {
		getQuestionData()
	}, [])

	if (isLoading) {
		return (
			<div className="d-flex jc-center">
				<Spinner size={100} styles={{ marginTop: 100 }} />
			</div>
		)
	}

	return (
		<div className="page-questions-answers form">
			<div className="page-header">
				<div className="row ai-center jc-between">
					<div className="col-md-auto">
						<h6 className="title">Просмотр вопроса к уроку</h6>
					</div>

					<div className="col-md-auto">
						<div onClick={() => history.goBack()} className="btn btn-primary">
							Назад
						</div>
					</div>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="h5 mb-4">
						<div>
							{questionData.lesson.name}: {`"${questionData.message}"`}
						</div>

						<div className="mt-3 row row-sm ai-center jc-between">
							<div className="col-auto">
								<div className="row row-sm">
									{lessonData.status === 0 && (
										<div className="col-auto">
											<button
												onClick={onReadQuestion}
												className="btn btn-sm btn-primary">
												Прочитать вопрос
											</button>
										</div>
									)}

									{role.question.delete && (
										<div className="col-auto">
											<button
												onClick={onRemoveQuestion}
												className="btn btn-sm btn-danger">
												Удалить вопрос
											</button>
										</div>
									)}

									{role.question["create-answer"] && (
										<div className="col-auto">
											<button
												onClick={() => setIsShowQuestionModal(true)}
												className="btn btn-sm btn-primary">
												Ответить на вопрос
											</button>
										</div>
									)}
								</div>
							</div>

							{role.question.public && (
								<div className="col-auto">
									<select
										className="input input-sm _select"
										value={questionStatus || ""}
										onChange={onQuestionStatusChange}>
										<option value="0">Не опубликован</option>
										<option value="1">Опубликован</option>
									</select>
								</div>
							)}
						</div>
					</div>

					<div className="answer-list">
						{answerList.map(item => {
							return (
								<div className="answer py-2 px-3" key={item.id}>
									<div>
										<b>{item.user.name}</b>, {item.message}
									</div>

									<div className="mt-2 row row-xs jc-between ai-center">
										<div className="col-auto">
											<div className="row row-sm">
												{role.question["create-answer"] && (
													<div className="col-auto">
														<button
															onClick={() => onShowModal(item.id)}
															className="btn btn-xs btn-primary">
															Ответить
														</button>
													</div>
												)}

												{role.question["delete-answer"] && (
													<div className="col-auto">
														<button
															onClick={() => onRemoveAnswer(item.id)}
															className="btn btn-xs btn-danger">
															Удалить
														</button>
													</div>
												)}
											</div>
										</div>

										{role.question["public-answer"] && (
											<div className="col-auto">
												<select
													className="input input-sm _select"
													defaultValue={item.public || ""}
													onChange={e => onAnswerListChange(e, item.id)}>
													<option value="0">Не опубликован</option>
													<option value="1">Опубликован</option>
												</select>
											</div>
										)}
									</div>

									{renderAnswerDepth(item.children)}
								</div>
							)
						})}
					</div>
				</div>
			</div>

			<Modal
				show={isShowQuestionModal && role.question["create-answer"]}
				onClose={() => setIsShowQuestionModal(false)}
				modalWrapperStyles={{ maxWidth: 700 }}>
				<form onSubmit={onAddQuestionAnswer} className="form">
					<Textarea
						label="Ответ на вопрос"
						placeholder="Введите ответ"
						value={questionAnswer}
						onChange={e => setQuestionAnswer(e.target.value)}
						rows={10}
					/>

					{role.question["create-answer"] && (
						<button type="submit" className="btn btn-primary btn-block ">
							Ответить
						</button>
					)}
				</form>
			</Modal>

			<Modal
				show={isShowAnswerModal && role.question["create-answer"]}
				onClose={() => setIsShowAnswerModal(false)}
				modalWrapperStyles={{ maxWidth: 700 }}>
				<form onSubmit={onAddAnswer} className="form">
					<Textarea
						label="Ответ на ответ"
						placeholder="Введите ответ"
						value={answer}
						onChange={e => setAnswer(e.target.value)}
						rows={10}
					/>

					{role.question["create-answer"] && (
						<button type="submit" className="btn btn-primary btn-block ">
							Ответить
						</button>
					)}
				</form>
			</Modal>
		</div>
	)
}

EducationQuestionsView.propTypes = {
	match: PropType.object,
	history: PropType.object,
	location: PropType.object,
}

export default EducationQuestionsView
