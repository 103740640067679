/* eslint-disable no-unused-vars */
import PropType from "prop-types"
import React, { useContext, useState } from "react"
import { useForm } from "react-hook-form"
import Api from "../../../../../../api"
import { Dropzone, Input, Select, Textarea } from "../../../../../../components"
import { Modal, Spinner } from "../../../../../../components/index"
import { AppContext } from "../../../../../../context/AppContext"
import {
	LESSON_VERSION_TYPE,
	PDF_ORIENTATION,
} from "../../../../../../global/data"

const LessonVersionEdit = ({ location, history }) => {
	const { originLessonName, lesson } = location.state

	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const [isSending, setIsSending] = useState(false)
	const [errorObj, setErrorObj] = useState({})
	const [mediaFiles, setMediaFiles] = useState([])
	const [bannerFiles, setBannerFiles] = useState([])
	const [downloadPercent, setDownloadPercent] = useState(0)

	const onMediaFileRemove = fileName =>
		setMediaFiles(mediaFiles.filter(f => f.name !== fileName))
	const onMediaFileUpload = files => {
		setMediaFiles(files)
	}

	const onBannerFileRemove = fileName =>
		setBannerFiles(bannerFiles.filter(f => f.name !== fileName))
	const onBannerFileUpload = files => setBannerFiles(files)

	const { register, handleSubmit, watch } = useForm({
		defaultValues: {
			name: lesson.name || "",
			description: lesson.description || "",
			orientation: lesson.orientation || "",
			public: lesson.public || "",
			type: lesson.type,
		},
	})

	const typeValue = Number(watch("type"))

	const onLessonVersionEdit = data => {
		if (!isSending) {
			setIsSending(true)

			const formData = new FormData()
			formData.append("name", data.name)
			formData.append("type", data.type)
			formData.append("description", data.description)
			formData.append("public", data.public)
			formData.append("orientation", data.orientation)

			bannerFiles.length > 0 && formData.append("banner", bannerFiles[0])
			mediaFiles.length > 0 && formData.append("video", mediaFiles[0])

			Api.post(`/lesson-version/update/${lesson.id}`, formData, {
				headers: { Authorization },
				onUploadProgress: progressEvent => {
					const percentCompleted = Math.round(
						(progressEvent.loaded * 100) / progressEvent.total
					)
					setDownloadPercent(percentCompleted)
				},
			})
				.then(res => {
					const { data, error } = res.data

					if (!error) {
						setErrorObj({})
						history.goBack()
					} else {
						window.scrollTo(0, 0)
						setErrorObj(data)
						console.error(data)
					}
				})
				.finally(() => {
					setIsSending(false)
					setDownloadPercent(0)
				})
		}
	}

	return (
		<div className="page-lesson-view">
			<div className="page-header">
				<h6 className="title">Редактирование версии урока</h6>
			</div>

			<form className="form" onSubmit={handleSubmit(onLessonVersionEdit)}>
				<div className="row">
					<div className="col-md-5 col-xl-4 col-xxl-3">
						<div className="card field">
							<div className="card-body">
								{typeValue === 1 && (
									<>
										<iframe
											width="100%"
											height="250"
											src={`https://play.boomstream.com/${lesson.video}`}
											frameBorder="0"
											scrolling="no"
											allow="autoplay; fullscreen"
											name="target"
											title={lesson.name || ""}
										/>

										{lesson.banner && (
											<img
												src={lesson.banner}
												alt=""
												className="mt-3"
												style={{ width: "100%" }}
											/>
										)}
									</>
								)}

								{lesson.audio && typeValue === 3 && (
									<audio
										src={lesson.audio}
										controls
										style={{ width: "100%" }}
									/>
								)}
							</div>
						</div>

						<label className="d-flex ai-center cursor-pointer">
							<input
								type="checkbox"
								className="input-checkbox mr-2"
								ref={register}
								name="public"
							/>
							Опубликовать
						</label>
					</div>

					<div className="col-md-7 col-xl-8 col-xxl-9">
						<div className="row">
							<div className="col-sm-6">
								<div className="field">
									<Input
										disabled
										label="Родительский урок"
										defaultValue={originLessonName}
									/>
								</div>
							</div>

							<div className="col-sm-6">
								<div className="field">
									<Input
										required
										label="Название"
										placeholder="Название"
										name="name"
										register={register}
										error={errorObj.name}
									/>
								</div>
							</div>

							<div className={`${typeValue === 2 ? "col-sm-6" : "col-12"}`}>
								<div className="field">
									<Select
										required
										label="Тип урока"
										name="type"
										register={register}
										items={LESSON_VERSION_TYPE}
									/>
								</div>
							</div>

							{typeValue === 2 && (
								<div className="col-sm-6">
									<div className="field">
										<Select
											required
											label="Ориентация PDF документа"
											name="orientation"
											register={register}
											items={PDF_ORIENTATION}
										/>
									</div>
								</div>
							)}

							<div className="col-12">
								<div className="field">
									<Textarea
										required
										label="Описание"
										placeholder="Описание"
										name="description"
										register={register}
										rows="9"
										error={errorObj.description}
									/>
								</div>
							</div>

							{typeValue !== 2 && (
								<>
									<div className="col-12">
										<div className="card field">
											<div className="card-body">
												<div className="h6 mb-2">Загрузка медиафайла</div>
												<Dropzone
													multiple={false}
													maxSize={2000000000}
													acceptTypes="video/*, audio/mp4, audio/mpeg"
													files={mediaFiles}
													onFileRemove={onMediaFileRemove}
													onFileUpload={onMediaFileUpload}
												/>
											</div>
										</div>
									</div>

									{typeValue === 1 && (
										<div className="col-12">
											<div className="card field">
												<div className="card-body">
													<div className="h6 mb-2">Загрузка баннера</div>
													<Dropzone
														multiple={false}
														maxSize={5000000}
														acceptTypes="image/*"
														files={bannerFiles}
														onFileRemove={onBannerFileRemove}
														onFileUpload={onBannerFileUpload}
													/>
												</div>
											</div>
										</div>
									)}
								</>
							)}
						</div>
					</div>
				</div>

				<button className="btn btn-primary btn-lg">Отредактировать</button>
			</form>

			<Modal
				show={isSending}
				showClose={false}
				modalWrapperStyles={{ maxWidth: 300 }}>
				<div className="ta-center">
					<Spinner size={100} />
					<div className="font-700 mt-2">{parseInt(downloadPercent)}%</div>
				</div>
			</Modal>
		</div>
	)
}

LessonVersionEdit.propTypes = {
	location: PropType.object,
	history: PropType.object,
}

export default LessonVersionEdit
