import PropType from "prop-types"
import React, { useContext, useState } from "react"
import { useForm } from "react-hook-form"
import Api from "../../../../../api"
import { Dropzone, Input, Textarea } from "../../../../../components"
import { Modal, Spinner } from "../../../../../components/index"
import { AppContext } from "../../../../../context/AppContext"

const DirectionCreate = ({ history }) => {
	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const [isSending, setIsSending] = useState(false)
	const [errorObj, setErrorObj] = useState({})
	const [files, setFiles] = useState([])

	const { register, handleSubmit } = useForm()

	const onDirectionAdd = data => {
		if (!isSending) {
			setIsSending(true)

			const { name, description, price, old_price } = data

			let errObj = {}

			if (price < 0) errObj = { ...errObj, price: "Некорректная сумма" }
			if (old_price && +old_price < +price)
				errObj = { ...errObj, old_price: "Старая цена должна быть больше" }

			setErrorObj(errObj)

			if (Object.keys(errObj).length === 0) {
				const formData = new FormData()
				formData.append("banner", files[0])
				formData.append("name", name)
				formData.append("description", description)
				formData.append("price", price)
				formData.append("old_price", old_price)

				Api.post("/direction", formData, { headers: { Authorization } }).then(
					res => {
						const { data, error } = res.data

						if (!error) {
							alert(data)
							setFiles([])
							history.goBack()
						} else {
							window.scrollTo(0, 0)
							setErrorObj(data)
							console.error(data)
							alert("Ошибка")
						}
					}
				)
			}
			setIsSending(false)
		}
	}

	const onFileRemove = fileName => {
		setFiles(files.filter(f => f.name !== fileName))
	}

	const onFileUpload = filesData => setFiles(filesData)

	return (
		<>
			<div className="page-header">
				<h6 className="title">Создать профессию</h6>
			</div>

			<form className="form" onSubmit={handleSubmit(onDirectionAdd)}>
				<div className="row">
					<div className="col-xl-4">
						<div className="field">
							<Input
								required
								label="Название"
								placeholder="Название"
								name="name"
								register={register}
								error={errorObj.name}
							/>
						</div>
					</div>

					<div className="col-xl-4">
						<div className="field">
							<Input
								required
								label="Цена"
								placeholder="Цена"
								name="price"
								register={register}
								error={errorObj.price}
							/>
						</div>
					</div>

					<div className="col-xl-4">
						<div className="field">
							<Input
								label="Старая цена"
								placeholder="Старая цена"
								name="old_price"
								register={register}
								error={errorObj.old_price}
							/>
						</div>
					</div>
				</div>

				<div className="field">
					<Textarea
						required
						label="Описание"
						placeholder="Описание"
						name="description"
						register={register}
						rows="8"
						error={errorObj.description}
					/>
				</div>

				<div className="field">
					<div className="card">
						<div className="card-body">
							<Dropzone
								multiple={false}
								maxSize={5000000}
								acceptTypes="image/png, image/jpeg, image/gif"
								files={files}
								onFileRemove={onFileRemove}
								onFileUpload={onFileUpload}
							/>
						</div>
					</div>
				</div>

				<button type="submit" className="btn btn-primary btn-lg">
					Создать
				</button>
			</form>

			<Modal
				show={isSending}
				showClose={false}
				modalWrapperStyles={{ maxWidth: 300 }}>
				<div className="ta-center">
					<Spinner size={100}/>
				</div>
			</Modal>
		</>
	)
}

DirectionCreate.propTypes = {
	history: PropType.object
}

export default DirectionCreate
