/* eslint-disable react/prop-types */
import React, { useContext } from "react"
import { AppContext } from "../../context/AppContext"
import { IconTrash } from "../../static/icons"

const ExtMaterialListTable = ({ data, onRemove }) => {
	const { state } = useContext(AppContext)
	const role = state.roleList

	return (
		<table>
			<thead>
				<tr>
					<th>Название</th>
					{role.document.delete && <th className="actions" />}
				</tr>
			</thead>

			<tbody>
				{data?.length > 0 ? (
					data.map(item => {
						return (
							<tr key={item.id}>
								<td>{item.name}</td>
								{role.document.delete && (
									<td className="actions">
										<button
											onClick={() => onRemove(item.id)}
											className="btn btn-sm btn-danger">
											<IconTrash size={14} color="#fff" />
										</button>
									</td>
								)}
							</tr>
						)
					})
				) : (
					<tr>
						<td colSpan="2" className="ta-center">
							Дополнительные материалы отсутствуют
						</td>
					</tr>
				)}
			</tbody>
		</table>
	)
}

export default ExtMaterialListTable
