import PropType from "prop-types"
import React, { useContext, useState } from "react"
import { useForm } from "react-hook-form"
import Api from "../../../../../api"
import { Dropzone, Input } from "../../../../../components"
import { Modal, Spinner } from "../../../../../components/index"
import { AppContext } from "../../../../../context/AppContext"

const FaqSectionCreate = ({ history }) => {
	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const [isSending, setIsSending] = useState(false)
	const [errorObj, setErrorObj] = useState({})
	const [files, setFiles] = useState([])

	const { register, handleSubmit } = useForm()

	const onFileRemove = fileName =>
		setFiles(files.filter(f => f.name !== fileName))
	const onFileUpload = filesData => setFiles(filesData)

	const onAddData = data => {
		if (!isSending) {
			setIsSending(true)

			const { name } = data

			let errObj = {}

			if (name.trim().length === 0)
				errObj = { ...errObj, name: "Название не может быть пустым" }

			setErrorObj(errObj)

			if (Object.keys(errObj).length === 0) {
				const formData = new FormData()
				formData.append("icon", files[0])
				formData.append("name", name)

				Api.post("/section-faq", formData, { headers: { Authorization } }).then(
					res => {
						const { error, data } = res.data

						if (!error) {
							alert(data.message)
							setErrorObj({})
							history.goBack()
						} else {
							setErrorObj(data)
							console.error(data)
							alert("Ошибка")
						}
					}
				)
			}

			setIsSending(false)
		}
	}

	return (
		<div className="page-faq-section-create">
			<div className="page-header">
				<div className="row ai-center jc-between">
					<div className="col-md-auto">
						<h6 className="title">Создание раздела частого вопроса</h6>
					</div>

					<div className="col-md-auto">
						<button
							onClick={() => history.goBack()}
							className="btn btn-primary btn-lg">
							Назад
						</button>
					</div>
				</div>
			</div>

			<form className="form" onSubmit={handleSubmit(onAddData)}>
				<div className="field">
					<Input
						required
						label="Название раздела"
						placeholder="Название раздела"
						name="name"
						register={register}
						error={errorObj.name}
					/>
				</div>

				<div className="field">
					<div className="card">
						<div className="card-body">
							<Dropzone
								multiple={false}
								maxSize={5000000}
								acceptTypes="image/*"
								files={files}
								onFileRemove={onFileRemove}
								onFileUpload={onFileUpload}
							/>
						</div>
					</div>
				</div>

				<button className="btn btn-primary" type="submit">
					Создать
				</button>
			</form>

			<Modal
				show={isSending}
				showClose={false}
				modalWrapperStyles={{ maxWidth: 300 }}>
				<div className="ta-center">
					<Spinner size={100}/>
				</div>
			</Modal>
		</div>
	)
}

FaqSectionCreate.propTypes = {
	history: PropType.object
}

export default FaqSectionCreate
