/* eslint-disable react/prop-types */
import React, { useContext, useState } from "react"
import Api from "../../../../../api"
import { Dropzone, Input, Select } from "../../../../../components"
import { Modal, Spinner } from "../../../../../components/index"
import { AppContext } from "../../../../../context/AppContext"

const ExtMaterialAdd = ({ location, history }) => {
	const { lessonId, docType } = location.state

	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const [isSending, setIsSending] = useState(false)
	const [files, setFiles] = useState([])
	const [errorObj, setErrorObj] = useState({})
	const [name, setName] = useState("")
	const [type, setType] = useState(docType)

	const onChangeName = e => {
		setName(e.target.value)
	}

	const onChangeType = e => {
		setType(e.target.value)
	}

	const onFileRemove = fileName =>
		setFiles(files.filter(f => f.name !== fileName))
	const onFileUpload = filesData => setFiles(filesData)

	/**
	 * Добавление нового документа
	 */
	const onSubmit = e => {
		if (!isSending) {
			setIsSending(true)

			e.preventDefault()

			if (files.length > 0) {
				const formData = new FormData()
				formData.append("name", name)
				formData.append("type", type)
				formData.append("document", files[0])

				Api.post(`/lesson/upload-document/${lessonId}`, formData, {
					headers: { Authorization }
				}).then(res => {
					const { data, error } = res.data

					if (!error) {
						history.push(`/lesson/view/${lessonId}`)
					} else {
						setErrorObj(data)
						console.error(data)
						setIsSending(false)
					}
				})
			} else {
				alert("Вы забыли добавить файл")
			}
		}
	}

	return (
		<div className="page-ext-material-add">
			<div className="page-header">
				<div className="row ai-center jc-between">
					<div className="col-md-auto">
						<h6 className="title">Добавление материала</h6>
					</div>

					<div className="col-md-auto">
						<button
							onClick={() => history.goBack()}
							className="btn btn-primary btn-lg">
							Назад
						</button>
					</div>
				</div>
			</div>

			<form onSubmit={onSubmit} className="form">
				<div className="row">
					<div className="col-xxl-8">
						<div className="field">
							<Input
								label="Название документа"
								value={name}
								onChange={onChangeName}
								error={errorObj.name}
							/>
						</div>
					</div>

					<div className="col-xxl-4">
						<div className="field">
							<Select
								items={[
									{ id: 1, name: "Доп материал" },
									{ id: 2, name: "Презентация" }
								]}
								label="Тип документа"
								value={type}
								onChange={onChangeType}
								error={errorObj.type}
							/>
						</div>
					</div>

					<div className="col-12">
						<div className="field">
							<div className="h6 mb-2">Загрузка документа</div>
							<Dropzone
								multiple={false}
								maxSize={10000000}
								acceptTypes={""}
								files={files}
								onFileRemove={onFileRemove}
								onFileUpload={onFileUpload}
							/>
						</div>
					</div>

					<div className="col-12">
						<button type="submit" className="btn btn-primary">
							Добавить
						</button>
					</div>
				</div>
			</form>

			<Modal
				show={isSending}
				showClose={false}
				modalWrapperStyles={{ maxWidth: 300 }}>
				<div className="ta-center">
					<Spinner size={100}/>
				</div>
			</Modal>
		</div>
	)
}

export default ExtMaterialAdd
