import PropType from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import Api from "../../../../../api"
import { Input, Select, Textarea } from "../../../../../components"
import { Modal, Spinner } from "../../../../../components/index"
import { AppContext } from "../../../../../context/AppContext"

const FaqQuestionEdit = ({ match, location, history }) => {
	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const [isSending, setIsSending] = useState(false)
	const [errorObj, setErrorObj] = useState({})
	const [section, setSection] = useState([])
	const [sectionId, setSectionId] = useState(location.state?.sectionId || null)

	const { register, handleSubmit } = useForm({
		defaultValues: {
			answer: location.state?.faq?.answer || "",
			question: location.state?.faq?.question || ""
		}
	})

	const getSectionHandle = e => setSectionId(e.target.value)

	const getSection = () => {
		Api.get("/section-faq/list", { headers: { Authorization } }).then(res => {
			const { data, error } = res.data
			if (!error) {
				if (data.length) {
					setSection(data)
					setSectionId(data[0].id)
				} else {
					console.error(data)
					alert("Ошибка")
				}
			}
		})
	}

	const onFaqEdit = data => {
		if (!isSending) {
			setIsSending(true)

			const { question, answer } = data

			let errObj = {}

			if (question.trim().length === 0)
				errObj = { ...errObj, question: "Вопрос не может быть пустым" }
			if (answer.trim().length === 0)
				errObj = { ...errObj, answer: "Ответ не может быть пустым" }

			setErrorObj(errObj)

			if (Object.keys(errObj).length === 0) {
				Api.put(
					`/faq/${match.params.id}`,
					{ ...data, section: sectionId },
					{ headers: { Authorization } }
				).then(res => {
					const { error, data } = res.data

					if (!error) {
						alert(data)
						setErrorObj({})
						history.goBack()
					} else {
						setErrorObj(data)
						console.error(data)
						alert("Ошибка")
					}
				})
			}

			setIsSending(false)
		}
	}

	useEffect(() => {
		getSection()
	}, [])

	return (
		<div className="page-faq-create">
			<div className="page-header">
				<div className="row ai-center jc-between">
					<div className="col-md-auto">
						<h6 className="title">Редактирование частого вопроса</h6>
					</div>

					<div className="col-md-auto">
						<button
							onClick={() => history.goBack()}
							className="btn btn-primary btn-lg">
							Назад
						</button>
					</div>
				</div>
			</div>

			<form className="form row" onSubmit={handleSubmit(onFaqEdit)}>
				<div className="col-md-6">
					<div className="field">
						<Select
							label="Раздел"
							name="section"
							items={section}
							value={sectionId}
							onChange={getSectionHandle}
						/>
					</div>
				</div>

				<div className="col-md-6">
					<div className="field">
						<Input
							required
							label="Вопрос"
							placeholder="Вопрос"
							name="question"
							register={register}
							error={errorObj.question}
						/>
					</div>
				</div>

				<div className="col-12">
					<div className="field">
						<Textarea
							required
							label="Ответ"
							placeholder="Ответ"
							name="answer"
							register={register}
							rows={20}
							error={errorObj.answer}
						/>
					</div>
				</div>

				<div className="col-12">
					<button className="btn btn-primary" type="submit">
						Отредактировать
					</button>
				</div>
			</form>

			<Modal
				show={isSending}
				showClose={false}
				modalWrapperStyles={{ maxWidth: 300 }}>
				<div className="ta-center">
					<Spinner size={100}/>
				</div>
			</Modal>
		</div>
	)
}

FaqQuestionEdit.propTypes = {
	match: PropType.object,
	location: PropType.object,
	history: PropType.object
}

export default FaqQuestionEdit
