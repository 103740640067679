/* eslint-disable react/prop-types */
import React, { useContext, useState } from "react"
import Api from "../../../../../../api"
import { Input, Modal } from "../../../../../../components"
import { AppContext } from "../../../../../../context/AppContext"
import { IconTrash } from "../../../../../../static/icons"

const AddQuestionModal = ({
	onCloseModal,
	courseId,
	type,
	id,
	influences,
	onAddQuestion,
}) => {
	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const [formData, setFormData] = useState({
		question: "",
		answers: [
			{
				answer: "",
				correct: "",
				influences,
			},
		],
	})

	const saveQuestion = () => {
		Api.post(`/test/create/${courseId}?${type}=${id}`, [formData], {
			headers: { Authorization },
		}).then(res => {
			const { error, data } = res.data

			if (!error) {
				const { answer_id, question_id } = data[0]

				const newData = { ...formData, id: question_id }
				const answerList = newData.answers.map((answer, ind) => {
					return { ...answer, id: answer_id[ind], influences }
				})
				const newFormData = { ...newData, answers: answerList }

				onAddQuestion(q => [...q, newFormData])
				onCloseModal()
			} else {
				console.error(data)
				alert("Ошибка")
			}
		})
	}

	const changeQuestionValue = e => {
		setFormData({ ...formData, question: e.target.value })
	}

	const addAnswer = () => {
		const data = {
			...formData,
			answers: [...formData.answers, { answer: "", correct: "" }],
		}

		setFormData(data)
	}

	const changeAnswerValue = (answer, aIndex) => {
		const answers = formData.answers.map((a, aInd) => {
			if (aInd === aIndex) {
				return { ...a, answer }
			}

			return a
		})
		const data = { ...formData, answers }

		setFormData(data)
	}

	const changeAnswerCorrect = aIndex => {
		const answers = formData.answers.map((a, aInd) => {
			if (aInd === aIndex) {
				if (a.correct === "") {
					return { ...a, correct: "on" }
				} else {
					return { ...a, correct: "" }
				}
			}
			return a
		})
		const data = { ...formData, answers }

		setFormData(data)
	}

	const removeAnswer = aIndex => {
		const data = {
			...formData,
			answers: formData.answers.filter((_, ind) => ind !== aIndex),
		}

		setFormData(data)
	}

	return (
		<Modal show onClose={onCloseModal} modalWrapperStyles={{ maxWidth: 800 }}>
			<div className="form">
				<Input
					label="Вопрос"
					value={formData.question}
					onChange={changeQuestionValue}
				/>

				<div className="pl-md-3 mt-3">
					<div className="card">
						<div className="card-body">
							{formData.answers.map((item, index) => {
								return (
									<div className="field" key={index}>
										<div className="row row-sm ai-end">
											<div className="col">
												<Input
													label={`Ответ ${index + 1}`}
													value={item.answer}
													onChange={e =>
														changeAnswerValue(e.target.value, index)
													}
													placeholder="Ответ"
												/>
											</div>

											<div
												className="col-auto d-flex ai-center"
												style={{ height: 38 }}>
												<label className="cursor-pointer">
													<input
														name="answers[0].correct"
														type="checkbox"
														className="input-checkbox"
														onChange={() => changeAnswerCorrect(index)}
														value={item.correct}
													/>
												</label>
											</div>

											<div className="col-auto">
												<div
													className="btn btn-danger"
													onClick={() => removeAnswer(index)}>
													<IconTrash size={13} color="#fff" />
												</div>
											</div>
										</div>
									</div>
								)
							})}

							<button
								className="mt-0 btn btn-primary btn-sm"
								onClick={addAnswer}>
								Добавить ответ
							</button>
						</div>
					</div>
				</div>

				<button
					type="submit"
					className="btn btn-primary"
					onClick={saveQuestion}>
					Добавить вопрос
				</button>
			</div>
		</Modal>
	)
}

export default AddQuestionModal
