import React from "react"
import "./Home.scss"

const Home = () => {
	return (
		<>
			<div className="page-header">
				<h6 className="title">Админ-панель</h6>
			</div>
		</>
	)
}

export default Home
