import PropType from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import Api from "../../../../../api"
import { Input } from "../../../../../components"
import { AppContext } from "../../../../../context/AppContext"

const TestView = ({ history, match, location }) => {
	const { state } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const { type } = location.state

	const [testData, setTestData] = useState([])

	/**
	 * Получение данных по тесту
	 */
	const getTestData = () => {
		Api.get(`/test?${type}=${match.params.id}`, {
			headers: { Authorization }
		}).then(res => {
			const { data, error } = res.data

			if (!error) {
				setTestData(data)
			} else {
				console.error(data)
			}
		})
	}

	useEffect(() => {
		getTestData()
	}, [])

	return (
		<>
			<div className="page-header">
				<div className="row ai-center jc-between">
					<div className="col-md-auto">
						<h6 className="title">Просмотр теста</h6>
					</div>

					<div className="col-md-auto">
						<div onClick={() => history.goBack()} className="btn btn-primary">
							Назад
						</div>
					</div>
				</div>
			</div>

			<div className="form">
				<div className="card">
					<div className="card-body">
						{testData?.questions?.map((qItem, qIndex) => {
							return (
								<div className="card" key={qIndex}>
									<div className="card-body">
										<div className="field">
											<div className="row-sm ai-end">
												<div className="col">
													<Input
														label={`Вопрос ${qIndex + 1}`}
														value={qItem.question}
														readOnly
													/>
												</div>
											</div>
										</div>

										<div className="pl-md-3">
											{qItem.answers.map((aItem, aIndex) => {
												return (
													<div className="field" key={aIndex}>
														<div className="row-sm ai-end">
															<div className="col">
																<Input
																	label={`Ответ ${aIndex + 1}`}
																	value={aItem.answer}
																	readOnly
																/>
															</div>

															<div
																className="col-auto d-flex ai-center"
																style={{ height: 38 }}>
																<input
																	name={`answers[${qIndex}].correct`}
																	type="checkbox"
																	className="input-checkbox"
																	readOnly
																	checked={aItem.correct}
																/>
															</div>
														</div>

														<div className="row row-sm mt-2">
															{aItem.influences &&
															aItem.influences.map((i, index) => {
																return (
																	<div className="col-xl-2" key={index}>
																		<input
																			disabled
																			className="input my-2"
																			defaultValue={i.name}
																		/>

																		<select
																			className="input"
																			value={i.amount || 0}
																			disabled>
																			<option value="1">
																				Предпочтительный
																			</option>
																			<option value="0">Нейтральный</option>
																			<option value="-1">
																				Нежелательный
																			</option>
																		</select>
																	</div>
																)
															})}
														</div>
													</div>
												)
											})}
										</div>
									</div>
								</div>
							)
						})}
					</div>
				</div>
			</div>
		</>
	)
}

TestView.propTypes = {
	history: PropType.object,
	match: PropType.object,
	location: PropType.object
}

export default TestView
