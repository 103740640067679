import React, { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import Api from "../../../../api"
import { Modal, Select, Spinner } from "../../../../components"
import Message from "../../../../components/Message/Message"
import { AppContext } from "../../../../context/AppContext"
import {
	IconClip,
	IconDocs,
	IconEducation,
	IconSend,
	IconTrash,
} from "../../../../static/icons"
import default_avatar from "../../../../static/imgs/users/default_avatar.svg"
import {
	addUrlParam,
	clearUrlParams,
	getUrlParams,
	removeUrlParam,
} from "../../../../utils/urlManipulation"
import "../Chat.scss"

const Courses = () => {
	const { state, setUnreadMessages } = useContext(AppContext)
	const Authorization = `Bearer ${state.authToken}`

	const role = state.roleList.homework

	const URL_PARAMS = getUrlParams()
	const directionParamId = URL_PARAMS["direction"]
	const courseParamId = URL_PARAMS["course"]
	const chatParamId = URL_PARAMS["chat"]

	const { handleSubmit, register, reset } = useForm()

	const [isLoading, setIsLoading] = useState(true)
	const [homeworksUserList, setHomeworksUserList] = useState([])
	const [homeworkId, setHomeworkId] = useState(null)
	const [chatMessageList, setChatMessageList] = useState([])
	const [homeworkForm, setHomeworkForm] = useState(null)
	const [showModal, setShowModal] = useState(false)
	const [userId, setUserId] = useState(null)
	const [isHomeworkStart, setIsHomeworkStart] = useState(true)
	const [documents, setDocuments] = useState([])
	const [documentError, setDocumentsError] = useState(null)
	const [eduList, setEduList] = useState({
		directionList: [],
		courseList: [],
	})
	const [eduIds, setEduIds] = useState({
		directionId: null,
		courseId: null,
		chatId: null,
	})

	const changeDirectionIdHandle = e => {
		const value = +e.target.value

		clearUrlParams()
		addUrlParam("direction", value)
		setEduIds({ ...eduIds, directionId: value })
		clearChatData()
	}
	const changeCourseIdHandle = e => {
		const value = +e.target.value

		removeUrlParam(["module", "lesson", "homework"])
		addUrlParam("course", value)
		setEduIds({ ...eduIds, courseId: value })
		clearChatData()
	}

	const clearChatData = () => {
		setHomeworksUserList([])
		setChatMessageList([])
	}

	const getDirectionList = () => {
		Api.get("/direction/list?sort=name", { headers: { Authorization } }).then(
			res => {
				const { data, error } = res.data

				if (!error) {
					setEduList({ ...eduList, directionList: data })

					if (data.length) {
						setEduIds({
							...eduIds,
							directionId: directionParamId || data[0].id,
						})
					}

					// if (data.length) {
					// 	setDirectionList(data)
					// 	getCourseList(directionParamId || data[0]?.id)
					// }
				} else {
					console.error(data)
				}
			}
		)
	}

	const getCourseList = () => {
		// setCourseList([])

		if (eduIds.directionId) {
			Api.get(`/course/list/${eduIds.directionId}`, {
				headers: { Authorization },
			}).then(res => {
				const { data, error } = res.data

				if (!error) {
					// if (data.length) {
					// 	setCourseList(data)
					// 	getModuleList(courseParamId || data[0]?.id)
					// }

					setEduList({ ...eduList, courseList: data })

					if (data.length) {
						setEduIds({ ...eduIds, courseId: courseParamId || data[0].id })
					}
				} else {
					console.error(data)
				}
			})
		}
	}

	const getHomeworkList = () => {
		if (eduIds.courseId) {
			Api.get(`/homework/list?course_id=${eduIds.courseId}`, {
				headers: { Authorization },
			}).then(res => {
				const { data, error } = res.data

				if (!error) {
					if (data.length) {
						role["list-user-homework"] && getHomeworkUserList(data[0].id)
					} else {
						setEduIds({ ...eduIds, chatId: null })
						removeUrlParam(["chat"])
					}
				} else {
					alert("Ошибка")
					console.error(data)
				}
			})
		}
	}

	const getHomeworkUserList = homeworkId => {
		setHomeworksUserList([])

		Api.get(`/homework/userhomework-list/${homeworkId}`, {
			headers: { Authorization },
		}).then(res => {
			const { error, data } = res.data

			if (!error) {
				setHomeworksUserList(data)

				if (data.length) {
					setUserId(data[0].user.id)
					setIsHomeworkStart(data[0].statusName === "Начат")
					setEduIds({ ...eduIds, chatId: chatParamId || data[0]?.chat_id })
				}
			} else {
				alert("Ошибка")
				console.error(data)
			}
		})
	}

	const getMessageList = () => {
		if (eduIds.chatId) {
			Api.get(`/message/${eduIds.chatId}`, { headers: { Authorization } })
				.then(res => {
					const { data, error } = res.data

					if (!error) {
						setChatMessageList(data)
					} else {
						console.error(data)
					}
				})
				.finally(() => setIsLoading(false))
		}
	}

	const onAddFiles = e => {
		const files = [...e.target.files]

		if (files.length > 0) {
			files.forEach(doc => {
				if (doc.size <= 5242880) {
					setDocuments([...documents, doc])
					setDocumentsError(null)
				} else {
					setDocumentsError("Попробуйте файл меньше 5 Мб")
				}
			})
		}
	}

	const removeFile = docName => {
		setDocuments(documents.filter(doc => doc.name !== docName))
	}

	const onSendMessage = data => {
		const { message } = data

		if (
			(documents.length === 0 || documents.length > 20) &&
			message.length === 0
		)
			return false

		const formData = new FormData()
		formData.append("message", message)

		documents.length > 0 &&
			documents.forEach((doc, index) =>
				formData.append(`documents[${index}]`, doc)
			)

		Api.post(`/message/${eduIds.chatId}`, formData, {
			headers: { Authorization },
		}).then(res => {
			const { error, data } = res

			if (!error) {
				reset()
				setDocuments([])
				setDocumentsError(null)
			} else {
				console.error(data)
			}
		})
	}

	const onFileDownload = (link, fileName) => {
		Api.get(link, { headers: { Authorization } }).then(res => {
			const { error, data } = res.data

			if (!error) {
				downloadBase64File(data, fileName)
			} else {
				console.error(data)
				alert("Ошибка")
			}
		})
	}

	const downloadBase64File = (base64Data, fileName) => {
		const downloadLink = document.createElement("a")
		downloadLink.href = base64Data
		downloadLink.download = fileName
		downloadLink.click()
	}

	const onChatChange = (chatId, index, statusName, userID, isViewed) => {
		setEduIds({ ...eduIds, chatId })
		setUserId(userID)

		setIsHomeworkStart(statusName === "Начат")

		const tmpHomeworkUserList = homeworksUserList.map((homework, ind) => ({
			...homework,
			isViewed: ind === index ? true : homework.isViewed,
		}))

		setHomeworksUserList(tmpHomeworkUserList)

		if (!isViewed) {
			const val =
				state.unreadMessages.homework - 1 > 0
					? state.unreadMessages.homework - 1
					: 0
			setUnreadMessages({ ...state.unreadMessages, homework: val })
		}
	}

	const getHomeworkForm = homeworkId => {
		setHomeworkForm(null)

		Api.get(`/homework/view-answer-homework/${homeworkId}?user_id=${userId}`, {
			headers: { Authorization },
		}).then(res => {
			const { error, data } = res.data

			if (!error) {
				setHomeworkForm(data)
				setHomeworkId(data.questions[0].user_homework_id)
			} else {
				console.error(data)
			}
		})
	}

	const showModalForm = () => {
		setShowModal(s => !s)
	}

	const downloadFile = (link, fileName) => {
		Api.get(link, { headers: { Authorization } }).then(res => {
			const { error, data } = res.data

			if (!error) {
				downloadBase64File(data, fileName)
			} else {
				console.error(data)
				alert("Ошибка")
			}
		})
	}

	const acceptHomework = () => {
		if (window.confirm("Принять работу?")) {
			Api.get(`/homework/finish/${homeworkId}`, {
				headers: { Authorization },
			}).then(res => {
				const { data, error } = res.data

				if (!error) {
					alert(data)
					setIsHomeworkStart(false)
				} else {
					console.error(data)
					alert("Ошибка")
				}
			})
		}
	}

	useEffect(() => {
		getDirectionList()
	}, [])

	useEffect(() => {
		getCourseList()
	}, [eduIds.directionId])

	useEffect(() => {
		getHomeworkList()
	}, [eduIds.courseId])

	useEffect(() => {
		if (eduIds.chatId && role.view) {
			getMessageList()
			addUrlParam("chat", eduIds.chatId)

			const unsubscribe = setInterval(() => {
				getMessageList()
			}, 5000)

			return () => {
				clearTimeout(unsubscribe)
			}
		}
	}, [eduIds.chatId])

	return (
		<div className="page-chat">
			<div className="page-header">
				<h6 className="title">Курсовые работы</h6>
			</div>

			<div className="form">
				<div className="row">
					<div className="col-sm-6">
						<div className="field">
							<Select
								label="Профессия"
								items={eduList.directionList}
								onChange={changeDirectionIdHandle}
								value={eduIds.directionId || ""}
							/>
						</div>
					</div>

					<div className="col-sm-6">
						<div className="field">
							<Select
								label="Курс"
								items={eduList.courseList}
								onChange={changeCourseIdHandle}
								value={eduIds.courseId || ""}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-xl-3">
					<div className="chat-list">
						<div className="h6 mb-3 list-header">
							{homeworksUserList.length > 0 ? "Контакты" : "Курсовых работ нет"}
						</div>

						<div className="scroll-y">
							{homeworksUserList.map((i, index) => {
								return (
									<div
										key={i.id}
										className={`chat-item d-flex ai-center ${
											+eduIds.chatId === i.chat_id ? "_active" : ""
										}
										${!i.isViewed ? " _unread" : ""}
									`}
										onClick={() =>
											onChatChange(
												i.chat_id,
												index,
												i.statusName,
												i.user.id,
												i.isViewed
											)
										}>
										<img
											src={i.user?.avatarThumb || default_avatar}
											alt=""
											className="avatar avatar-xs avatar-circle mr-3 image-cover"
										/>

										<div className="item-body">
											<div className="name font-500">
												{`${i.user?.last_name || "Имя"} ${
													i.user?.first_name || "Фамилия"
												}`}
											</div>
											<div className="thumb">{i.user?.birthDate}</div>
											<div style={{ color: "#ef2e2e" }}>
												{i.statusName === "Закончен" && "Завершено"}
											</div>
										</div>
									</div>
								)
							})}
						</div>
					</div>
				</div>

				<div className="col-xl-9">
					{eduIds.chatId && role.view && (
						<>
							<div className="chat-wrap">
								<div className="msg-list scroll-y d-flex flex-column">
									{!isLoading ? (
										chatMessageList.map((i, index) => {
											return (
												<Message
													key={index}
													data={i}
													userId={state.user.user_id}
													download={onFileDownload}
													onMoveToHomework={() => {
														showModalForm()
														getHomeworkForm(i.homework_id)
													}}
												/>
											)
										})
									) : (
										<Spinner size={80} className="spinner pos-abs" />
									)}
								</div>

								<form
									onSubmit={handleSubmit(onSendMessage)}
									className="msg-form pos">
									<label className="pos-abs-y attach-btn d-flex">
										<input
											type="file"
											className="d-none"
											multiple
											onChange={onAddFiles}
										/>
										<IconClip color="#1952a5" className="image-contain" />
									</label>

									<textarea
										rows="1"
										placeholder="Написать сообщение"
										className="textareaChat"
										name="message"
										ref={register}
									/>

									<button type="submit" className="pos-abs-y send-btn">
										<IconSend size={20} color="#1952a5" />
									</button>
								</form>
								<div className="mt-2" style={{ color: "red" }}>
									{documentError}
								</div>
							</div>

							{documents.length > 0 && (
								<div className="mt-3 row attach-files">
									{documents.map((doc, index) => {
										const typeDoc = !doc.type.includes("image/")
										const img = URL.createObjectURL(doc)

										return (
											<div
												className="col-xs-6 col-sm-4 col-md-4 col-xl-3 col-xxl-2"
												key={index}>
												<div className="mt-3 p-3 file pos d-flex ai-center jc-center flex-column">
													<button
														className="close"
														onClick={() => removeFile(doc.name)}>
														<IconTrash size={12} className="pos-abs" />
													</button>

													{typeDoc ? (
														<>
															<IconEducation size={38} color={"#1952a5"} />
															<div className="ta-center mt-2 h7">
																{doc.name}
															</div>
														</>
													) : (
														<>
															<Spinner size={50} className="spinner pos-abs" />
															<img
																src={img}
																alt={doc.name}
																className="img image-cover"
															/>
														</>
													)}
												</div>
											</div>
										)
									})}
								</div>
							)}
						</>
					)}
				</div>
			</div>

			<Modal
				show={showModal}
				onClose={showModalForm}
				modalWrapperStyles={{ maxWidth: 800 }}>
				<div className="form">
					{homeworkForm ? (
						homeworkForm.questions.map((i, index) => (
							<div className="mb-4" key={index}>
								<div className="h6">{i.question}</div>
								<textarea
									rows={5}
									className="input my-1"
									value={i?.answer || ""}
									readOnly
								/>
								<div className="h7">{i.prompt}</div>

								<div className="row row-sm">
									{i.files.map(file => {
										const fileName = `${file.id}.${file.extension}`
										return (
											<div key={file.id} className="col-sm-6 col-md-4">
												<div
													className="mt-2 btn btn-primary btn-xs btn-block jc-start"
													onClick={() => downloadFile(file.path, fileName)}>
													<IconDocs size={16} color="#fff" className="mr-2" />

													<div>
														<div className="h6">{fileName}</div>
														<div className="h7">{file.size}</div>
													</div>
												</div>
											</div>
										)
									})}
								</div>
							</div>
						))
					) : (
						<Spinner size={90} />
					)}

					{isHomeworkStart && (
						<button
							onClick={() => acceptHomework()}
							className="btn btn btn-primary mt-4">
							Принять работу
						</button>
					)}
				</div>
			</Modal>
		</div>
	)
}

export default Courses
