/* eslint-disable react/prop-types */
import * as React from "react"

const SvgComponent = ({ size, color, ...props }) => (
	<svg
		fill={color}
		width={size}
		height={size}
		viewBox="0 0 535.5 535.5"
		{...props}>
		<path d="M0 497.25l535.5-229.5L0 38.25v178.5l382.5 51-382.5 51z"/>
	</svg>
)

export default SvgComponent
