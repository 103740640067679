import React, { useContext, useEffect, useState } from "react"
import { hot } from "react-hot-loader/root"
import { BrowserRouter, Switch } from "react-router-dom"
import Api from "./api"
import Spinner from "./components/Spinner/Spinner"
import { AppContext, AppProvider } from "./context/AppContext"
import AuthFlow from "./pages/Auth"
import LkFlow from "./pages/Lk"

const MindflexApp = () => {
	const { state, login, reset } = useContext(AppContext)
	const [isLoading, setIsLoading] = useState(true)

	const tryLogin = async () => {
		const authToken = localStorage.getItem("authToken")

		if (authToken) {
			try {
				const Authorization = `Bearer ${authToken}`

				const res = await Api.get("/profile", { headers: { Authorization } })
				const resRole = await Api.get("/access-user", {
					headers: { Authorization },
				})
				const { error, data } = res.data

				if (!error) {
					login(data, authToken, resRole.data)
				} else {
					reset()
				}
			} catch (e) {
				if (e.message.match(/401/)) {
					reset()
				}
			}
		}

		setIsLoading(false)
	}

	useEffect(() => {
		tryLogin()
	}, [])

	if (isLoading) {
		return (
			<div style={{ height: "100vh" }} className="d-flex ai-center jc-center">
				<Spinner size={200} />
			</div>
		)
	}

	return state.authToken ? <LkFlow /> : <AuthFlow />
}

const App = () => {
	return (
		<BrowserRouter>
			<AppProvider>
				<Switch>
					<MindflexApp />
				</Switch>
			</AppProvider>
		</BrowserRouter>
	)
}

export default hot(App)
