/* eslint-disable react/prop-types */
import * as React from "react"

const SvgComponent = ({ size, color, ...props }) => (
	<svg height={size} viewBox="0 0 512 512" width={size} {...props}>
		<path
			fill={color}
			d="M447.733 30h1.066c8.285 0 15-6.716 15-15s-6.715-15-15-15H103.368C72.948 0 48.2 24.748 48.2 55.168v401.667C48.2 487.253 72.948 512 103.368 512h305.264c30.42 0 55.168-24.747 55.168-55.165V135.5c0-15.152-6.143-28.894-16.066-38.873V30zm-92.895 257.069l-38.536 37.564 8.957 52.225c.246 1.077.376 2.199.376 3.351 0 8.277-6.705 14.989-14.98 15h-.041c-2.388 0-4.784-.569-6.979-1.723l-47.634-25.044-47.635 25.044a15.004 15.004 0 01-15.797-1.142 14.997 14.997 0 01-5.967-14.671l9.098-53.039-38.536-37.564a15 15 0 018.313-25.585l53.256-7.737 23.816-48.259a14.998 14.998 0 0126.901 0l23.817 48.259 53.256 7.737a14.998 14.998 0 0112.109 10.208 14.996 14.996 0 01-3.794 15.376zM417.733 81.1a55.28 55.28 0 00-9.102-.765H103.368c-13.878 0-25.168-11.29-25.168-25.167C78.2 41.29 89.49 30 103.368 30h314.365z"
		/>
	</svg>
)

export default SvgComponent
